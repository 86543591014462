import { A } from '@ember/array';
import ProjectFileVersionDownloadOperation from 'seshy/models/project-file-version-download-operation';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import Service, { inject as service } from '@ember/service';
import EmberObject from '@ember/object';
import slash from 'slash';
import { DelayPolicy } from 'ember-concurrency-retryable';
import FingerprintMismatchError from '../errors/fingerprint-mismatch-error';
import FilesLockedError from '../errors/files-locked-error';

const myDelayPolicy = new DelayPolicy({
  delay: [300, 600, 1200, 2400],
  reasons: [FingerprintMismatchError],
});

export default class ProjectDownloadOperationModel extends EmberObject {
  @service settings;

  @tracked operations = A([]);
  @tracked activeFileOperations = A([]);

  @tracked subOperationCount = 0;

  glob = window.requireNode('glob-promise');
  fs = window.require('fs');
  path = window.require('path');

  get fileOperations() {
    return this.operations;
  }

  get percentDone() {
    var percent =
      100 - (this.operations.get('length') / this.subOperationCount) * 100;
    if (isNaN(percent)) {
      percent = 0;
    }
    return percent;
  }

  customInit(project, projectVersion, store, projectPath, seshyDir) {
    this.project = project;
    this.projectVersion = projectVersion;
    this.store = store;
    this.projectPath = projectPath;
    this.seshyDir = seshyDir;

    //console.log('fileDownloadMax = ', this.settings.get('fileDownloadMax'));
  }

  get projectData() {
    return {
      projectName: this.project.displayName,
    };
  }

  log(...args) {
    if (false) {
      console.log(...args);
    }
  }

  async handleProjectFileVersion(projectFileVersion) {
    this.subOperationCount += 1;
    this.log('need to check a projectFileVersion', projectFileVersion);
    var operation = new ProjectFileVersionDownloadOperation(
      this.project,
      this.projectPath,
      projectFileVersion,
      this.store,
      this.seshyDir
    );
    this.operations.pushObject(operation);
    await this.fileDownload.perform(operation);
    this.log('all done with projectFileVersion', projectFileVersion);
    this.operations.removeObject(operation);
  }

  // TODO : this is the most minimal change I could make to add some concurrency control to downloads.
  // There are probably better ways to handle this. And I'm not entirely sure that it's a good idea
  // to use ember-concurrency in a POJO, but it seems to work...
  fileDownload = task(
    {
      maxConcurrency: this.settings.get('fileDownloadMax'),
      enqueue: true,
      retryable: myDelayPolicy,
    },
    async (operation) => {
      //console.log('starting ---------------------------')
      this.log('starting download');
      this.activeFileOperations.pushObject(operation);
      try {
        await operation.downloadOrVerifyLocalFile();
      } finally {
        // We have this in a finally to prevent retried operations building up in the queue
        this.activeFileOperations.removeObject(operation);
        this.log('ending download');
      }
    }
  );

  fileIsWritable(filePath) {
    let fileAccess = false
    try {
      this.fs.closeSync(this.fs.openSync(filePath, 'r+'))
      fileAccess = true
      console.log('can open file', filePath);
    } catch (err) {
      console.log('can not open file!', filePath);
    }
    return fileAccess
  }

  fileIsLocked(filePath){
    return !this.fileIsWritable(filePath);
  }

  async download() {
    this.log('beginning download for project ' + this.project);
    this.log('beginning download for projectVersion ' + this.projectVersion);
    this.log('projectPath = ', this.projectPath);

    //await this.cleanupExistingProjectDirectory();

    var localProjectFiles = await this.glob(this.projectPath + '/**/*');

    for (const file of localProjectFiles){
      if (this.fs.lstatSync(file).isFile() && this.fileIsLocked(file)){
        throw new FilesLockedError(file);
        //return;
      }
    }

    var localFragments = [];
    for (const file of localProjectFiles) {
      // We slash() both of these paths to ensure that they slash direction matches.
      // On Windows this.projectPath will contain backslashes like C:\\Users\jgreen\Seshy
      // but the glob library will return paths with forwards slashes C:/Users/jgreen/Seshy
      var localProjectFileFragment = slash(file).replace(
        slash(this.projectPath + '/'),
        ''
      );
      localFragments.push(localProjectFileFragment);
    }

    var projectFileVersions = await this.store.query('project-file-version', {
      filter: { projectVersionId: this.projectVersion.id },
      include: 'project-file',
    });

    var remoteFragments = [];
    for (const file of projectFileVersions.slice()) {
      remoteFragments.push(file.path);
    }

    var leftoverFragments = localFragments.filter(
      (x) => !remoteFragments.includes(x)
    );

    //console.log('localProjectFiles = ', localProjectFiles);
    //console.log('localFragments = ', localFragments);
    //console.log('projectFileVersions = ', projectFileVersions);
    //this.log('projectFileVersions = ', projectFileVersions);
    //console.log('remoteFragments = ', remoteFragments);
    //console.log('leftoverFragments = ', leftoverFragments);

    await Promise.all(
      projectFileVersions.map(async (projectFileVersion) => {
        await this.handleProjectFileVersion(projectFileVersion);
      })
    );

    // We do this last so that we make sure we've downloaded everything we need
    // for this version before we delete anything.
    await this.cleanupLeftoverFiles(leftoverFragments);
  }

  async cleanupLeftoverFiles(fragments) {
    for (const fragment of fragments) {
      let filePath = this.projectPath + this.path.sep + fragment;
      //console.log('cleanup : ', filePath);
      if (
        this.fs.existsSync(filePath) &&
        !this.fs.lstatSync(filePath).isDirectory()
      ) {
        //console.log('the file exists and is not a directory')
        console.log('after download we need to clean up file : ', filePath);
        this.fs.unlinkSync(filePath);
      }
    }
  }

  // Here we move all of the files that are already on disk out of the way.
  // This ensures that we don't carry over files that were once part of the
  // project but that have been cleaned out.
  async cleanupExistingProjectDirectory() {
    var localProjectFiles = await this.glob(this.projectPath + '/**/*');
    for (const file of localProjectFiles) {
      console.log('need to move local file ', file);
      if (this.fs.lstatSync(file).isDirectory()) {
        //console.log("it's a dir. NEXT!");
        continue;
      }
      console.log('this.projecPath = ', this.projectPath);
      // We slash() both of these paths to ensure that they slash direction matches.
      // On Windows this.projectPath will contain backslashes like C:\\Users\jgreen\Seshy
      // but the glob library will return paths with forwards slashes C:/Users/jgreen/Seshy
      var localProjectFileFragment = slash(file).replace(
        slash(this.projectPath),
        ''
      );
      console.log('localProjectFileFragment = ', localProjectFileFragment);
      var destination =
        (await this.seshyDir.projectCacheDirPath()) +
        this.path.sep +
        'project-' +
        this.project.id +
        localProjectFileFragment;
      console.log('will move to ' + destination);
      var dir = this.path.dirname(destination);
      if (!this.fs.existsSync(dir)) {
        this.fs.mkdirSync(dir, { recursive: true, mode: 0o744 });
      }
      try {
        this.fs.renameSync(file, destination);
      } catch (err) {
        console.error('we could not move file ', file);
      }
    }

    // TODO: This is VERY brute force but makes it so that several things will work:
    // - Project Alternatives in Logic
    // - Cleaning up a project in Logic (and probably Ableton)
    //
    // To make this better we should probably maintain a local file cache or something?
    // A danger with this current approach is that on flaky connections it could be really
    // hard to get everything downloaded since we won't be resuming the download where it
    // left off. This should be high priority.
    //if (this.fs.existsSync(this.projectPath)) {
    //this.fs.rmdirSync(this.projectPath, { recursive: true });
    //}

    // TODO: This is kind of brute force, but it makes Project Alternatives work correctly
    // TODO: This is Logic specific
    //var alternativeDirs = await this.glob(this.projectPath + '/Alternatives/*');
    //for (const dir of alternativeDirs) {
    //console.log('need to remove alternative dir ', dir);
    //this.fs.rmdirSync(dir, { recursive: true });
    //}
  }
}
