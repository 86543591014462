/* import __COLOCATED_TEMPLATE__ from './team-project-widget.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import canUserManageProject from 'seshy/utils/can-user-manage-project';

export default class TeamProjectWidgetComponent extends Component {
  @service store;
  @service currentUser;

  @tracked isConnected = false;

  @tracked errorMessage = null;

  get hasError() {
    return !!this.errorMessage;
  }

  constructor() {
    super(...arguments);
    //console.log('calling TeamProjectWidget constructor');
    this.setIsConnected();
  }

  async setIsConnected() {
    var isConnected = false;
    for (let i = 0, len = this.args.teamProjects.length; i < len; i++) {
      var teamProject = this.args.teamProjects.at(i);
      try {
        var team = await teamProject.team;
        //console.log("this.args.team = ", this.args.team.id);
        //console.log("teamProjectTeam = ", team.id);
        if (team.id == this.args.team.id) {
          //console.log('this one is connected');
          isConnected = true;
          this.teamProject = teamProject;
        }
      } catch (err) {
        console.log(
          'caught an error trying to check this teamProject',
          err,
          teamProject
        );
      }
    }

    this.isConnected = isConnected;
  }

  get isOwned() {
    return (
      this.args.team.id == this.args.project.owningTeam.id
    );
  }

  get canManageProject() {
    return canUserManageProject(this.currentUser, this.args.project);
  }

  get notManager() {
    return !this.canManageProject;
  }

  @action
  async connect() {
    this.errorMessage = null;
    //console.log('connect');
    this.teamProject = this.store.createRecord('team-project', {
      team: this.args.team,
      project: this.args.project,
    });
    try {
      await this.teamProject.save();
      this.isConnected = true;
    } catch (error) {
      console.log('caught an error', error);

      let errorCause = error.errors[0];

      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  @action
  async disconnect() {
    this.errorMessage = null;
    //console.log('disconnect');
    this.teamProject.deleteRecord();
    try {
      await this.teamProject.save();
      this.teamProject = null;
      this.isConnected = false;
    } catch (error) {
      console.log('caught an error', error);

      let errorCause = error.errors[0];

      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  setForbiddenMessage() {
    this.errorMessage =
      "You don't seem to be a Team Admin for the team that owns this project.";
  }

  setUnknownErrorMessage() {
    this.errorMessage =
      'We ran into an unexpected error while trying update the sharing status. Please try again.';
  }
}
