import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import slash from 'slash';
import { task, timeout } from 'ember-concurrency';

/*
 * The internals of this service are currently kinda gross. I'm just trying
 * to make sure that the external API is working well before spending a bunch
 * of time optimizing it.
 */
export default class ProjectVersionRepositoryService extends Service {
  @service seshyDir;
  @service settings;
  @service currentUser;
  @tracked projectData = {};

  // We use this hash to keep hold of project data for projects that have
  // been deleted. This allows us to immediately show in the UI that a project
  // has gone missing locally. But in cases where a project has been moved from
  // one dir to another this allows us to keep track of the project name/checksum
  // combo that can be used to identify a project.
  recentlyDeletedProjectData = {};

  pushProjectData(project, projectVersion, localPath) {
    let dataBlob = {
      currentProjectVersionId: projectVersion ? projectVersion.get('id') : null,
      currentProjectVersionChecksum: projectVersion
        ? projectVersion.get('projectDataChecksum')
        : null,
      localPath: slash(localPath),
      projectName: project.get('name'),
    };
    this._pushProjectDataBlob(project.get('id'), dataBlob);
  }

  _pushProjectDataBlob(projectId, dataBlob) {
    this.projectData[projectId] = dataBlob;

    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
    //console.log('this.projectData = ', this.projectData);

    this.writeDataFile.perform();
  }

  dataFor(projectId) {
    return this.projectData[projectId];
  }

  versionFor(projectId) {
    var currentProjectData = this.projectData[projectId];
    return currentProjectData && !currentProjectData.localProjectMissing
      ? currentProjectData.currentProjectVersionChecksum
      : null;
  }

  localProjectMissingFor(projectId) {
    var currentProjectData = this.projectData[projectId];
    if (currentProjectData) {
      return !!currentProjectData.localProjectMissing;
    }
    return false;
  }

  localPathFor(project, team) {
    var projectId = project.get('id');
    var currentProjectData = this.projectData[projectId];
    if (currentProjectData) {
      // If we already have a local path for this projectId just use that
      return slash(currentProjectData.localPath);
    } else if (team) {
      let pathLib = require('path');

      // If we're looking for it in the context of a team, build a path that uses the team name
      return (
        slash(this.settings.get('primaryFolder')) +
        pathLib.sep +
        team.get('name') +
        pathLib.sep +
        project.get('name')
      );
    } else {
      // assume we're in the primary seshy dir
      // throw 'Implement me 2!';
      return null;
    }
  }

  async projectDataFilePath() {
    const path = await this.seshyDir.projectDataFilePath();
    //console.log('path =====================================');
    //console.log(path);
    return path;
  }

  // TODO: Is this how we should do this?
  // Maybe we want to retain path/project_id mappings?
  // And maybe we should mark projects in the UI as "not watched"?
  //
  async resetDataFile() {
    /*
    var fs = window.require('fs');
    var seshyJsonPath = await this.projectDataFilePath();
    await fs.unlinkSync(seshyJsonPath);
    this.projectData = {};
    */
  }

  writeDataFile = task(
    {
      maxConcurrency: 1,
      keepLatest: true,
    },
    async () => {
      //console.log('writing data file!')
      var fs = window.require('fs');
      var writeFileAtomic = window.require('write-file-atomic');
      var seshyJsonPath = await this.projectDataFilePath();
      var backupPath = seshyJsonPath + '.bak';
      if (fs.existsSync(seshyJsonPath)) {
        fs.copyFileSync(seshyJsonPath, backupPath);
      }
      await writeFileAtomic(
        seshyJsonPath,
        JSON.stringify(this.projectData, null, 2)
      );
    }
  );

  async readDataFile() {
    var fs = window.require('fs');

    var seshyJsonPath = await this.projectDataFilePath();

    if (fs.existsSync(seshyJsonPath)) {
      this.projectData = require(seshyJsonPath);
    }
  }

  async scanForDeletedProjects() {
    var fs = window.require('fs');
    var projectIds = Object.keys(this.projectData);
    for (let i = 0, len = projectIds.length; i < len; i++) {
      var projectId = projectIds[i];
      var projectData = this.projectData[projectId];
      var localPath = projectData.localPath;
      if (!fs.existsSync(localPath)) {
        //this.removeProjectDataByLocalPath(localPath);
        this.markMissingLocalProjectByLocalPath(localPath);
      }
    }
    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
  }

  findProjectDataByLocalPath(localPath, includeRecentlyDeletedProjects) {
    var projectIds = Object.keys(this.projectData);
    for (let i = 0, len = projectIds.length; i < len; i++) {
      var projectId = projectIds[i];
      var projectData = this.projectData[projectId];
      if (localPath == projectData.localPath) {
        // Some users have ended up with an entry in their repo with a key of "null".
        // When we try to retrieve a project with an ID of "null" it fails and the user
        // ends up with a stranded project that is invisible to Seshy.
        if (projectId == 'null' || projectId == null) {
          console.error('we have a null key', localPath);
          delete this.projectData[projectId];
          this.writeDataFile.perform();
          return null;
        }
        // We set the projectId here so that it's available to
        // callers, and since we don't store it in the data blob,
        // we only store it as the key in this.projectData
        projectData.projectId = projectId;
        return projectData;
      }
    }
    if (!includeRecentlyDeletedProjects) {
      return null;
    }
    console.log(
      "didn't find project in the main projectData checking recentlyDeletedProjectData"
    );
    var projectIds = Object.keys(this.recentlyDeletedProjectData);
    for (let i = 0, len = projectIds.length; i < len; i++) {
      var projectId = projectIds[i];
      var projectData = this.recentlyDeletedProjectData[projectId];
      if (localPath == projectData.localPath) {
        // We put the recently deleted project info back into our main, persisted repo data.
        this._pushProjectDataBlob(projectId, projectData);

        // We set the projectId here so that it's available to
        // callers, and since we don't store it in the data blob,
        // we only store it as the key in this.projectData
        projectData.projectId = projectId;

        return projectData;
      }
    }
    return null;
  }

  markMissingProjectByLocalPath(localPath) {
    // TODO: Should we include recently deleted here?
    var pData = this.findProjectDataByLocalPath(localPath, false);
    if (pData && !pData.apiProjectMissing) {
      this.projectData[pData.projectId].apiProjectMissing = true;
      this.writeDataFile.perform();
    }

    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
  }

  markMissingLocalProjectByLocalPath(localPath) {
    // TODO: Should we include recently deleted here?
    var pData = this.findProjectDataByLocalPath(localPath, false);
    if (pData && !pData.localProjectMissing) {
      this.projectData[pData.projectId].localProjectMissing = true;
      this.writeDataFile.perform();
    }

    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
  }

  unmarkMissingLocalProjectByLocalPath(localPath) {
    // TODO: Should we include recently deleted here?
    var pData = this.findProjectDataByLocalPath(localPath, false);
    if (pData && pData.localProjectMissing) {
      this.projectData[pData.projectId].localProjectMissing = false;
      this.writeDataFile.perform();
    }

    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
  }

  markUnauthorizedProjectByLocalPath(localPath) {
    // TODO: Should we include recently deleted here?
    var pData = this.findProjectDataByLocalPath(localPath, false);
    if (pData && !pData.apiProjectUnauthorized) {
      this.projectData[pData.projectId].apiProjectUnauthorized = true;
      this.writeDataFile.perform();
    }

    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
  }

  markPaymentRequiredByLocalPath(localPath) {
    // TODO: Should we include recently deleted here?
    var pData = this.findProjectDataByLocalPath(localPath, false);
    if (pData && !pData.apiPaymentRequired) {
      this.projectData[pData.projectId].apiPaymentRequired = true;
      this.writeDataFile.perform();
    }

    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
  }

  findOrphanedProjects() {
    console.log('finding orphaned projects');
    var orphanedProjects = [];
    var projectIds = Object.keys(this.projectData);
    for (let i = 0, len = projectIds.length; i < len; i++) {
      var projectId = projectIds[i];
      var projectData = this.projectData[projectId];
      if (projectData.apiProjectMissing) {
        projectData.projectId = projectId;
        orphanedProjects.push(projectData);
      }
    }
    return orphanedProjects;
  }

  findProjectDataByProjectNameAndChecksum(projectName, checksum) {
    var projectIds = Object.keys(this.projectData);
    for (let i = 0, len = projectIds.length; i < len; i++) {
      var projectId = projectIds[i];
      var projectData = this.projectData[projectId];
      if (
        projectName == projectData.projectName &&
        checksum == projectData.currentProjectVersionChecksum
      ) {
        // We set the projectId here so that it's available to
        // callers, and since we don't store it in the data blob,
        // we only store it as the key in this.projectData
        projectData.projectId = projectId;
        return projectData;
      }
    }

    console.log(
      "didn't find project in the main projectData checking recentlyDeletedProjectData"
    );
    var projectIds = Object.keys(this.recentlyDeletedProjectData);
    for (let i = 0, len = projectIds.length; i < len; i++) {
      var projectId = projectIds[i];
      var projectData = this.recentlyDeletedProjectData[projectId];
      if (
        projectName == projectData.projectName &&
        checksum == projectData.currentProjectVersionChecksum
      ) {
        // We set the projectId here so that it's available to
        // callers, and since we don't store it in the data blob,
        // we only store it as the key in this.projectData
        projectData.projectId = projectId;
        return projectData;
      }
    }
    return null;
  }

  removeProjectDataByLocalPath(localPath, options) {
    console.log('removing project data by localPath', localPath);
    var pData = this.findProjectDataByLocalPath(localPath, false);
    if (pData) {
      console.log('options = ', options);
      if (options && options.skipSave) {
        // Do nothing
        console.log('skipping save');
      } else {
        console.log('adding to recentlyDeletedProjectData');
        this.recentlyDeletedProjectData[pData.projectId] = pData;
      }
      // TODO: This is temporary to feel out how to handle moving projects
      delete this.projectData[pData.projectId];
      console.log(
        'this.recentlyDeletedProjectData =',
        this.recentlyDeletedProjectData
      );
      this.writeDataFile.perform();
    }
    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData = this.projectData;
  }

  updateProjectDataForRename(projectId, oldName, newName) {
    var oldLocalPath = this.projectData[projectId].localPath;
    console.log('oldLocalPath = ', oldLocalPath);
    var newLocalPath = oldLocalPath.replace(oldName, newName);
    console.log('newLocalPath = ', newLocalPath);
    this.projectData[projectId].localPath = newLocalPath;
    this.projectData[projectId].projectName = newName;
    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.projectData[projectId] = this.projectData[projectId];
    this.projectData = this.projectData;
  }
}
