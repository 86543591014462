/* import __COLOCATED_TEMPLATE__ from './focused.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class BouncePlayerFocusedComponent extends Component {
  @service currentlyPlaying;

  focusWindowPadding = 5;
  @tracked wavesurferElement = null;
  @tracked soundShouldPlay = false;

  @tracked _currentAudioTime = null;
  @tracked _zoom = null;

  get sound() {
    //console.log('get sound', this.args.bounce?.soundId)
    return this.currentlyPlaying.getSound(this.args.bounce?.soundId);
  }

  @action
  captureWavesurferElement(element) {
    //console.log('captureWavesurferElement', element);
    this.wavesurferElement = element;
    //this.zoom = this.minPxPerSec;
  }

  @action
  onAudioPositionChanged(newTime) {
    //console.log('onAudioPositionChanged', newTime);
    this._currentAudioTime = newTime;
    if (this.args.onAudioPositionChanged) {
      this.args.onAudioPositionChanged(newTime);
    }
  }

  @action
  onInteraction(newTime) {
    //console.log('onInteraction',newTime);
    if (this.args.onInteraction) {
      //console.log('we have an onInteraction arg')
      this.args.onInteraction(newTime);
    } else {
      //console.log('we do not have an onInteraction arg')
      if (this.sound) {
        //console.log('we have a sound')
        this.sound.position = newTime * 1000;
      } else {
        this._currentAudioTime = newTime;
        //console.log('we do not have a sound')
      }
    }
  }

  @action
  async commentMarkerClick(comment, marker) {
    //console.log('commentMarkerClick', marker.start)
    this.onInteraction(marker.start);
    this.soundShouldPlay = true;
    //this.updateSoundPosition(marker.start);
    //await this.playSoundTask.perform();
  }

  get currentAudioTime() {
    return this._currentAudioTime || this.focusStartTime;
  }

  get displayTime() {
    return this.args.displayTime || this.currentAudioTime;
  }

  get minPxPerSec() {
    //console.log('calculating minPxPerSec', this.wavesurferElement)
    if (!this.wavesurferElement) {
      //console.log('minPxPerSec - we do not have an element!')
      return null;
    }
    let pixelWidth = this.wavesurferElement.offsetWidth;
    let pixelsPerSecond = pixelWidth / this.focusWindow / 1;
    //console.log('minPxPerSec = ', pixelsPerSecond);
    return pixelsPerSecond;
  }

  get focusCenterTime() {
    let time = this.focusStartTime + this.focusWindow / 2;
    return time;
  }

  get focusWindow() {
    //console.log('focusWindow calculating')
    return this.focusEndTime - this.focusStartTime;
  }

  get focusStartTime() {
    //console.log('get focusStartTime', this.args.comment);
    let startTime =
      (this.args.comment?.earliestMarkerStart || 0) - this.focusWindowPadding;
    //console.log('startTime = ', startTime)
    if (startTime < 0) {
      startTime = 0;
    }
    //console.log('startTime = ', startTime)
    return startTime;
  }

  get focusEndTime() {
    //console.log('get focusEndTime', this.args.comment);
    let duration = this.args.bounce.duration;
    // First get the start time of the latest marker
    let latestStart = this.args.comment?.latestMarkerStart || duration;
    let latestEnd = this.args.comment?.latestMarkerEnd || duration;
    let endTime = latestStart > latestEnd ? latestStart : latestEnd;
    endTime = endTime + this.focusWindowPadding;
    if (endTime > duration) {
      endTime = duration;
    }
    //console.log('endTime = ', endTime);
    return endTime;
  }

  get zoom() {
    //console.log('trying to get zoom', this._zoom, this.minPxPerSec);
    return this._zoom || this.minPxPerSec;
  }

  set zoom(newZoom) {
    //console.log('the zoom setter', newZoom);
    this._zoom = newZoom;
  }

  setZoom(newZoom) {
    //console.log('setZoom', newZoom);
    this.zoom = newZoom;
    if (this.zoom < 1) {
      this.zoom = 1;
    }
    if (this.zoom > 50) {
      this.zoom = 50;
    }
    //this.wavesurfer.zoom(this.zoom);
  }

  @action zoomIn() {
    this.setZoom(this.zoom + this.zoom * 0.2);
  }

  @action zoomOut() {
    this.setZoom(this.zoom - this.zoom * 0.2);
  }

  nudgePosition(seconds) {
    let currentTime = this.currentAudioTime;
    let newTime = currentTime + seconds;

    this._currentAudioTime = newTime;
    //this.updateSoundPosition(newTime);
    //this.updateWavesurferPosition(newTime);
  }

  @action backBig() {
    this.nudgePosition(-5);
  }

  @action backSmol() {
    this.nudgePosition(-1);
  }

  @action forwardBig() {
    this.nudgePosition(5);
  }

  @action forwardSmol() {
    this.nudgePosition(1);
  }
}
