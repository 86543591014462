/* import __COLOCATED_TEMPLATE__ from './bounce-player-compact.hbs'; */
import BouncePlayerAbstract from './bounce-player-abstract';
import { tracked } from '@glimmer/tracking';

export default class BouncePlayerComponent extends BouncePlayerAbstract {
  @tracked radius = 22;
  @tracked circumference = this.radius * 2 * Math.PI;
  @tracked percent = 0;
  @tracked cx = 20;
  @tracked cy = 20;

  get dashoffset() {
    if (!this.bounce) {
      return 0;
    }
    return (
      this.circumference -
      (this.bounce.percentPlayed / 100) * this.circumference
    );
  }
}
