import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import * as Sentry from '@sentry/ember';

export default class CurrentUserService extends Service {
  @service session;
  @service store;

  @tracked user = null;

  async load() {
    if (this.session.isAuthenticated) {
      let user = await this.store.queryRecord('user', {
        me: true,
        include: 'teams,primary-team',
      });
      console.log('from the me endpoint we got a user', user);
      // TODO: Eventually the call above will be all we need. Currently the /me endpoing doesn't
      // actually include the things we ask for. So we make another call with an id to get the
      // associated records.
      user = await this.store.findRecord('user', user.id, {
        include: 'teams,primary-team',
      });
      console.log('from the id endpoint we got a user', user);
      this.set('user', user);
      console.log('setting Sentry user')
      Sentry.setUser({ email: user.get('email'), id: user.get('id') });
    }
  }

  get teams() {
    return this.user?.teams;
  }
}
