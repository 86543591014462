/* import __COLOCATED_TEMPLATE__ from './progress-bar.hbs'; */
import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

export default class ProgressBarComponent extends Component {
  get styleString() {
    return htmlSafe('width: ' + this.args.operation.percentDone + '%');
  }

  get showText() {
    if (typeof this.args.showText !== 'undefined') {
      return this.args.showText;
    }
    return true;
  }

  get showSubStatus() {
    if (typeof this.args.showSubStatus !== 'undefined') {
      return this.args.showSubStatus;
    }
    return true;
  }
}
