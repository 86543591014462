import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';

export default class AuthenticatedProjectsShowIssuesShowRoute extends BaseRoute {
  @service store;
  @service router;
  @service currentUser;

  unloadNewIssue(transition) {
    //console.log('calling unloadNewIssue')
    let model = this.modelFor(this.routeName);
    if (model && model.newIssue) {
      //console.log('we have a new issue to deal with')
      model.newIssue.destroyRecord();
    } else {
      //console.log('we do not have a new issue to deal with')
    }
    //(transition) => {
    //if (!transition.to.find(route => route.name === this.routeName) &&
    //!confirm('Are you sure you want to abandon progress?')) {
    //transition.abort();
    //}
    //}
  }

  async model(params) {
    let project = this.modelFor('authenticated.projects.show');
    //console.log('project = ', project);
    let issue = this.store.findRecord('comment', params.issue_id, {
      include:
        'user,comments,comments.user,comments.project,comment.project-version,comment.bounce,project,project.current-bounce,project.current-bounce.project-version,project-version,bounce,bounce.project-version,bounce.project-version.project',
    });

    let bounces = this.store.query('bounce', {
      filter: { project_id: project.id },
      include: 'user,project-version',
    });

    return hash({ project, issue, bounces });
  }

  async afterModel(model, transition) {
    console.log('starting the afterModel method');
    //let team = model
    this.subscribeForRefresh('CommentsChannel', { comment_id: model.issue.id });
    this.router.on('routeWillChange', this.unloadNewIssue.bind(this));
  }

  @action
  routeRefresh() {
    console.log('routeRefresh');
    this.refresh();
  }
}
