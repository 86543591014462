/* import __COLOCATED_TEMPLATE__ from './bounce-uploader-compact.hbs'; */
import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { FileState } from 'ember-file-upload';
import LocalFile from 'seshy/models/local-file';
import LocalBrowserFile from 'seshy/models/local-browser-file';

export default class BounceUploaderCompactComponent extends Component {
  @service fileQueue;
  @service store;
  @service platform;
  @tracked uploadedFiles = [];

  get queueName() {
    return `project-version-${this.args.projectVersion.get(
      'id'
    )}-bounces-compact`;
  }

  get queue() {
    return this.fileQueue.find(this.queueName);
  }

  validateFile(file) {
    var isMp3 = file.name.toLowerCase().endsWith('.mp3');
    var isWav = file.name.toLowerCase().endsWith('.wav');
    var isM4a = file.name.toLowerCase().endsWith('.m4a');
    if (!isMp3 && !isWav && !isM4a) {
      alert('Only mp3s are allowed as bounces. (For now.)');
    }
    return isMp3 || isWav || isM4a;
  }

  @action
  addToQueue(file) {
    file.queue = this.queue;
    file.state = FileState.Queued;
    this.uploadedFiles = [file, ...this.uploadedFiles];

    this.doUpload.perform(file);
  }

  doUpload = task({ maxConcurrency: 1, enqueue: true }, async (file) => {
    console.log('starting bounce upload for file ', file);
    var localFile;
    var checksum;
    if (this.platform.isNative) {
      localFile = new LocalFile(file.file.path);
      checksum = await localFile.fingerprint();
    } else {
      localFile = new LocalBrowserFile(file);
      checksum = await localFile.fingerprint();
    }

    const projectVersion = this.args.projectVersion;
    let bounceAttrs = {
      projectVersion: projectVersion,
      checksum: checksum,
      fileName: file.file.name,
      sizeInBytes: file.file.size,
    };
    let bounce = this.store.createRecord('bounce', bounceAttrs);
    await bounce.save();

    console.log('created the bounce record and about to upload to s3', file);

    const signedUploadData = bounce.get('signedUploadData');

    console.log('signedUploadData = ', signedUploadData);

    const s3Response = await file.uploadBinary(signedUploadData.url, {
      method: 'PUT',
      headers: signedUploadData.headers,
    });

    const s3ResponseText = await s3Response.text();

    bounce.set('uploadComplete', true);
    await bounce.save();

    if (this.args.project) {
      this.args.project.set('currentBounce', bounce);
    }

    console.log('bounce uploaded', file);
  });
}
