import ProjectDownloadOperationModel from 'seshy/models/project-download-operation';
import ProjectDirectorySyncOperation from 'seshy/models/project-directory-sync-operation';

export function initialize(application) {
  console.log('initializer operation = ', ProjectDownloadOperationModel);
  application.register(
    'operation:project-download',
    ProjectDownloadOperationModel,
    { singleton: false }
  );
  application.register(
    'operation:project-directory-sync',
    ProjectDirectorySyncOperation,
    { singleton: false }
  );
}

export default {
  initialize,
};
