import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedProjectsShowProjectVersionsListRoute extends BaseRoute {
  @service store;
  @service router;

  searchValue = '';

  async model() {
    var project = this.modelFor('authenticated.projects.show');
    console.log('project = ', project);
    var projectVersions = await this.store.query('project-version', {
      filter: {
        projectId: project.id,
        search: this.searchValue,
      },
      include: 'user,bounces',
    });
    return { project, projectVersions };
  }

  @action
  routeRefresh() {
    console.log('routeRefresh');
    this.refresh();
  }
}
