import { htmlSafe } from '@ember/template';
import LocalFile from 'seshy/models/local-file';
import FingerprintMismatchError from '../errors/fingerprint-mismatch-error';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';

export default class ProjectFileVersionDownloadOperationModel {
  @tracked projectFilePath;
  @tracked progress = 0;

  fs = require('fs');
  path = require('path');
  os = require('os');

  constructor(project, projectPath, projectFileVersion, store, seshyDir) {
    this.project = project;
    this.projectPath = projectPath;
    this.projectFileVersion = projectFileVersion;
    this.store = store;
    this.projectFilePath = projectFileVersion.path;
    this.seshyDir = seshyDir;
  }

  get percentDone() {
    return this.progress;
  }

  get progressStyleString() {
    return htmlSafe('width: ' + this.percentDone + '%');
  }

  updateProgress(bytes_received) {
    var downloadProgress =
      bytes_received / this.projectFileVersion.get('sizeInBytes');
    // This calculation is a little weird since we're trying to blend upload progress with
    // other record keeping to present an overal view of progress. We manually update to
    // 10% done as we're fingerprinting and finding the projectFile and projectFileVersion.
    // and then 10% is reserved for post-upload bookkeeping.
    this.progress = 10 + downloadProgress * 80;
  }

  async downloadOrVerifyLocalFile() {
    //console.log('downloadOrVerifyLocalFile', this.projectFileVersion);
    var projectFile = await this.projectFileVersion.get('projectFile');
    this.progress = 2.5;

    this.filePath = this.projectPath + '/' + projectFile.get('path');

    if (projectFile.get('fileType') == 'directory') {
      if (this.fs.existsSync(this.filePath)) {
        //console.log('we have a path at', this.filePath);
        if (!this.fs.lstatSync(this.filePath).isDirectory()) {
          //console.log('the file is there, but is not a directory!');
        }
      } else {
        //console.log('creating directory at ', this.filePath);
        this.fs.mkdirSync(this.filePath, { recursive: true, mode: 0o744 });
      }

      this.progress = 100;

      await timeout(100); // we do a smol timeout to allow the progress bar to update

      return null;
    }
    // Here we look to see if we've previously moved a file at that path out of the way.
    // If so we put it back where it should go.
    /*
    var backupPath =
      (await this.seshyDir.projectCacheDirPath()) +
      '/project-' +
      this.project.id +
      '/' +
      projectFile.get('path');
    if (this.fs.existsSync(backupPath)) {
      console.log('backup path exists');
      var dir = this.path.dirname(this.filePath);
      console.log('going to move ', backupPath);
      console.log('to ', this.filePath);
      this.fs.renameSync(backupPath, this.filePath);
    }
    */

    this.progress = 5;

    // Now we calculate the checksum for our local copy, if any.
    var localFile = new LocalFile(this.filePath);
    var checksum = 'nope';
    try {
      checksum = await localFile.fingerprint();
    } catch (err) {
      //console.log('we caught an error calculating the checksum', err);
    }

    this.progress = 10;

    // And then download a copy of the file if we don't have a good one.
    if (checksum == this.projectFileVersion.get('checksum')) {
      console.log('our local copy is good', this.filePath);
    } else {
      console.log(
        'checksums do not match we shoud download for path',
        this.filePath
      );
      await this.download();
      //console.log('after awaiting download');

      try {
        checksum = await localFile.fingerprint();
      } catch (err) {
        console.log('we caught an error calculating the checksum', err);
      }

      this.progress = 95;

      //console.log('our platform is ', this.os.platform());

      if (checksum == this.projectFileVersion.get('checksum')) {
        //console.log('after downloading our checksums match');
      } else {
        console.error(
          'after downloading our checksums do not match for file: ',
          this.filePath,
          'our checksum: ',
          checksum,
          'cloud checksum:',
          this.projectFileVersion.get('checksum')
        );
        if (
          this.os.platform() === 'win32' &&
          this.projectFileVersion.get('sizeInBytes') == 0
        ) {
          // Windows seems to prevent us from making empty files?
          // But we don't want that to prevent us from considering the download sucessful.
          console.error(
            'Since we are on Windows and it is a zero byte file we are ignoring the problem'
          );
        } else {
          console.error(
            'throwing FingerprintMismatchError which should trigger a retry'
          );
          throw new FingerprintMismatchError(this.filePath);
        }
      }
    }

    this.progress = 100;

    await timeout(100); // we do a smol timeout to allow the progress bar to update

    return null;
  }

  async download() {
    //console.log('this.store = ', this.store);

    let downloadRequest = this.store.createRecord('download-request', {
      projectFileVersionId: this.projectFileVersion.id,
    });

    await downloadRequest.save();
    var url = downloadRequest.get('url');
    //console.log('we should download from ...', url);

    // We unload the request from the local store so that it doesn't sit around
    // in memory after we're done with it.
    downloadRequest.unloadRecord();

    //console.log('about to call downloadAndWriteFile');

    await this.downloadAndWriteFile(url);

    //console.log('Download Completed (outside the promise)', this.filePath);
    return null;
  }

  async downloadAndWriteFile(url) {
    const fs = require('fs');
    const path = require('path');
    const https = require('https');
    var dir = path.dirname(this.filePath);
    if (!fs.existsSync(dir)) {
      fs.mkdirSync(dir, { recursive: true, mode: 0o744 });
    }
    //console.log('about to start download for ', this.filePath);
    var received_bytes = 0;
    var total_bytes = 0;
    let updateProgress = this.updateProgress.bind(this);
    let promise = new Promise((resolve, reject) => {
      https.get(url, (res) => {
        // Image will be stored at this path
        const path = this.filePath;
        const fileStream = fs.createWriteStream(path);
        res.pipe(fileStream);
        res.on('response', function (data) {
          //console.log('on response ', data);
          total_bytes = parseInt(data.headers['content-length']);
        });
        res.on('data', function (chunk) {
          received_bytes += chunk.length;
          //console.log('on data ', chunk);
          //console.log('received bytes ', received_bytes);
          updateProgress(received_bytes);
          //showDownloadingProgress(received_bytes, total_bytes);
        });
        res.on('end', () => {
          console.log('Download Completed', path);
          fileStream.close();
          resolve();
        });
      });
    });
    //console.log('made a promise', promise);

    return promise;
  }
}
