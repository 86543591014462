"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
class RemotePromise {
    /** Instantiates a new remote promise. */
    constructor() {
        this._status = 'pending';
        this[Symbol.toStringTag] = '[object Promise]';
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve;
            this._reject = reject;
        });
        this.then = this._promise.then.bind(this._promise);
        this.catch = this._promise.catch.bind(this._promise);
    }
    /** Gets the native promise instance. */
    get promise() {
        return this._promise;
    }
    /** Gets the current status of the promise. */
    get status() {
        return this._status;
    }
    /** Throws an error if the promises has already been resolved. */
    assertPending() {
        if (this._status !== 'pending') {
            throw new Error(`This promise has already been ${this._status}.`);
        }
    }
    resolve(value) {
        this.assertPending();
        this._status = 'fulfilled';
        this._resolve(value);
    }
    /** Remotely reject this promise */
    reject(reason) {
        this.assertPending();
        this._status = 'rejected';
        this._reject(reason);
    }
}
exports.RemotePromise = RemotePromise;
exports.default = RemotePromise;
