/* import __COLOCATED_TEMPLATE__ from './orphaned-project-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class OrphanedProjectListComponent extends Component {
  @service projectVersionRepository;

  @tracked orphanedProjects = null;

  @action
  findOrphanedProjects() {
    this.orphanedProjects =
      this.projectVersionRepository.findOrphanedProjects();
  }

  get hasOrphanedProjects() {
    return this.orphanedProjects && this.orphanedProjects.length > 0;
  }

  @action
  orphanDeleted() {
    console.log('orphanDeleted...');
    this.findOrphanedProjects();
  }
}
