/* import __COLOCATED_TEMPLATE__ from './new-team-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from 'seshy/config/environment';
import { inject as service } from '@ember/service';

export default class NewTeamButtonComponent extends Component {
  @service platform;

  @action
  openNewTeamPage(e) {
    e.preventDefault();
    var page = ENV.api.webhost + '/account/teams/new';
    console.log('yo', page);

    if (this.platform.isNative) {
      let shell = require('electron').shell;
      shell.openExternal(page);
    } else {
      window.open(page);
    }
  }
}
