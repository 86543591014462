import Model, { belongsTo } from '@ember-data/model';

export default class ProjectVersionFileVersionModel extends Model {
  @belongsTo('project-file-version', { inverse: null, async: false })
  projectFileVersion;
  @belongsTo('project-version', {
    inverse: 'projectVersionFileVersions',
    async: false,
  })
  projectVersion;
}
