// This is an attempt to merge the torii specific bits of the torii authenticator
// provided by the ember-simple-auth project, with the automatic token refresh
// functionality profided by the oauth2-password-grant authenticator from
// ember-simple-auth.
//
// Basically we're extending the oauth2-password-grant authenticator since it has
// the most complex code:
// https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/src/authenticators/oauth2-password-grant.js
// Then we're overridding some things by copy/pasting a few things from the torii authenticator:
// https://github.com/mainmatter/ember-simple-auth/blob/master/packages/ember-simple-auth/src/authenticators/torii.js

import RSVP from 'rsvp';
import { assert, deprecate } from '@ember/debug';
import { isPresent, isEmpty } from '@ember/utils';
import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import { inject as service } from '@ember/service';
import ENV from 'seshy/config/environment';

export default OAuth2PasswordGrant.extend({
  torii: service(),
  _provider: null,

  clientId: ENV.api.clientId,

  serverTokenEndpoint: ENV.api.apihost + '/oauth/token',

  /**
    Restores the session by calling the torii provider's `fetch` method.

    @method restore
    @param {Object} data The data to restore the session from
    @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming or remaining authenticated
    @public
  */
  restore(data) {
    console.log('calling restore with data = ', data);
    this._assertToriiIsPresent();

    data = data || {};
    if (isEmpty(data.provider)) {
      console.log('trying to set provider');
      data.provider = 'seshy-oauth2-provider';
    }
    console.log('now data = ', data);
    if (!isEmpty(data.provider)) {
      const { provider } = data;

      return this.torii.fetch(data.provider, data).then(
        (fetchedData) => {
          this._authenticateWithProvider(provider, fetchedData);
          let returnVal = Object.assign(data, fetchedData);
          this._scheduleAccessTokenRefresh(
            data['expires_in'],
            data['expires_at'],
            data['refresh_token']
          );
          console.log('in restore we are returning returnVal =', returnVal);
          return returnVal;
        },
        (err) => {
          console.log('caught an error', err);
          delete this._provider;
          throw err;
        }
      );
    } else {
      console.log('apparently the provider is empty?', data);
      delete this._provider;
      return RSVP.reject('apparently the provider is empty?');
    }
  },

  /**
    Authenticates the session by opening the specified torii provider. For more
    documentation on torii and its providers abstraction, see the
    [project's README](https://github.com/Vestorly/torii#readme), specifically
    the
    [section on providers](https://github.com/Vestorly/torii#configuring-a-torii-provider).

    @method authenticate
    @param {String} provider The torii provider to authenticate the session with
    @param {Object} options The options to pass to the torii provider
    @return {Ember.RSVP.Promise} A promise that when it resolves results in the session becoming authenticated
    @public
  */
  authenticate(provider, options) {
    this._assertToriiIsPresent();

    return this.torii.open(provider, options || {}).then((data) => {
      this._authenticateWithProvider(provider, data);
      console.log('in authenticate data = ', data);

      const expiresAt = this._absolutizeExpirationTime(data['expires_in']);
      console.log('expiresAt = ', expiresAt);
      this._scheduleAccessTokenRefresh(
        data['expires_in'],
        expiresAt,
        data['refresh_token']
      );
      if (!isEmpty(expiresAt)) {
        data = Object.assign(data, { expires_at: expiresAt });
      }

      console.log('in authenticate now data = ', data);
      return data;
    });
  },

  /**
    Closes the torii provider. If the provider is successfully closed, this
    method returns a resolving promise, otherwise it will return a rejecting
    promise, thus intercepting session invalidation.

    @method invalidate
    @return {Ember.RSVP.Promise} A promise that when it resolves results in the session being invalidated
    @public
  */
  invalidate(data) {
    return this.torii.close(this._provider, data).then(() => {
      delete this._provider;
    });
  },

  _authenticateWithProvider(provider, data) {
    data.provider = provider;
    this._provider = data.provider;
  },

  _assertToriiIsPresent() {
    const torii = this.torii;
    assert(
      'You are trying to use the torii authenticator but torii is not available. Inject torii into the authenticator with "torii: Ember.inject.service()".',
      isPresent(torii)
    );
  },
});
