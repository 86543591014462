import LocalFile from 'seshy/models/local-file';

export default class LocalAbletonFileModel extends LocalFile {
  static glob = window.requireNode('glob-promise');
  static crypto = window.require('crypto');

  static dawName = 'ableton-live';

  // We don't try to sync the weird 'Icon?' / 'Icon\r' file.
  // The actual icon lives in the resource fork of the file, and there doesn't
  // seem to be a good way to capture that data and then get it back into a
  // resource fork. Grr...
  static iconFragment = '^.* Project/Icon';

  // We don't sync files that are in a 'Backup' dir
  static backupFragment = '^.* Project/Backup';

  static excludedFilesRegex = new RegExp(
    this.iconFragment + '|' +
    this.backupFragment //+
  );

  static projectDataRegex = /(.*\/([^\/]*))\/[^\/]*.als$/;

  static projectDataFileGlob = '/*.als';

  static async buildFileList(projectPath) {
    // We include directories here because Ableton is picky about some of them
    // being there and will prevent a dowloaded session from being saved in the
    // same place after opening it.
    var files = await this.glob(projectPath + '/**', { nodir: false });
    var filteredFiles = [];
    for (const file of files) {
      if (!file.match(this.excludedFilesRegex)) {
        filteredFiles.push(file);
      }
    }
    return filteredFiles;
  }

  static async calculateProjectFingerprint(projectPath) {
    const hash = this.crypto.createHash('sha256');
    var projectDataFiles = await this.glob(
      projectPath + this.projectDataFileGlob
    );
    for (const file of projectDataFiles) {
      var localDataFile = new LocalFile(file);
      var fingerprint = await localDataFile.fingerprint();
      hash.update(fingerprint);
    }
    return new Promise((resolve, reject) => {
      resolve(hash.digest('hex'));
    });
  }

  projectName() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectName = match[2];
    return projectName;
  }

  projectPath() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectPath = match[1];
    return projectPath;
  }

  isProjectDataFiile() {
    return (
      this.path.match(this.constructor.projectDataRegex) &&
      !this.path.match(this.constructor.excludedFilesRegex)
    );
  }
}
