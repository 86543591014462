/* import __COLOCATED_TEMPLATE__ from './comment-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked, cached } from '@glimmer/tracking';

export default class IssuesCommentFormComponent extends Component {
  @service store;
  @service currentUser;

  @tracked newComment = null;

  @action
  saveComment() {
    this.newComment.save();
    let comment = this.buildComment();
    this.newComment = comment;
  }

  @action buildNewComment() {
    let comment = this.buildComment();
    this.newComment = comment;
  }

  @action removeNewComment() {
    if (this.newComment) {
      this.newComment.destroyRecord();
    }
  }

  buildComment() {
    let comment = this.store.createRecord('comment', {
      project: this.args.project || this.args.issue.project,
      projectVersion: this.args.projectVersion,
      bounce: this.args.bounce,
      type: 'comment',
      comment: this.args.issue,
      user: this.currentUser.user,
    });
    return comment;
  }
}
