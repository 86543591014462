import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedProjectsShowIssuesNewController extends Controller {
  @service router;

  @action cancel() {
    console.log('cancel');
    this.router.transitionTo('authenticated.projects.show.issues.index');
  }

  @action async save() {
    console.log('save');
    let issue = this.get('model.issue');
    try {
      await issue.save();
      this.router.transitionTo(
        'authenticated.projects.show.issues.show',
        issue.id
      );
    } catch (err) {
      console.log('we caught an error when saving a comment', err);
    }
  }
}
