/* import __COLOCATED_TEMPLATE__ from './seshy-migration-helper.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SeshyMigrationHelperComponent extends Component {
  @service seshyDir;
  @service platform;
  @tracked hasSyncbotDir = false;
  @tracked hasSeshyDir = false;

  @action
  detectSyncbotDirectory() {
    if (!this.platform.isNative) {
      return;
    }
    const fs = require('fs');
    this.hasSyncbotDir = fs.existsSync(this.seshyDir.syncbotPath);
    this.hasSeshyDir = fs.existsSync(this.seshyDir.path);
  }

  get hasDirCollission() {
    return this.hasSyncbotDir && this.hasSeshyDir;
  }

  @action
  moveSyncbotFolder() {
    const fs = require('fs');

    // It might seem like we should do this:
    //let oldPath = this.seshyDir.syncbotPath + "/.syncbot";
    //let newPath = this.seshyDir.syncbotPath + "/.seshy";
    //if(fs.existsSync(oldPath)){
    //fs.renameSync(oldPath, newPath);
    //}
    // But the package_data.json file contains full paths, we don't want to store those.
    // We'll regenerate it after the first full sync attempt and then things should be good.

    // Now we move ~/Syncbot to ~/Seshy (or ~/Syncbot-development => ~/Seshy-development)
    let oldPath = this.seshyDir.syncbotPath;
    let newPath = this.seshyDir.path;
    if (fs.existsSync(oldPath)) {
      fs.renameSync(oldPath, newPath);
    }

    this.detectSyncbotDirectory();
  }
}
