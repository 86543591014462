import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedTeamsListIndexController extends Controller {
  @tracked searchValue = '';

  get filteredTeams() {
    let archived = this.archived;
    let teams = this.model;
    let filteredTeams = teams;

    if (this.searchValue != '') {
      var pattern = new RegExp(this.searchValue, 'i');
      filteredTeams = filteredTeams.filter(function (item, index, enumerable) {
        var data = item.get('name');
        return pattern.test(data);
      });
    }
    return filteredTeams;
  }
}
