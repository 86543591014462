/* import __COLOCATED_TEMPLATE__ from './project-version-tabs.hbs'; */
import Component from '@glimmer/component';

export default class ProjectVersionTabsComponent extends Component {
  menuItems = [
    {
      name: 'Overview',
      link: 'authenticated.projects.show.project-versions.show.index',
    },
    {
      name: 'Files',
      link: 'authenticated.projects.show.project-versions.show.files',
    },
    {
      name: 'Bounces',
      link: 'authenticated.projects.show.project-versions.show.bounces',
    },
  ];
}
