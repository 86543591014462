/* import __COLOCATED_TEMPLATE__ from './issue-close-button.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class IssueCloseButtonComponent extends Component {
  @tracked menuOpen = false;
  @tracked closeState = 'complete';

  get hasComment() {
    return this.args.comment?.body && this.args.comment?.body != '';
  }

  @action
  setInitialCloseSate() {
    console.log('setting initial close state');
    if (
      this.args.currentIssueState == 'complete' ||
      this.args.currentIssueState == 'closed'
    ) {
      this.closeState = 'open';
    }
  }

  @action
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    console.log('toggled menu to ', this.menuOpen);
  }

  @action
  closeIssue() {
    this.args.closeIssueAction(this.closeState);
    if (this.hasComment && this.args.saveAction) {
      this.args.saveAction();
    }
  }

  @action
  markAsComplete() {
    this.closeState = 'complete';
    this.menuOpen = false;
  }

  @action
  markAsClosed() {
    this.closeState = 'closed';
    this.menuOpen = false;
  }

  @action
  markAsOpen() {
    this.closeState = 'open';
    this.menuOpen = false;
  }
}
