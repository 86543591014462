import Model, { attr, hasMany } from '@ember-data/model';

export default class TeamModel extends Model {
  @attr('string') name;
  @attr('string') obfuscatedId;
  @attr currentUserRoles;
  @attr projectsCount;
  @attr projectsSizeInBytes;
  @attr labelString;
  @hasMany('team-project', { inverse: 'team', async: false }) teamProjects;
  @hasMany('project', { inverse: 'team', async: false }) projects;
  @hasMany('user', { inverse: 'teams', async: false }) users;
}
