// Ideally we want to do this:
//import Model, { attr, belongsTo } from '@ember-data/model';

// But due to the bug mentioned in ./base we do this:
import Model from './base';
import { attr, belongsTo, hasMany } from '@ember-data/model';

import { tracked } from '@glimmer/tracking';

export default class BounceModel extends Model {
  @belongsTo('project-version', { inverse: 'bounces', async: false })
  projectVersion;
  @belongsTo('user', { inverse: null, async: false }) user;
  @hasMany('comment', { inverse: 'bounce', async: false }) comments;
  @attr('string') checksum;
  @attr('string') fileName;
  @attr('boolean') uploadComplete;
  @attr() sizeInBytes;
  @attr() signedUploadData;
  @attr() peaks;
  @attr() downloadUrl;

  @tracked percentPlayed = 0;

  get duration() {
    return this.peaks.length / 5 / 2;
  }

  identifier() {
    if (this) {
      return this.downloadUrl;
    } else {
      return null;
    }
  }

  get soundId() {
    return `bounce_${this.id}`;
  }
}
