/* import __COLOCATED_TEMPLATE__ from './project-renamer.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import canUserManageProject from 'seshy/utils/can-user-manage-project';

export default class ProjectRenamerComponent extends Component {
  @service currentUser;
  @service projectVersionRepository;
  @service platform;

  @tracked isEditing = false;

  @tracked projectName = '';

  @tracked errorMessage = null;

  get hasError() {
    return !!this.errorMessage;
  }

  get canManageProject() {
    return canUserManageProject(this.currentUser, this.args.project);
  }

  get hasLocalProject() {
    var projectData = this.projectVersionRepository.dataFor(
      this.args.project.id
    );
    return !!projectData;
  }

  get localProjectName() {
    var projectData = this.projectVersionRepository.dataFor(
      this.args.project.id
    );

    if (projectData) {
      var localPathProjectName = projectData.localPath.split('/').pop();
      return localPathProjectName;
    }
    return '';
  }

  get hasNameConflict() {
    return (
      this.hasLocalProject &&
      !(this.localProjectName == this.args.project.get('name'))
    );
  }

  @action
  startEditing() {
    this.projectName = this.args.project.get('displayName');
    console.log('startEditing');
    this.isEditing = true;
  }

  @action
  cancel() {
    this.isEditing = false;
  }

  @action
  async rename() {
    var oldName = this.args.project.get('name');
    var newName = this.projectName + this.args.project.get('suffix');

    var oldLocalPath = this.projectVersionRepository.localPathFor(
      this.args.project,
      null
    );
    console.log('oldLocalPath', oldLocalPath);
    if (oldLocalPath) {
      this.projectVersionRepository.updateProjectDataForRename(
        this.args.project.id,
        oldName,
        newName
      );
      var newLocalPath = this.projectVersionRepository.localPathFor(
        this.args.project,
        null
      );
      console.log('newLocalPath', newLocalPath);

      let os = require('os');
      if (os.platform() === 'win32') {
        oldLocalPath = oldLocalPath.replaceAll('/', '\\');
        newLocalPath = newLocalPath.replaceAll('/', '\\');
      }
      const fs = require('fs');
      fs.renameSync(oldLocalPath, newLocalPath);
      console.log('just renamed the project on the filesystem');
    }

    this.args.project.set('name', newName);

    try {
      await this.args.project.save();
      console.log('just saved the new project name');
      this.isEditing = false;
    } catch (error) {
      console.log('caught an error', error);

      let errorCause = error.errors[0];

      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  @action
  async resolveConflict() {
    var oldName = this.localProjectName;
    var newName = this.args.project.get('name');

    var oldLocalPath = this.projectVersionRepository.localPathFor(
      this.args.project,
      null
    );
    console.log('oldLocalPath', oldLocalPath);
    if (oldLocalPath) {
      this.projectVersionRepository.updateProjectDataForRename(
        this.args.project.id,
        oldName,
        newName
      );
      var newLocalPath = this.projectVersionRepository.localPathFor(
        this.args.project,
        null
      );
      console.log('newLocalPath', newLocalPath);
      const fs = require('fs');
      fs.renameSync(oldLocalPath, newLocalPath);
      console.log('just renamed the project on the filesystem');
    }

    //this.isEditing = false;
  }

  setForbiddenMessage() {
    this.errorMessage =
      "You don't seem to be a Team Admin for the team that owns this project.";
  }

  setUnknownErrorMessage() {
    this.errorMessage =
      'We ran into an unexpected error while trying to rename the cloud project. Please try again.';
  }
}
