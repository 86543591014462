import BaseRoute from 'seshy/routes/base';
import { action } from '@ember/object';

export default class AuthenticatedProjectsShowProjectVersionsRoute extends BaseRoute {
  model(params) {
    return params;
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    let listIndexController = this.controllerFor(
      'authenticated.projects.show.project-versions.list'
    );
    listIndexController.set('searchValue', model.searchValue);
  }

  @action
  propagateSearch(searchValue) {
    console.log('propagateSearch', searchValue);
    this.controllerFor('authenticated.projects.show.project-versions.list').set(
      'searchValue',
      searchValue
    );
  }
}
