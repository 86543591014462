/* import __COLOCATED_TEMPLATE__ from './project-download-manager-status.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ProjectDownloadManagerStatusComponent extends Component {
  @service projectDownloadManager;

  get operations() {
    return this.projectDownloadManager.operations;
  }
}
