/* import __COLOCATED_TEMPLATE__ from './mini-map.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';

import MinimapPlugin from 'wavesurfer.js/plugins/minimap';

import { Node } from 'ember-composability-tools';

export default class BouncePlayerWavesurferMiniMapComponent extends Node {
  @tracked miniMap = null;

  _insertPosition = 'beforebegin';
  _height = 30;
  _waveColor = '#059669';
  _progressColor = '#047857';
  _overlayColor = 'rgba(125,125,125,0.25)';
  _barHeight = 0.4;
  _interact = true;
  _dragToSeek = false;
  _style = {
    marginBottom: '0em',
    paddingTop: '1em',
  };

  @action
  didInsertParent(element) {
    console.log('BP:W:MiniMap : didInsertParent', this.args.id, element);
    this._element = element;
    this._buildMiniMap.perform();
    // The topmost parent hook call.
    // Here we have a `element` available and
    // we are certain that none of the children's
    // `didInsertParent` hooks were called
  }

  @action
  willDestroyParent(element) {
    console.log('BP:W:MiniMap : willDestroyParent', this.args.id, element);
    this._destroyMiniMap.perform();
    // the reverse is applied here.
    // We are certain that this call will take place
    // when all of the children's `willDestroyParent`
    // were called.
  }

  _buildMiniMap = task(async () => {
    console.log('building the miniMap', this.args.bounce);
    // TODO: How to deal with the minimap timeline?
    /*
    this.minimapTimeline = await TimelinePlugin.create({
      height: 12,
      timeInterval: 15,
      primaryLabelInterval: 60,
      style: {
        fontSize: '0.75rem',
        color: '#999',
        lineHeight: '1em',
        //marginTop: '0.5em',
        marginBottom: '0em',
      },
    })
    */
    this.miniMap = await MinimapPlugin.create({
      insertPosition: this.insertPosition,
      height: this.height,
      waveColor: this.waveColor,
      progressColor: this.progressColor,
      overlayColor: this.overlayColor,
      barHeight: this.barHeight,
      interact: this.interact,
      dragToSeek: this.dragToSeek,
      style: this.style,
    });
    console.log('this.miniMap = ', this.miniMap);

    this.args.parent.wavesurfer.registerPlugin(this.miniMap);
    // We have to call this since we're adding the minimap after the wavesurfer is ready
    // https://github.com/katspaugh/wavesurfer.js/issues/3566
    this.miniMap.initMinimap();
  });

  _destroyMiniMap = task(async () => {
    if (this.miniMap) {
      this.miniMap.destroy();
      this.miniMap = null;
    }
  });

  get insertPosition() {
    return this.args.insertPosition || this._insertPosition;
  }

  get height() {
    return this.args.height || this._height;
  }

  get waveColor() {
    return this.args.waveColor || this._waveColor;
  }

  get progressColor() {
    return this.args.progressColor || this._progressColor;
  }

  get overlayColor() {
    return this.args.overlayColor || this._overlayColor;
  }

  get barHeight() {
    return this.args.barHeight || this._barHeight;
  }

  get interact() {
    return this.args.interact || this._interact;
  }

  get dragToSeek() {
    return this.args.dragToSeek || this._dragToSeek;
  }

  get style() {
    return this.args.style || this._style;
  }

  get wavesurfer() {
    return this.miniMap.miniWavesurfer;
  }
}
