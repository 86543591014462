/* import __COLOCATED_TEMPLATE__ from './subscription-required-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from 'seshy/config/environment';
import { inject as service } from '@ember/service';

export default class SubscriptionRequiredBannerComponent extends Component {
  @service subscriptionTracker;

  get starting() {
    return this.subscriptionTracker.starting;
  }

  get teamData() {
    return this.subscriptionTracker.get('teamData');
  }

  get subscriptionRequired() {
    return this.subscriptionTracker.get('teamData').length > 0;
  }

  @action
  openSubscriptionsPage(teamId) {
    //e.preventDefault();
    var page =
      ENV.api.webhost + `/account/teams/${teamId}/billing/subscriptions`;
    console.log('yo3', page);

    let shell = require('electron').shell;
    shell.openExternal(page);
    return true;
  }
}
