import Model, { attr, belongsTo } from '@ember-data/model';

export default class DebugReportModel extends Model {
  @attr seshyDirData;
  @attr projectVersionRepositoryData;
  @attr settingsData;
  @attr watchedFolderData;

  @belongsTo('user', { async: false, inverse: null }) user;
}
