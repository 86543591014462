/* import __COLOCATED_TEMPLATE__ from './bounce-player.hbs'; */
import Component from '@glimmer/component';
import BouncePlayerWavesurfer from './bounce-player-wavesurfer';

// TODO : Remove these as possible
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/plugins/timeline';
import { later } from '@ember/runloop';

export default class BouncePlayerComponent extends BouncePlayerWavesurfer {
  //TODO: For some reason this breaks things if I put it in the BouncePlayerWavesurfer class...
  @action
  wavesurferMaskClick() {
    console.log('wavesurfer mask click');
    this.togglePlaySoundTask.perform();
  }
}
