/* import __COLOCATED_TEMPLATE__ from './project-download-button.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ProjectDownloadButtonComponent extends Component {
  @service projectDownloadManager;

  @action
  downloadProject() {
    this.projectDownloadManager.downloadProjectVersion(
      this.args.project,
      this.args.projectVersion
    );
  }
}
