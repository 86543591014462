/* import __COLOCATED_TEMPLATE__ from './new-bounce-warning.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class IssuesNewBounceWarningComponent extends Component {
  @tracked showNewBounce = false;

  @action
  toggleShowNewBounce() {
    this.showNewBounce = !this.showNewBounce;
  }

  get latestBounce() {
    return this.args.issue.project.get('currentBounce');
  }

  get newBounceAvailable() {
    let issueBounce = this.args.issue.bounce;
    if (!issueBounce) {
      return false;
    }
    let latestBounce = this.args.issue.get('project.currentBounce');
    if (!latestBounce) {
      return false;
    }
    console.log('newBounce issueBounce.id =', issueBounce.id);
    console.log('newBounce latestBounce.id =', latestBounce.get('id'));
    return issueBounce.id != latestBounce.get('id');
  }
}
