/* import __COLOCATED_TEMPLATE__ from './side-list.hbs'; */
import Component from '@glimmer/component';
import { sort, filter, uniqBy } from '@ember/object/computed';

export default class ProjectsSideListComponent extends Component {
  @uniqBy('args.projects', 'id') uniqueProjects;
  updatedAtSorting = ['latestVersionUpdatedAt:desc'];
  @sort('uniqueProjects', 'updatedAtSorting') sortedProjects;

  get limit() {
    return this.args.limit || 10;
  }

  get filteredProjects() {
    return this.sortedProjects.filter((item, index, enumerable) => {
      return index < this.limit;
    });
  }

  get projectsToDisplay() {
    return this.filteredProjects;
  }
}
