import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ProjectsAbstractStatusComponent extends Component {
  @service projectVersionRepository;
  @service projectUploadManager;
  @service projectDownloadManager;
  @service currentUser;
  @service platform;

  get projectId() {
    return this.args.project.get('id');
  }

  get latestProjectDataChecksum() {
    return this.args.project.get('latestProjectDataChecksum');
  }

  get isCurrent() {
    var ourChecksum = this.latestProjectDataChecksum;
    var repoChecksum = this.projectVersionRepository.versionFor(this.projectId);
    return ourChecksum == repoChecksum;
  }

  get localProjectMissing() {
    return this.projectVersionRepository.localProjectMissingFor(this.projectId);
  }

  get isPrivate() {
    //return false;
    let teams = this.args.project.get('teams');
    return teams.length == 1 && teams.at(0).get('name') == 'Personal';
  }

  get uploadOperation() {
    if (!this.platform.isNative) {
      return null;
    }
    if (this.args.project) {
      var operation = this.projectUploadManager.operationForProjectId(
        this.projectId
      );
      //console.log('uploadOperation = ', operation);

      // TODO: is this a good way to do this?
      //console.log('we have an operation!!!!!!!!!!!', operation);
      if (operation) {
        // TODO : Figure out how to make this work. And then re-enable it in the
        // project open button.
        //this.editingDescription = true;
      }

      return operation;
    }
    //console.log('we do not have a project', this.args.project);
    return null;
  }

  get downloadOperation() {
    if (this.args.project) {
      var operation =
        this.projectDownloadManager.operationForProjectIdAndChecksum(
          this.projectId,
          this.latestProjectDataChecksum
        );
      //console.log('downloadOperation = ', operation);
      return operation;
    }
    //console.log('we do not have a project for downloadOperation = ', this.args.project);
    return null;
  }

  get hasNameConflict() {
    var projectData = this.projectVersionRepository.dataFor(this.projectId);
    if (projectData) {
      var localPathProjectName = projectData.localPath.split('/').pop();
      return !(localPathProjectName == this.args.project.get('name'));
    }
    return false;
  }
}
