import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedTeamsListController extends Controller {
  @service router;
  queryParams = ['searchValue'];

  @tracked searchValue = '';

  @action
  controllerRefresh() {
    console.log('controllerRefresh');
    this.send('routeRefresh');
  }

  @action
  setSearch(searchValue) {
    this.searchValue = searchValue;
    this.router.transitionTo({
      queryParams: { searchValue: this.searchValue },
    });
    this.send('propagateSearch', searchValue);
  }
}
