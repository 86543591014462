/* import __COLOCATED_TEMPLATE__ from './toggle-panel.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class TogglePanelComponent extends Component {
  get allowOverflow() {
    if (typeof this.args.allowOverflow !== 'undefined') {
      return this.args.allowOverflow;
    }
    return false;
  }

  get showTrueOverflow() {
    return this.allowOverflow && this.args.toggle;
  }

  get showFalseOverflow() {
    return this.allowOverflow && !this.args.toggle;
  }

  get activePanelClass() {
    return this.args.activePanelClass || '';
  }
}
