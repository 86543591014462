/* import __COLOCATED_TEMPLATE__ from './parent.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { Root } from 'ember-composability-tools';

export default class TestParentComponent extends Root {
  @tracked isReady = false;
  @tracked customChildren = A([]);

  @action
  parentAction() {
    alert('a message from the parent');
    console.log('this.children = ', this.children);
  }

  registerCustomChild(child) {
    this.customChildren.pushObject(child);
  }

  /*async*/ didInsertParent(element) {
    this.realDidInsertParent();
    return;
    console.log('Parent: didInsertParent', this.args.id);
    /*await*/ timeout(this.args.setupTime || 0);
    this.isReady = true;
    console.log('Parent: finally ready', this.args.id);
    /*
    for(let child of this.children){
      console.log('child = ', child);
      child.parentIsReady();
    }
    */
  }

  async realDidInsertParent(element) {
    console.log('Parent: didInsertParent', this.args.id);
    await timeout(this.args.setupTime || 0);
    this.isReady = true;
    console.log('Parent: finally ready', this.args.id);
  }

  @action
  willDestroyParent(element) {
    console.log('Parent: willDestroyParent', this.args.id);
  }

  /*
  async setup(){
    console.log('Parent: setup ', this.args.id);
    await timeout(1000)
    console.log('Parent: setup p2 ', this.args.id);
    await super.setup(...arguments)
  }
  */
}
