/* import __COLOCATED_TEMPLATE__ from './project-list-new.hbs'; */
import Component from '@glimmer/component';
import { sort, filter, uniqBy } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class ProjectListNewComponent extends Component {
  @tracked searchValue = '';
  @tracked archived = 'false';

  @uniqBy('args.projects', 'id') uniqueProjects;

  updatedAtSorting = ['latestVersionUpdatedAt:desc'];
  @sort('filteredProjects', 'updatedAtSorting') sortedProjects;

  @action
  setSearch(searchValue) {
    this.searchValue = searchValue;
    //console.log('setting search value', searchValue);
    /*
    this.router.transitionTo({
      queryParams: { searchValue: this.searchValue },
    });
    */
    //this.send('propagateSearch', searchValue);
  }

  @action
  setArchived(archived) {
    this.archived = archived;
    /*
    this.router.transitionTo({
      queryParams: { archived: this.archived, searchValue: this.searchValue },
    });
    */
    //this.send('propagateArchived', archived);
  }

  get trackingTest() {
    return this.archived + ' ' + this.searchValue;
  }

  get filteredProjects() {
    //console.log('filtering projects...');
    let archived = this.archived;
    let projects = this.uniqueProjects;
    let filteredProjects = projects;

    if (archived == 'true') {
      //filteredProjects = filteredProjects.filterBy('archived', true);
      filteredProjects = filteredProjects.filter((project) => project.archived);
    } else if (archived == 'false') {
      //filteredProjects = filteredProjects.filterBy('archived', false);
      filteredProjects = filteredProjects.filter(
        (project) => !project.archived
      );
    }

    if (this.searchValue != '') {
      var pattern = new RegExp(this.searchValue, 'i');
      filteredProjects = filteredProjects.filter(function (
        item,
        index,
        enumerable
      ) {
        var data = item.get('displayName');
        return pattern.test(data);
      });
    }

    return filteredProjects;
  }
}
