import Transform from '@ember-data/serializer/transform';
import { A } from '@ember/array';
import CommentMarker from 'seshy/models/comment-marker';

export default class MarkersTransform extends Transform {
  deserialize(serialized) {
    //console.log('calling the deserialize method on ', serialized);
    let trackedMarkers = A([]);
    let markers = serialized;
    for (let i = 0, len = markers.length; i < len; i++) {
      let marker = markers[i];
      let newMarker = CommentMarker.create(marker);
      trackedMarkers.push(newMarker);
    }
    //console.log('returning trackedMarkers = ', trackedMarkers);
    return trackedMarkers;
  }

  serialize(deserialized) {
    console.log('calling the serialize method on ', deserialized);
    let newMarkers = [];
    let trackedMarkers = deserialized;
    for (let i = 0, len = trackedMarkers.length; i < len; i++) {
      let marker = trackedMarkers[i];
      newMarkers.push(marker.toSimpleMarker());
    }
    console.log('returning newMarkers = ', newMarkers);
    return newMarkers;
  }
}
