/* import __COLOCATED_TEMPLATE__ from './issue-list-tabs.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class IssueListTabsComponent extends Component {
  menuItems = [
    {
      name: 'Open Issues',
      state: 'open',
    },
    {
      name: 'Completed Issues',
      state: 'complete',
    },
    {
      name: 'Closed Issues',
      state: 'closed',
    },
    {
      name: 'Discussions',
      state: 'comments',
    },
    {
      name: 'All',
      state: null,
    },
  ];

  @tracked currentItem = this.menuItems[0];

  @action
  setIssueState(newState) {
    console.log('setting state', newState);
    return true;
  }

  @action
  changeCurrentItem(newItem) {
    this.currentItem = newItem;
    this.args.setIssueState(newItem.state);
  }
}
