import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { later } from '@ember/runloop';
import randomstring from 'randomstring';

export default class BouncePlayerAbstractComponent extends Component {
  @service currentlyPlaying;
  @service stereo;
  @service session;

  @tracked audioPositionListener = null;
  @tracked _bounce = null;
  @tracked componentElement = null;
  @tracked randomId = null;

  /*
  constructor(){
    super(...arguments);
    // TODO: Do we need this to be a bound method?
    this.boundAudioPositionChanged = this.audioPositionChanged.bind(this);
  }
  */

  get bounce() {
    return this._bounce || this.args.bounce;
  }

  get sound() {
    return this.currentlyPlaying.getSound(this.soundId);
  }

  set bounce(newBounce) {
    if (
      !!this._bounce &&
      this._bounce.id == newBounce?.id &&
      this._bounce.peaks.length == newBounce?.peaks?.length
    ) {
      return;
    } else if (this._bounce) {
      this.cleanUpCurrentBounce();
    }
    this._bounce = newBounce;
    this.randomId = randomstring.generate(6);
    if (this._bounce) {
      later(() => {
        this.wireUpCurrentBounce();
      });
    }
  }

  @action
  setBounce(newBounce, element) {
    //console.log('Abstract: calling setBounce', newBounce.id)
    this.bounce = newBounce;
    if (element) {
      this.componentElement = element;
    }
  }

  @action
  unsetBounce(newBounce) {
    this.bounce = null;
  }

  cleanUpCurrentBounce() {
    //console.log('Abstract cleanUpCurrentBounce', this.bounce.id);
    this.cleanupSoundListeners();
  }

  wireUpCurrentBounce() {
    //console.log('Abstract wireUpCurrentBounce', this.bounce.id);
    this.registerSoundListeners();
  }

  get identifier() {
    return `${this.bounce.downloadUrl}?Authorization=Bearer ${this.session.data.authenticated.access_token}`;
  }

  get soundId() {
    return this.bounce.soundId;
  }

  get playerId() {
    let pId = `bounce-player-${this.bounce?.id}`;
    if (this.args.idSuffix) {
      pId += this.args.idSuffix;
    }
    pId += `-${this.randomId}`;
    return pId;
  }

  togglePlaySoundTask = task(async () => {
    let sound = this.sound;
    if (sound) {
      sound.togglePause();
    } else {
      sound = await this.loadSound();
      await this.updateUiForFirstPlay();
      sound.play();
    }
    this.currentlyPlaying.set('bounce', this.bounce);
    this.afterTogglePlaySound(sound);
  });

  playSoundTask = task(async () => {
    let sound = this.sound;
    if (sound) {
      sound.play();
    } else {
      sound = await this.loadSound();
      await this.updateUiForFirstPlay();
      sound.play();
    }
    this.currentlyPlaying.set('bounce', this.bounce);
    this.afterTogglePlaySound(sound);
  });

  updateSoundPosition(timeInSeconds) {
    //console.log('setting time ', timeInSeconds);
    if (!timeInSeconds) {
      console.error(
        'We tried to updateSoundPosition with an empty value. Bailing out.',
        timeInSeconds
      );
      return;
    }
    let sound = this.currentlyPlaying.getSound(this.soundId);
    if (sound) {
      let newTimeInMs = timeInSeconds * 1000;
      sound.position = newTimeInMs;
    }
  }

  async afterTogglePlaySound() {
    await this.registerSoundListeners();
  }

  async registerSoundListeners(sound) {
    let realSound = this.sound;
    //console.log('Abstract: fired registerSoundListeners and the sound = ', realSound, this.identifier);

    if (!this.audioPositionListener && realSound) {
      //console.log('Abstract: we need to register a listener for audio-position-changed')
      this.audioPositionListener = realSound.on(
        'audio-position-changed',
        this.audioPositionChanged
      );
    } else if (this.audioPositionListener) {
      //console.log('Abstract: we already have a listener for audio-position-changed', this.audioPositionListener)
    } else {
      //console.log('Abstract: we do not have a sound', realSound)
    }
  }

  cleanupSoundListeners(sound) {
    let realSound = this.sound;

    //console.log('Abstract: fired cleanupSoundListeners and the sound = ', realSound);
    if (this.audioPositionListener && realSound) {
      //console.log('Abstract: ARE deregistering audio-position-changed handler', this.audioPositionListener);
      realSound.off('audio-position-changed', this.audioPositionChanged);
      this.audioPositionListener = null;
    } else {
      //console.log('Abstract: NOT deregistering the audio-position-changed handler', sound, this.audioPositionListener)
    }
  }

  async loadSound() {
    console.log(
      'BouncePlayerAbstract: loadSound',
      this.bounce.id,
      this.bounce.fileName
    );
    let randWait = Math.floor(Math.random() * 200); // up to 200 ms
    //console.log('about to wait for ', randWait)
    await timeout(randWait);
    let { sound } = await this.stereo.load(this.identifier, {
      metadata: {
        title: this.bounce.fileName,
        artist: 'via Seshy',
        artwork: [{ src: '/images/seshy_robo.png' }],
      },
    });

    let identifier = this.identifier;
    this.currentlyPlaying.addSound(sound, identifier, this.soundId);
    return sound;
  }

  async updateUiForFirstPlay() {}

  @action
  audioPositionChanged({ sound }) {
    //console.log('audioPositionChanged - Abstract', sound.position);
    if (this.bounce) {
      this.bounce.percentPlayed = (sound.position / sound.duration) * 100;
    }
    //console.log('the sound position = ', sound.position);
    if (this.args.onAudioPositionChanged) {
      this.args.onAudioPositionChanged(sound.position / 1000);
    }
  }
}
