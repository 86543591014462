import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SubscriptionTrackerService extends Service {
  @tracked paymentData = {};
  @tracked starting = null;

  subscriptionRequired(teamId, teamName, projectId, projectName, starting) {
    this.starting = starting;
    console.log(
      'received paymentRequired!',
      teamId,
      teamName,
      projectId,
      projectName,
      starting
    );
    let teamData = this.paymentData[teamId] || {};
    this.paymentData[teamId] = teamData;
    teamData.id = teamId;
    teamData.name = teamName;
    teamData.projects = teamData.projectsData || {};
    let projectData = teamData.projects[projectId] || {};
    projectData.id = projectId;
    projectData.name = projectName;
    teamData.projects[projectId] = projectData;
    console.log('this.paymentData = ', this.paymentData);
    // We reassign this to trigger updates since Ember can't track a hash.
    // https://guides.emberjs.com/release/in-depth-topics/autotracking-in-depth/#toc_plain-old-javascript-objects-pojos
    this.paymentData = this.paymentData;
  }

  get teamData() {
    var teams = [];
    for (var teamId in this.paymentData) {
      console.log('a teamId= ', teamId);
      let teamData = this.paymentData[teamId];
      teams.push(teamData);
    }
    return teams;
  }
}
