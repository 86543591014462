import Component from '@glimmer/component';
import BouncePlayerWavesurfer from './bounce-player-wavesurfer';
import RegionsPlugin from 'wavesurfer.js/plugins/regions';
import TimelinePlugin from 'wavesurfer.js/plugins/timeline';
import { tracked } from '@glimmer/tracking';
import { action, setProperties, set } from '@ember/object';
import { A } from '@ember/array';
import { sort, filter, uniqBy, union } from '@ember/object/computed';
import { task, timeout } from 'ember-concurrency';

export default class BouncePlayerWavesurferMarkedComponent extends BouncePlayerWavesurfer {
  @tracked regions = null;
  @tracked timeline = null;

  @tracked activeMarker = null;

  @tracked showTimeline = true;

  @tracked displayMarkers = A([]);
  @tracked newDisplayMarkers = A([]);
  @union('displayMarkers', 'newDisplayMarkers') allDisplayMarkers;

  async preBuildPlugins() {
    await super.preBuildPlugins();
    await this.buildTimeline();
  }

  async buildTimeline() {
    if (!this.showTimeline) {
      return;
    }
    this.timeline = await TimelinePlugin.create({
      height: 12,
      timeInterval: 5,
      primaryLabelInterval: 15,
      style: {
        fontSize: '0.75rem',
        color: '#059669',
        lineHeight: '1em',
        marginTop: '0.25em',
      },
    });
    this.plugins.push(this.timeline);
  }

  async buildPlugins() {
    await super.buildPlugins();
    //console.log('about to wait before building regions')
    //await timeout(2000);
    await this.buildRegions();
    await this.buildCommentMarkers();
  }

  async buildRegions() {
    //console.log('Marked: calling build regions', this.regions);
    this.regions = await RegionsPlugin.create({});
    let newRegions = await this.wavesurfer.registerPlugin(this.regions);
  }

  async registerUiHandlers() {
    await super.registerUiHandlers();
  }

  @action
  setActiveMarker(comment, marker) {
    console.log('calling setActiveMarker', marker);
    this.removeActiveMarker();
    this.activeMarker = marker;
    if (this.activeMarker) {
      this.activeMarker.isActive = true;
      this.updateWavesurferPosition(this.activeMarker.start);
    }
  }

  @action
  removeActiveMarker() {
    console.log('calling removeActiveMarker', this.activeMarker);
    if (this.activeMarker) {
      this.activeMarker.isActive = false;
    }
    this.activeMarker = null;
  }

  findDisplayMarker(comment, marker) {
    //console.log('calling findDisplayMarker and we have this many allDisplayMarkers', this.allDisplayMarkers.length, this.displayMarkers.length, this.newDisplayMarkers.length);
    for (let i = 0, len = this.allDisplayMarkers.length; i < len; i++) {
      let displayMarker = this.allDisplayMarkers[i];
      //console.log('marker', marker?.id, displayMarker.marker?.id)

      if (
        !!comment &&
        displayMarker.comment == comment &&
        displayMarker.marker?.id == marker?.id
      ) {
        return displayMarker;
      } else if (!comment && displayMarker.marker?.id == marker?.id) {
        return displayMarker;
      }
    }
  }

  nudgePosition(seconds) {
    super.nudgePosition(seconds);
    let newTime = this.wavesurfer.getCurrentTime();
    this.updateActiveMarkerPosition(newTime);
  }

  updateActiveMarkerPosition(timeInSeconds) {
    console.log('calling updateActiveMarkerPosition', timeInSeconds);
    if (!this.activeMarker) {
      console.log('we do not have an activeMarker!');
      return;
    }
    //console.log('we have a marker')
    // TODO: How to handle multiple markers
    let marker = this.activeMarker;
    let displayMarker = this.findDisplayMarker(null, marker);
    //marker.start = timeInSeconds;
    console.log('updating marker ', marker, displayMarker);
    let newEnd = null;
    if (marker.end) {
      let length = marker.end - marker.start;
      newEnd = timeInSeconds + length;
      console.log('newEnd = ', newEnd);
    }
    let newProperties = {
      start: timeInSeconds,
      end: newEnd,
    };
    setProperties(marker, newProperties);

    /*
    let issue = this.newIssue;
    let trackedMarkersArray = issue.markers.toArray();
    set(issue, 'markers', trackedMarkersArray)
    */

    displayMarker.displayMarker.setOptions({
      start: marker.start,
      end: marker.end || marker.start,
    });
  }

  buildSingleCommentMarker(comment, marker) {
    //console.log('building single marker for ', comment.id)

    let oldDisplayMarker = this.findDisplayMarker(comment, marker);
    if (oldDisplayMarker) {
      //console.log('found an oldDisplayMarker')
      this.removeDisplayMarker(oldDisplayMarker);
    } else {
      //console.log('did not find an oldDisplayMarker')
    }

    let elem = document.getElementById(`comment_${comment.id}_icon`);
    let regionContent = null;
    if (elem) {
      regionContent = elem.cloneNode(true);
      regionContent.classList.add('commentIcon');
    }

    //if(elem){
    let displayMarker = this.regions.addRegion({
      start: marker.start,
      content: regionContent,
      //color: '#0000ff',
      color: 'rgba(0,0,255,0.5)',
      // If no end is supplied we have to make a short region for event handling to work
      // https://github.com/katspaugh/wavesurfer.js/issues/3328
      end: marker.end || marker.start + 0.2,
      drag: false,
      resize: false,
      id: `comment_${comment.id}`,
    });
    //console.log('the marker = ', marker);
    this.displayMarkers.pushObject({ comment, marker, displayMarker });
    //console.log('this.displayMarkers = ', this.displayMarkers);

    //comment.marker = marker;
    //console.log('set marker for ', comment, comment.marker);
    //}else{
    //// TODO: Maybe we don't need this whole defensive thing around 'elem' anymore...
    //console.log('the elem is not there so we will try again later');
    //later(() =>{
    //this.buildSingleCommentMarker(comment);
    //});
    //}
  }

  @action
  commentMarkerClick(comment, marker) {
    console.log('commentMarkerClick ------------------------', marker);
    this.updateSoundPosition(marker.start);
    this.updateWavesurferPosition(marker.start);
    this.updateMarkerPosition(marker.start);
    //console.log('done updating positions------------------------')
  }

  @action updateIssueMarker(issue, oldMarker, newMarker) {
    console.log(
      'calling updateIssueMarker',
      JSON.stringify(oldMarker),
      JSON.stringify(newMarker)
    );

    console.log('markers = ', JSON.stringify(issue.markers));

    let displayMarkerData = this.findDisplayMarker(issue, oldMarker);

    if (oldMarker && newMarker) {
      console.log('updating existing marker');
      oldMarker.start = newMarker.start;
      oldMarker.end = newMarker.end;
    } else if (oldMarker) {
      console.log('removing old marker');
      issue.markers.removeObject(oldMarker);
    } else if (newMarker) {
      console.log('adding new marker');
      //issue.createMarker(newMarker);
      //issue.trackedMarkers.pushObject(newMarker);
      //issue.markers.push(newMarker);
    }

    let trackedMarkersArray = issue.markers.toArray();
    issue.set('markers', trackedMarkersArray);

    console.log('markers = ', JSON.stringify(issue.markers));

    if (displayMarkerData && newMarker) {
      console.log('the displayMarker = ', displayMarkerData);
      let newOptions = {
        start: newMarker.start,
        end: newMarker.start,
      };
      if (newMarker.end) {
        newOptions.end = newMarker.end;
      }
      displayMarkerData.displayMarker.setOptions(newOptions);
      //displayMarkerData.marker = newMarker;
    } else if (displayMarkerData) {
      console.log('going to remove some displayMarkerData');
      this.removeDisplayMarker(displayMarkerData);
    } else if (newMarker) {
      console.log(
        'we do not have a displayMarker to update ... so we will make a new one'
      );
      this.buildSingleCommentMarker(issue, newMarker);
    }
  }

  removeDisplayMarker(markerData) {
    this.displayMarkers.removeObject(markerData);
    this.newDisplayMarkers.removeObject(markerData);
    //console.log('markerData', markerData);
    markerData?.displayMarker?.remove();
  }

  @action
  destroyWavesurfer() {
    //console.log('Marked: calling destroyWavesurfer')
    for (let i = 0, len = this.allDisplayMarkers.length; i < len; i++) {
      let displayMarker = this.allDisplayMarkers[i];
      //console.log('displayMarker = ', displayMarker, typeof displayMarker, displayMarker.remove);
      try {
        this.removeDisplayMarker(displayMarker);
      } catch (err) {
        console.error(err);
      }
    }

    if (this.timeline) {
      //console.log('destroying timeline');
      this.timeline.destroy();
      this.timeline = null;
    }
    if (this.regions) {
      //console.log('destroying regions');
      this.regions.destroy();
      this.regions = null;
    }

    super.destroyWavesurfer();
  }
}
