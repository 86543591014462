import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedTeamsShowProjectsShowTeamsController extends Controller {
  queryParams = ['searchValue', 'refreshTimestamp'];

  @tracked searchValue = null;
  //@tracked refreshTimestamp = null;

  @action
  search() {
    console.log('doing search', this.searchValue);
    this.send('searchModel', this.searchValue);
  }

  @action
  controllerRefresh() {
    console.log('controllerRefresh');
    this.send('routeRefresh');
  }
}
