import RSVP from 'rsvp';
import FingerprintMismatchError from '../errors/fingerprint-mismatch-error';
import FileUploadError from '../errors/file-upload-error';

import { Upload } from "@aws-sdk/lib-storage";
import { S3Client, S3 } from "@aws-sdk/client-s3";
import { getApplyMd5BodyChecksumPlugin } from '@aws-sdk/middleware-apply-body-checksum';

//import * as fs from 'node:fs/promises';
//const AWS = require('aws-sdk');

export default class MultipartFileUploadOperationModel {
  progress = 0;

  partSize = 8 * 1024 * 1024;

  queueSize = 4;

  constructor(filePath, projectFileVersion, checksum, store) {
    this.filePath = filePath;
    this.projectFileVersion = projectFileVersion;
    this.checksum = checksum;
    this.store = store;

    // This is handy for testing how we handle random upload failures
    /*
    var rand = Math.random();
    console.log('rand = ', rand);
    if(rand > 0.5){
      this.checksum = "a-fake-checksum";
    }
    */
  }

  async upload(progressCallback) {
    console.log('MultipartFileUploadOperation.upload', this.filePath);

    let federationToken = this.store.createRecord('federation-token', {
      projectFileVersionId: this.projectFileVersion.id,
      sha256Checksum: this.checksum,
      //sha256Checksum: 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'
    });
    await federationToken.save();
    var data = federationToken.data;
    console.log('the data = ', data);

    var creds = data.credentials;

    const fs = require('fs');
    var stats = fs.statSync(this.filePath)
    var total = stats.size;

    const s3Creds = {
      // Use the temporary creds to login to AWS
      accessKeyId: creds["access_key_id"],
      secretAccessKey: creds["secret_access_key"],
      sessionToken: creds["session_token"],
    }

    console.log('s3Creds = ', s3Creds);
    const s3Client = new S3Client({
      region: federationToken.region,
      credentials: s3Creds
    });


    s3Client.middlewareStack.use(
      getApplyMd5BodyChecksumPlugin(s3Client.config)
    )

    //var fs = window.require('fs');
    //const stream = fs.createReadStream(this.filePath);

    const nodeStream = fs.createReadStream(this.filePath, { highWaterMark: this.partSize })
    const iterator = this.nodeStreamToIterator(nodeStream)
    const webStream = this.iteratorToStream(iterator)


    console.log('the stream = ', webStream);


    var startTime = Date.now();

    try{
      const upload = new Upload({
        client: s3Client,
        queueSize: this.queueSize,
        partSize: this.partSize,
        leavePartsOnError: true,
        params: {
          Bucket: federationToken.bucket,
          Key: federationToken.key,
          Body: webStream,
          // We set this to ensure that the checksum is computed the same what that we compute it.
          // This is just for verification purposes. The aws-sdk will copute a checksum on it's own
          // and we're setting the algorithm just to be able to compare with our own checksum. For
          // real multi-part uploads (for large files) the checksum will be different since the lib
          // computs checksums for each part separately. We DO NOT want to set the `ChecksumSHA256`
          // value. If we do that then multi part uploads will fail.
          ChecksumAlgorithm: federationToken.checksumAlgorithm,
          //ChecksumSHA256: federationToken.checksum
        },
      });
      upload.on("httpUploadProgress", (progress) => {
        console.log("httpUploadProgress", progress);
        this.progress = progress.loaded / total;
        progressCallback(this.progress);
      });

      // We unload the presign from the local store so that it doesn't sit around
      // in memory after we're done with it.
      federationToken.unloadRecord();

      const results = await upload.done();

      var elapsedMillis = Date.now() - startTime;
      console.log('multipart took this long to uplaod: ', elapsedMillis, this.filePath);

      console.log('results = ', results);

      const statusCode = results.$metadata.httpStatusCode;
      console.log('statusCode = ', statusCode);

      return statusCode;
    }catch(err){
      console.log('we caught this error in multipart upload');
      console.error(err);
      console.log('err.status', err.status);
      console.log('err.code', err.code);
      console.log('err.message', err.message);
      console.log('err.name', err.name);
      throw(err);
      if(err.name == "BadDigest"){
        throw new FingerprintMismatchError(this.filePath);
      }else if(err.name == "InvalidRequest"){
        throw new FileUploadError(this.filePath, '??', err.message)
      }else if(err.name == "AccessDenied"){
        throw new FileUploadError(this.filePath, '??', err.message)
      }else{
        throw(err);
      }
    }
  }

  /**
   * Taken from Next.js doc
   * https://nextjs.org/docs/app/building-your-application/routing/router-handlers#streaming
   * Itself taken from mozilla doc
   * https://developer.mozilla.org/en-US/docs/Web/API/ReadableStream#convert_async_iterator_to_stream
   * @param {*} iterator 
   * @returns {ReadableStream}
   */
  iteratorToStream(iterator) {
    return new ReadableStream({
      async pull(controller) {
        //console.log('doing a pull', controller);
        const { value, done } = await iterator.next()

        if (done) {
          controller.close()
        } else {

          controller.enqueue(new Uint8Array(value))
        }
      },
    })
  }

  /**
   * From https://github.com/MattMorgis/async-stream-generator
   */
  async *nodeStreamToIterator(stream) {
    for await (const chunk of stream) {
      //console.log('doing a chunk', chunk);
      yield chunk;
    }
  }

}
