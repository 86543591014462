import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';

export default class AuthenticatedProjectsShowIssuesIndexRoute extends BaseRoute {
  @service store;

  async model() {
    let project = this.modelFor('authenticated.projects.show');

    let issues = await this.store.query('comment', {
      filter: { project_id: project.id, top_level: true },
      include: 'user,project,project-version',
    });

    return { project, issues };
  }
}
