import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { pushPayload } from 'seshy/utils/push-payload';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class AuthenticatedTeamsShowRoute extends BaseRoute {
  @service currentUser;
  @service store;

  model(params) {
    return this.store.findRecord('team', params.team_id);
  }

  afterModel(team, transition) {
    this.team = team;
    this.currentUser.set('currentTeam', team);

    this.subscribeForRefresh(
      'ProjectsChannel',
      { team_id: team.id },
      this.setRefreshAvailable
    );
    this.subscribeForRefresh(
      'BouncesChannel',
      { team_id: team.id },
      this.setRefreshAvailable
    );
    this.subscribeForRefresh(
      'CommentsChannel',
      { team_id: team.id },
      this.setRefreshAvailable
    );
  }
}
