/* import __COLOCATED_TEMPLATE__ from './regions.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';

import RegionsPlugin from 'wavesurfer.js/plugins/regions';

import BouncePlayerWavesurferMarkerComponent from './marker';

import { Node } from 'ember-composability-tools';

export default class BouncePlayerWavesurferRegionsComponent extends Node {
  @tracked regions = null;

  @action
  async didInsertParent(element) {
    //console.log('BP:W:Regions : didInsertParent', this.args.id, element)
    this._element = element;
    await this._buildRegions();
    // The topmost parent hook call.
    // Here we have a `element` available and
    // we are certain that none of the children's
    // `didInsertParent` hooks were called
  }

  @action
  willDestroyParent(element) {
    //console.log('BP:W:Regions : willDestroyParent', this.args.id, element)
    this._destroyRegions.perform();
    // the reverse is applied here.
    // We are certain that this call will take place
    // when all of the children's `willDestroyParent`
    // were called.
  }

  @action
  regionIn(region) {
    //console.log('regionIn', region);
    if (this.args.onRegionIn) {
      this.args.onRegionIn(region);
    }
  }

  @action
  regionClick(region) {
    //console.log('regionClick', region);
    if (this.args.onRegionClick) {
      this.args.onRegionClick(region);
    }
  }

  //_buildRegions = task(async () => {
  async _buildRegions() {
    //console.log('starting buildRegions')
    //await timeout(2000)
    this.regions = await RegionsPlugin.create({});

    this.regions.on('region-in', (region) => {
      //console.log('region-in', region);
      this.regionIn(region);
    });
    this.regions.on('region-clicked', (region) => {
      //console.log('region-clicked', region);
      this.regionClick(region);
    });

    await this.args.parent.wavesurfer.registerPlugin(this.regions);
  }
  //})

  _destroyRegions = task(async () => {
    if (this.regions) {
      //console.log('destroying regions');
      this.regions.destroy();
      this.regions = null;
    }
  });
}
