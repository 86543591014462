/* import __COLOCATED_TEMPLATE__ from './play-button.hbs'; */
import Component from '@glimmer/component';

export default class BouncesPlayButtonComponent extends Component {
  defaultSize = 8;

  get size() {
    return this.args.size || this.defaultSize;
  }

  get width() {
    return `w-${this.size}`;
  }

  get height() {
    return `h-${this.size}`;
  }

  get sizeClasses() {
    return `${this.width} ${this.height}`;
  }
}
