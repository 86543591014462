import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class CurrentlyPlayingService extends Service {
  @tracked bounce;

  @tracked loadedSounds = {};

  addSound(sound, identifier, sound_id) {
    this.loadedSounds[sound_id] = { sound, identifier };
    this.loadedSounds = this.loadedSounds;
  }

  getSound(sound_id) {
    //console.log('in currentlyPlaying looking for sound: ', sound_id);
    let data = this.loadedSounds[sound_id];
    //console.log('found sound', data)
    if (data) {
      return data.sound;
    }
    return null;
  }

  getIdentifier(sound_id) {
    //console.log('in currentlyPlaying looking for identifier: ', sound_id);
    let data = this.loadedSounds[sound_id];
    //console.log('found sound', data)
    if (data) {
      return data.identifier;
    }
    return null;
  }

  unloadSound(sound_id) {
    delete this.loadedSounds[sound_id];
    this.loadedSounds = this.loadedSounds;
  }
}
