import Application from '@ember/application';
import Resolver from 'ember-resolver';
import loadInitializers from 'ember-load-initializers';
import config from 'seshy/config/environment';

//import { init as sentryInit } from "@sentry/electron";
//import * as Sentry from "@sentry/electron";
import * as Sentry from '@sentry/ember';
import { RewriteFrames } from '@sentry/integrations';

// We import this here to give us a 'retryable' modifier for concurrency tasks
import 'ember-concurrency-retryable';

//console.log('config = ', config);
//console.log('config.environment = ', config.environment);

// TODO: Theoretically we should call sentryInit here and pass emberInit as the second param.
// Doing that seems to make network calls to the API hang forever. Not sure what's up.
Sentry.init(
  {
    dsn: 'https://426d681bd02149f2a6d4db54f6f875c8@o4505037993869312.ingest.sentry.io/4505038005075968',
    environment: config.environment,
    release: 'Seshy@' + config.APP.version,
    dist: '1',

    integrations: [
      new RewriteFrames({
        prefix: '~/',
        iteratee: (frame) => {
          //console.log('the frame = ', frame);
          //frame.filename = '~/' + frame.filename.split('/').pop();
          frame.filename = '/' + frame.filename.split('/').pop();
          //console.log('now the frame = ', frame);
          return frame;
        },
      }),
    ],

    // This enables automatic instrumentation (highly recommended), but is not
    // necessary for purely manual usage
    //integrations: [new Sentry.BrowserTracing()],
    //integrations: [],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production,
    //tracesSampleRate: 1.0,

    // Configuration for Ember's default tracing integration.
    /*
  browserTracingOptions: {
    tracePropagationTargets: [
      "seshy-app-octolabs.ngrok.io", "seshy-api-octolabs.ngrok.io",
      "app.seshy.me", "api.seshy.me"
    ],
  },
  */

    // TODO: turn this off after things are working
    //debug: true,
  } /*, emberInit*/
);
//Sentry.setTag("release", "Seshy@" + config.APP.version);
//Sentry.setTag("Release", "Seshy@" + config.APP.version);
//alert('setting sentry tag Seshy@' + config.APP.version);

export default class App extends Application {
  modulePrefix = config.modulePrefix;
  podModulePrefix = config.podModulePrefix;
  Resolver = Resolver;
}

loadInitializers(App, config.modulePrefix);
