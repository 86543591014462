import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class AuthenticatedTeamsShowProjectsShowProjectFilesController extends Controller {
  @action
  controllerRefresh() {
    console.log('controllerRefresh');
    this.send('routeRefresh');
  }
}
