import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';

export default class AuthenticatedProjectsShowProjectVersionsShowRoute extends BaseRoute {
  @service store;

  async model(params) {
    //console.log('starting model hook, params = ', params);
    var project = this.modelFor('authenticated.projects.show');
    var team = this.modelFor('authenticated.teams.show');
    var projectVersion = await this.store.findRecord(
      'project-version',
      params.project_version_id,
      {
        include:
          'user,project-file-versions,project-file-versions.project-file,bounces,comments,comments.user,comments.bounce',
      }
    );
    projectVersion.set('project', project);
    projectVersion.set('team', team);
    //console.log('projectVersion = ', projectVersion);
    return projectVersion;
  }
}
