import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { hash } from 'rsvp';

export default class AuthenticatedTeamsShowProjectsShowTeamsIndexRoute extends BaseRoute {
  @service currentUser;
  @service store;

  async model() {
    console.log('project teams route model');
    var project = this.modelFor('authenticated.projects.show');
    var userTeams = this.store.findAll('team');
    var teamProjects = this.store.query('team-project', {
      filter: { projectId: project.id },
      include: 'team',
    });
    //console.log('user_teams = ', userTeams);
    //console.log('teamProjects = ', teamProjects);
    return hash({
      project: project,
      userTeams: userTeams,
      teamProjects: teamProjects,
    });
  }

  @action
  routeRefresh() {
    //console.log('routeRefresh');
    this.refresh();
  }
}
