/* import __COLOCATED_TEMPLATE__ from './refresh-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class RefreshButtonComponent extends Component {
  @action
  refresh() {
    this.args.refreshAction();
  }
}
