/* import __COLOCATED_TEMPLATE__ from './network-operations-monitor.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { union } from '@ember/object/computed';

export default class NetworkOperationsMonitorComponent extends Component {
  @service projectDownloadManager;
  @service projectUploadManager;
  @service platform;

  @union('downloadOperations', 'uploadOperations') allOperations;

  get downloadOperations() {
    return this.projectDownloadManager.operations;
  }

  get uploadOperations() {
    if (!this.platform.isNative) {
      return [];
    }
    return this.projectUploadManager.operations;
  }
}
