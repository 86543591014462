/* import __COLOCATED_TEMPLATE__ from './child.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';

import { Node } from 'ember-composability-tools';

export default class TestChildComponent extends Node {
  @action
  childAction() {
    alert('a message from the child');
    if (this.args.parent) {
      this.args.parent.parentAction();
    }
  }

  /*
  async setup(){
    console.log('Child: setup ', this.args.id);
    await timeout(100)
    console.log('Child: setup p2', this.args.id);
    await super.setup(...arguments)
  }
  */

  parentIsReady() {
    console.log('Child: parent is ready', this.args.id);
  }

  didInsertParent(element) {
    console.log('Child: didInsertParent', this.args.id);
    if (this.args.parent.isReady) {
      //console.log('the parent is ready = ', this.args.parent.isReady);
      this.parentIsReady();
    } else {
      //console.error('the parent is NOT ready = ', this.args.parent.isReady);
    }
  }

  @action
  willDestroyParent(element) {
    console.log('Child: willDestroyParent', this.args.id);
  }
}
