/* import __COLOCATED_TEMPLATE__ from './commentable-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { registerDestructor } from '@ember/destroyable';
import { later } from '@ember/runloop';

export default class CommentsCommentableListComponent extends Component {
  @service store;
  @service currentUser;

  @tracked _newIssue = null;

  get newIssue() {
    if (this._newIssue) {
      return this._newIssue;
    }
    let newIssue = this.createNewIssue();
    later(() => {
      this._newIssue = newIssue;
    });
    return newIssue;
  }

  set newIssue(newNewIssue) {
    this._newIssue = newNewIssue;
  }

  @action
  cleanUpNewIssue() {
    if (this.newIssue) {
      this.newIssue.destroyRecord();
      this.newIssue = null;
    }
  }

  createNewIssue() {
    let issue = this.store.createRecord('comment', {
      project: this.args.project,
      projectVersion: this.args.projectVersion,
      bounce: this.args.bounce,
      type: 'comment',
      comment: this.args.issue,
      user: this.currentUser.user,
    });
    return issue;
  }

  @action async saveComment() {
    console.log('saveComment');
    let newIssue = this.newIssue;
    try {
      await newIssue.save();
      this.newIssue = this.createNewIssue();
      /*
      newIssue = this.store.createRecord('comment',{
        project: project,
        type: "comment",
        comment: issue,
        user: this.currentUser.user
      });
      this.set('model.newIssue', newIssue);
      */
    } catch (err) {
      console.log('we caught an error when saving a sub comment', err);
    }
  }

  @action async closeIssue(status) {
    if (this.newIssue.body) {
      await this.saveComment();
    }
    if (this.args.closeIssueAction) {
      this.args.closeIssueAction(status);
    }
  }
}
