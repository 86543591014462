/* import __COLOCATED_TEMPLATE__ from './network-concurrency-manager.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NetworkConcurrencyManagerComponent extends Component {
  @service settings;

  @tracked fileUploadMax = this.settings.get('fileUploadMax');
  @tracked fileDownloadMax = this.settings.get('fileDownloadMax');

  get hasChanged() {
    return (
      this.fileUploadMax != this.settings.get('fileUploadMax') ||
      this.fileDownloadMax != this.settings.get('fileDownloadMax')
    );
  }

  @action
  fileUploadMaxChanged(changeEvent) {
    this.fileUploadMax = parseInt(changeEvent.target.value);
  }

  @action
  fileDownloadMaxChanged(changeEvent) {
    this.fileDownloadMax = parseInt(changeEvent.target.value);
  }

  @action
  async save() {
    await this.settings.set('fileUploadMax', this.fileUploadMax);
    await this.settings.set('fileDownloadMax', this.fileDownloadMax);
    // We set these again to trigger the hasChanged getter to recompute.
    // Hacky, but it works.
    this.fileUploadMax = this.settings.get('fileUploadMax');
    this.fileDownloadMax = this.settings.get('fileDownloadMax');

    //const { ipcRenderer } = require('electron');
    //ipcRenderer.send('restart-me');
  }

  @action
  cancel() {
    this.fileUploadMax = this.settings.get('fileUploadMax');
    this.fileDownloadMax = this.settings.get('fileDownloadMax');
  }
}
