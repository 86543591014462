/* import __COLOCATED_TEMPLATE__ from './status-icon.hbs'; */
import Component from '@glimmer/component';

export default class IssuesStatusIconComponent extends Component {
  get type() {
    if (typeof this.args.type !== 'undefined') {
      return this.args.type;
    }
    if (this.args.status == 'open') {
      return 'outline';
    }
    return 'solid';
  }
}
