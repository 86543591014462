/* import __COLOCATED_TEMPLATE__ from './issue-form.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

export default class IssueFormComponent extends Component {
  @service currentUser;

  @action
  captureOriginalMarkers() {
    this.originalMarkers = JSON.stringify(this.args.issue.markers); //JSON.stringify(this.args.issue.markers);
    console.log(
      'we are calling captureOriginalMarkers = ',
      this.originalMarkers
    );
  }

  get mode() {
    return this.args.mode ?? 'issue';
  }

  get showBounceControls() {
    if (typeof this.args.showBounceControls !== 'undefined') {
      return this.args.showBounceControls;
    } else {
      return this.args.issue.bounce;
    }
  }

  get isCancelable() {
    //console.log('isCancelable = ', this.args.issue.isNew, !!this.args.cancelAction);
    return !this.args.issue.isNew || !!this.args.cancelAction;
  }

  @action cancelEditing() {
    this.removeExistingMarkers();

    console.log(
      'before restoring the issue markers = ',
      JSON.stringify(this.args.issue.markers)
    );
    console.log('before restoring originalMarkers = ', this.originalMarkers);
    if (!this.args.issue.isNew) {
      this.args.issue.rollbackAttributes();
      //later(() => {
      //this.args.issue.clearMarkers();
      //})
      //this.args.issue.populateTrackedMarkers();
    }
    console.log(
      'after rolling back issue markers = ',
      JSON.stringify(this.args.issue.markers)
    );

    later(this, this.createNewMarkers);

    //this.args.issue.markers = [];
    //this.args.issue.set('trackedMarkers', []);
    /*
    let newMarkers = JSON.parse(this.originalMarkers);
    console.log('newMarkers = ', JSON.stringify(newMarkers));
    for (let i = 0, len = newMarkers.length; i < len; i++) {
      let marker = newMarkers[i];
      console.log('about to call update for ', JSON.stringify(marker))
      if(this.args.updateIssueMarker){ this.args.updateIssueMarker( this.args.issue, null, marker); }
    }
    */
    //if(this.args.updateIssueMarker){ this.args.updateIssueMarker( this.args.issue ); }
    if (this.args.cancelAction) {
      this.args.cancelAction();
    }
  }

  removeExistingMarkers() {
    let markersToRemove = [...this.args.issue.markers];
    console.log(
      'canceling with tis many tracked markers ',
      this.args.issue.markers.length
    );
    for (let i = 0, len = markersToRemove.length; i < len; i++) {
      let marker = markersToRemove[i];
      console.log('-------------------------- remove for ', marker.id);
      if (this.args.updateIssueMarker) {
        this.args.updateIssueMarker(this.args.issue, marker, null);
      }
    }
  }

  createNewMarkers() {
    this.args.issue.clearMarkers();
    let markersToCreate = JSON.parse(this.originalMarkers);
    for (let i = 0, len = markersToCreate.length; i < len; i++) {
      let marker = markersToCreate[i];
      console.log('need to create a marker for ', marker);

      let newMarker = this.args.issue.createMarker(marker);
      if (this.args.updateIssueMarker) {
        this.args.updateIssueMarker(this.args.issue, null, newMarker);
      }
    }
    this.args.issue.resetMarkers();
    console.log(
      'after rebuilding markers ',
      JSON.stringify(this.args.issue.markers)
    );
  }

  @action addMarker() {
    let newStart = 0;
    if (this.args.getCursorTime) {
      newStart = this.args.getCursorTime();
    } else {
      console.log('we do not have a getCursorTime method');
    }
    console.log('newStart= ', newStart, this.args.getCursorTime);
    let newMarker = {
      start: newStart,
      end: null,
      isEditing: true,
      isActive: true,
    };
    newMarker = this.args.issue.createMarker(newMarker);
    console.log('this args.issue.markers = ', this.args.issue.markers);
    if (this.args.updateIssueMarker) {
      this.args.updateIssueMarker(this.args.issue, null, newMarker);
    }
    if (this.args.setActiveMarker) {
      this.args.setActiveMarker(this.args.issue, newMarker);
    }
  }
}
