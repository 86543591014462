import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedProjectsShowIssuesIndexShowController extends Controller {
  @service store;
  @service currentUser;

  @action
  controllerRefresh() {
    console.log('controllerRefresh');
    this.send('routeRefresh');
  }

  @action async closeIssue(closeStatus) {
    console.log('about to close issue with status', closeStatus);
    let issue = this.get('model.issue');

    let originalState = issue.state;

    issue.set('state', closeStatus);

    issue.stateChanges.push({
      originalState: originalState,
      newState: closeStatus,
      createdAtISO: new Date(), // We use this particular name so that it can be sorted with other models
      userId: this.currentUser.user.id,
    });
    await issue.save();
  }
}
