import Service, { inject as service } from '@ember/service';
import ENV from 'seshy/config/environment';
import { tracked } from '@glimmer/tracking';

export default class SeshyDirService extends Service {
  @service platform;

  @tracked path = null;
  @tracked defaultPath = null;
  // Temporary to help with the name transition from syncbot => seshy
  @tracked syncbotPath = null;
  @tracked oldSeshyDataDir = null;

  constructor() {
    super(...arguments);
    if (!this.platform.isNative) {
      return;
    }
    //console.log('SeshyDirService constructor. process.env = ', process.env);
    //console.log(
    //'SeshyDirService constructor. window.process.argv = ',
    //window.process.argv
    //);

    this.pathLib = require('path');

    this.path =
      this.homeDir() +
      this.pathLib.sep +
      'Seshy' +
      (ENV.environment == 'production' ? '' : '-' + ENV.environment);

    this.defaultPath =
      this.homeDir() +
      this.pathLib.sep +
      'Seshy' +
      (ENV.environment == 'production' ? '' : '-' + ENV.environment);

    // Temporary to help with the name transition from syncbot => seshy
    this.syncbotPath =
      this.homeDir() +
      this.pathLib.sep +
      'Syncbot' +
      (ENV.environment == 'production' ? '' : '-' + ENV.environment);

    this.oldSeshyDataDir =
      this.homeDir() +
      this.pathLib.sep +
      'Seshy' +
      (ENV.environment == 'production' ? '' : '-' + ENV.environment) +
      this.pathLib.sep +
      '.seshy';
  }

  homeDir() {
    // HOME == MacOS
    // HOMEDRIVE + HOMEPATH == Windows
    return process.env.HOME || process.env.HOMEDRIVE + process.env.HOMEPATH;
  }
  /*
   * We have to invoke a method from the main process in order to be able to access
   * the system settings for where to store user preferences and application cache.
   *
   * TODO: This might not be the best place for this, but it'll do for now.
   */
  getUserDataDir() {
    for (let i = 0, len = window.process.argv.length; i < len; i++) {
      let arg = window.process.argv[i];
      if (arg.startsWith('--user-data-dir=')) {
        let parts = arg.split('=');
        //console.log('getUserDataDir parts = ', parts);
        return parts[1];
      }
    }
  }

  projectDataFileName() {
    return ENV.environment == 'production'
      ? 'project_data.json'
      : 'project_data_' + ENV.environment + '.json';
  }

  projectDataFilePath() {
    return (
      this.getUserDataDir() + this.pathLib.sep + this.projectDataFileName()
    );
  }

  projectCacheDirName() {
    return ENV.environment == 'production'
      ? 'project_cache'
      : 'project_cache_' + ENV.environment;
  }

  projectCacheDirPath() {
    return (
      this.getUserDataDir() + this.pathLib.sep + this.projectCacheDirName()
    );
  }

  settingsDataFileName() {
    return ENV.environment == 'production'
      ? 'settings.json'
      : 'settings_' + ENV.environment + '.json';
  }

  settingsDataFilePath() {
    return (
      this.getUserDataDir() + this.pathLib.sep + this.settingsDataFileName()
    );
  }
}
