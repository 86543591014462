/* import __COLOCATED_TEMPLATE__ from './team-tabs.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class TeamTabsComponent extends Component {
  @service router;

  menuItems = [
    {
      name: 'Overview',
      link: 'authenticated.teams.show.index',
    },
    {
      name: 'Projects',
      link: 'authenticated.teams.show.projects.list',
    },
    {
      name: 'Issues',
      link: 'authenticated.teams.show.issues.index',
    },
    {
      name: 'Members',
      link: 'authenticated.teams.show.members',
    },
  ];
}
