/* import __COLOCATED_TEMPLATE__ from './timeline.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';

import TimelinePlugin from 'wavesurfer.js/plugins/timeline';

import { Node } from 'ember-composability-tools';

export default class BouncePlayerWavesurferTimelineComponent extends Node {
  @tracked timeline = null;

  @tracked _height = 12;
  @tracked _timeInterval = 5;
  @tracked _primaryLabelInterval = 15;
  @tracked _style = {
    fontSize: '0.75rem',
    color: '#059669',
    lineHeight: '1em',
    marginTop: '0.25em',
  };

  @action
  didInsertParent(element) {
    console.log('BP:W:Timeline : didInsertParent', this.args.id, element);
    this._element = element;
    this._buildTimeline.perform();
    // The topmost parent hook call.
    // Here we have a `element` available and
    // we are certain that none of the children's
    // `didInsertParent` hooks were called
  }

  @action
  willDestroyParent(element) {
    console.log('BP:W:Timeline : willDestroyParent', this.args.id, element);
    this._destroyTimeline.perform();
    // the reverse is applied here.
    // We are certain that this call will take place
    // when all of the children's `willDestroyParent`
    // were called.
  }

  _buildTimeline = task(async () => {
    this.timeline = await TimelinePlugin.create({
      height: this.height,
      timeInterval: this.timeInterval,
      primaryLabelInterval: this.primaryLabelInterval,
      style: this.style,
    });

    console.log(
      'build timeline',
      this.args.parent,
      this.args.parent.wavesurfer
    );
    this.args.parent.wavesurfer.registerPlugin(this.timeline);
  });

  _destroyTimeline = task(async () => {
    if (this.timeline) {
      this.timeline.destroy();
      this.timeline = null;
    }
  });

  get height() {
    return this.args.height || this._height;
  }

  get timeInterval() {
    return this.args.timeInterval || this._timeInterval;
  }

  get primaryLabelInterval() {
    return this.args.primaryLabelInterval || this._primaryLabelInterval;
  }

  get style() {
    return this.args.style || this._style;
  }
}
