/* import __COLOCATED_TEMPLATE__ from './bounce-player-focused-editable.hbs'; */
import Component from '@glimmer/component';
import BouncePlayerFocused from './bounce-player-focused';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class BouncePlayerFocusedEditableComponent extends BouncePlayerFocused {
  @tracked wavesurferHeight = 26;

  @tracked waveColor = '#ffffff';
  @tracked progressColor = '#cbd5e1';
  @tracked showTimeline = false;

  wireUpCurrentBounce() {
    super.wireUpCurrentBounce();
    // TODO: Ideally we wouldn't load the sound first, but it makes it so that
    // the cursor can be positioned immediately.
    this.loadSound();
  }

  @action
  async startDeleting(e) {
    e.preventDefault();
    console.log('startDeleting', this.args.commentDeleteAction);
    if (
      confirm(
        'Are you sure you want to delete this issue and all the comments that are on it?'
      )
    ) {
      let comment = this.args.issue;
      if (this.args.commentDeleteAction) {
        await this.args.commentDeleteAction(comment);
      }
      comment.deleteRecord();
      await comment.save();
    }
  }
}
