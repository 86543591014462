/* import __COLOCATED_TEMPLATE__ from './breadcrumbs-container.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class BreadcrumbsContainerComponent extends Component {
  @service breadcrumbs;
  @service router;

  @action
  navigate(crumb) {
    this.router.transitionTo(crumb.args.route);
  }
}
