import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';

export default class AuthenticatedNetworkMonitorRoute extends BaseRoute {
  @service projectDownloadManager;
  @service projectUploadManager;

  model() {
    return {
      projectDownloadManager: this.projectDownloadManager,
      projectUploadManager: this.projectUploadManager,
    };
  }
}
