/* import __COLOCATED_TEMPLATE__ from './display-panel.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class IssuesDisplayPanelComponent extends Component {
  get hasCommentMarkerClick() {
    return !!this.args.commentMarkerClick;
  }

  @action
  commentMarkerClick(marker) {
    if (this.args.commentMarkerClick) {
      this.args.commentMarkerClick(this.args.issue, marker);
    }
  }
}
