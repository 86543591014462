import { isArrayBuffer } from "@smithy/is-array-buffer";
import { HttpRequest } from "@smithy/protocol-http";
export const applyMd5BodyChecksumMiddleware = (options) => (next) => async (args) => {
    let { request } = args;
    if (HttpRequest.isInstance(request)) {
        const { body, headers } = request;
        if (!hasHeader("content-md5", headers)) {
            let digest;
            if (body === undefined || typeof body === "string" || ArrayBuffer.isView(body) || isArrayBuffer(body)) {
                const hash = new options.md5();
                hash.update(body || "");
                digest = hash.digest();
            }
            else {
                digest = options.streamHasher(options.md5, body);
            }
            request = {
                ...request,
                headers: {
                    ...headers,
                    "content-md5": options.base64Encoder(await digest),
                },
            };
        }
    }
    return next({
        ...args,
        request,
    });
};
export const applyMd5BodyChecksumMiddlewareOptions = {
    name: "applyMd5BodyChecksumMiddleware",
    step: "build",
    tags: ["SET_CONTENT_MD5", "BODY_CHECKSUM"],
    override: true,
};
export const getApplyMd5BodyChecksumPlugin = (config) => ({
    applyToStack: (clientStack) => {
        clientStack.add(applyMd5BodyChecksumMiddleware(config), applyMd5BodyChecksumMiddlewareOptions);
    },
});
const hasHeader = (soughtHeader, headers) => {
    soughtHeader = soughtHeader.toLowerCase();
    for (const headerName of Object.keys(headers)) {
        if (soughtHeader === headerName.toLowerCase()) {
            return true;
        }
    }
    return false;
};
