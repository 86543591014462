import EmberObject from '@ember/object';
import { tracked } from '@glimmer/tracking';
//import cryptoRandomString from 'crypto-random-string';

export default class CommentMarkerModel extends EmberObject {
  @tracked start = null;
  @tracked end = null;
  @tracked id = null;
  @tracked isEditing = false;
  @tracked isActive = false;

  constructor() {
    super(...arguments);
    if (!this.id) {
      this.id = this.generateId();
    }
    //this.id = cryptoRandomString({length: 10, type: 'alphanumeric'});
  }

  generateId() {
    //Can change 7 to 2 for longer results.
    let r = (Math.random() + 1).toString(36).substring(7);
    //console.log('---------- generated id ', r)
    return r;
  }

  toSimpleMarker() {
    return { start: this.start, end: this.end, id: this.id };
  }

  toJSON() {
    return { ...this.toSimpleMarker(), id: this.id, robust: true };
  }
}
