import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedTeamsShowMembersIndexRoute extends BaseRoute {
  @service store;

  searchValue = '';

  async model(params) {
    console.log('running the model hook');
    let { team_id } = this.paramsFor('authenticated.teams.show');
    return this.store.query('user', {
      filter: {
        team_id: team_id,
        //search: this.searchValue,
      },
    });
  }

  @action
  routeRefresh() {
    //console.log('routeRefresh');
    this.refresh();
  }

  afterModel(team, transition) {}
}
