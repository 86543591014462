/* import __COLOCATED_TEMPLATE__ from './project-version-bounce-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProjectVersionBounceListComponent extends Component {
  @service betaFeatures;
  @service session;

  @action
  async deleteBounce(bounce) {
    console.log('we should delete bounce', bounce);
    if (
      confirm('Are you sure you want to delete this bounce? There is no undo.')
    ) {
      console.log('we should really delete bounce', bounce);
      bounce.destroyRecord();
      await bounce.save();
    }
  }

  get authString() {
    return `Authorization=Bearer ${this.session.data.authenticated.access_token}&for_download=true`;
  }
}
