/* import __COLOCATED_TEMPLATE__ from './marker.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class IssueFormMarkerComponent extends Component {
  @tracked originalEnd = null;
  @tracked originalStart = null;
  @tracked _newStart = null;
  @tracked _newEnd = null;

  constructor() {
    super(...arguments);
    this.originalStart = this.args.marker.start;
    this.originalEnd = this.args.marker.end;
  }

  get isEditing() {
    return this.args.marker.isEditing;
  }

  get isActive() {
    return this.args.marker.isActive;
  }

  @action setActiveMarker() {
    if (this.args.setActiveMarker) {
      this.args.setActiveMarker(this.args.issue, this.args.marker);
    }
  }

  @action startEditing() {
    this.args.marker.isEditing = true;
    this.setActiveMarker();
  }

  @action stopEditing() {
    // TODO: Is this actually the right behavior?
    // We lock in changes so that if you edit, change, hit OK, edit, change, hit Cancel
    // we'll save the first change, but not the second.
    this.originalStart = this.args.marker.start;
    this.originalEnd = this.args.marker.end;

    this.args.marker.isEditing = false;

    this.setActiveMarker();
  }

  propogateChanges() {
    if (this.args.updateIssueMarker) {
      this.args.updateIssueMarker(
        this.args.issue,
        this.args.marker,
        this.originalMarker
      );
    }
    if (this.args.onUpdate) {
      this.args.onUpdate();
    }
  }

  @action cancelEditing() {
    this._newStart = null;
    this._newEnd = null;
    this.args.marker.isEditing = false;
    this.args.marker.start = this.originalStart;
    this.args.marker.end = this.originalEnd;
    return;
    this.propogateChanges();
    //this.setActiveMarker();
  }

  get realMarker() {
    console.log('in realMarker', JSON.stringify(this.args.marker));
    return this.args.marker;
  }

  get markerType() {
    return typeof this.args.marker;
  }

  get newStart() {
    let ns = this._newStart || this.args.marker.start;
    return ns;
  }

  set newStart(ns) {
    this._newStart = ns;
  }

  get newEnd() {
    return this._newEnd || this.args.marker.end;
  }

  set newEnd(ne) {
    this._newEnd = ne;
  }

  get start() {
    return this.newStart;
  }

  get end() {
    return this.newEnd;
  }

  get newMarker() {
    return { start: this.newStart, end: this.newEnd };
  }

  get originalMarker() {
    return { start: this.originalStart, end: this.originalEnd };
  }

  get markerSectionLength() {
    console.log(
      'markerSectionLength',
      this.hasMarkerEndTime,
      this.newEnd,
      this.newStart
    );
    if (this.hasMarkerEndTime) {
      return this.newEnd - this.newStart;
    } else {
      return 10;
    }
  }

  set markerSectionLength(newLength) {
    console.log('trying to set new Length', newLength);
  }

  get hasMarkerEndTime() {
    return !!this.newEnd;
  }

  set hasMarkerEndTime(newMarkerEndTime) {
    console.log('calling the setter for hasMarkerEndTime');
  }

  @action toggleEndTime(event) {
    if (this.newEnd) {
      console.log('setting newEnd to null');
      this.newEnd = null;
      this.args.marker.end = null;
    } else {
      console.log('adding end time');
      this.addEndTime();
    }
    this.propogateChanges();
  }

  @action addEndTime() {
    console.log('calling addEndTime', this.args.getCursorTime);
    let currentTime = this.newStart || this.args.marker.start;
    let endTime =
      parseFloat(currentTime) + parseFloat(this.markerSectionLength);

    if (this.args.getCursorTime) {
      let cursorTime = this.args.getCursorTime();
      if (cursorTime && cursorTime > currentTime) {
        endTime = cursorTime;
        console.log('got end time from getCursorTime', endTime);
      } else {
        console.log('not using cursorTime', cursorTime, currentTime);
      }
    }

    this.args.marker.end = endTime;
    this.propogateChanges();
  }

  nudgePosition(seconds) {
    let currentTime = this.newStart;
    let newTime = currentTime + seconds;
    if (this.hasMarkerEndTime) {
      let newEndTime = newTime + this.markerSectionLength;
      this.newEnd = newEndTime;
    }
    // We do this last so taht the calculations above are correct
    this.newStart = newTime;

    this.args.marker.start = this.newStart;
    this.args.marker.end = this.newEnd;
    this.propogateChanges();
    return;

    //this.updateSoundPosition(newTime);
    //this.updateWavesurferPosition(newTime);
    //this.updateMarkerPosition(newTime);
  }

  @action backBig() {
    this.nudgePosition(-5);
  }

  @action backSmol() {
    this.nudgePosition(-1);
  }

  @action forwardBig() {
    this.nudgePosition(5);
  }

  @action forwardSmol() {
    this.nudgePosition(1);
  }

  nudgePositionEnd(seconds) {
    let currentTime = this.newEnd;
    let newTime = currentTime + seconds;

    if (newTime < this.newStart) {
      newTime = this.newStart + 1;
    }

    // We do this last so taht the calculations above are correct
    this.newEnd = newTime;
    this.args.marker.end = newTime;

    this.propogateChanges();
    //this.updateSoundPosition(newTime);
    //this.updateWavesurferPosition(newTime);
    //this.updateMarkerPosition(newTime);
  }

  @action backBigEnd() {
    this.nudgePositionEnd(-5);
  }

  @action backSmolEnd() {
    this.nudgePositionEnd(-1);
  }

  @action forwardBigEnd() {
    this.nudgePositionEnd(5);
  }

  @action forwardSmolEnd() {
    this.nudgePositionEnd(1);
  }

  @action removeMarker() {
    //this.args.issue.markers.remove(this.args.marker);
    //this.args.issue.markers.splice(this.args.issue.markers.indexOf(this.args.marker), 1);
    this.args.issue.removeMarker(this.args.marker);
    this.propogateChanges();
    this.setActiveMarker();
  }
}
