/* import __COLOCATED_TEMPLATE__ from './file-upload-error-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';

export default class FilesLockedBannerComponent extends Component {
  @service projectUploadManager;

  @action
  retryUpload() {
    var projectData = this.args.project.get('retryProjectData');
    console.log('projectData for retry = ', projectData);
    this.args.project.set('fileUploadError', false);
    this.args.project.fileUploadErrorFiles = A([]);

    this.projectUploadManager.projectUpdated(projectData);
  }
}
