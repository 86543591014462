/* import __COLOCATED_TEMPLATE__ from './project-tabs.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ProjectTabsComponent extends Component {
  @service router;

  menuItems = [
    {
      name: 'Overview',
      link: 'authenticated.projects.show.index',
    },
    {
      name: 'Versions',
      link: 'authenticated.projects.show.project-versions.list',
    },
    {
      name: 'Issues',
      link: 'authenticated.projects.show.issues.index',
    },
    {
      name: 'Files',
      link: 'authenticated.projects.show.project-files',
    },
    {
      name: 'Teams',
      link: 'authenticated.projects.show.teams',
    },
    {
      name: 'Settings',
      link: 'authenticated.projects.show.settings',
    },
  ];
}
