/* import __COLOCATED_TEMPLATE__ from './invite-team-member-button.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from 'seshy/config/environment';
import { inject as service } from '@ember/service';

export default class InviteTeamMemberButtonComponent extends Component {
  @service platform;

  @action
  openNewInvitePage(e) {
    e.preventDefault();
    var page =
      ENV.api.webhost +
      `/account/teams/${this.args.team.obfuscatedId}/invitations/new`;
    console.log('yo', page);
    if (this.platform.isNative) {
      let shell = require('electron').shell;
      shell.openExternal(page);
    } else {
      window.open(page);
    }
  }

  get isPersonal() {
    return this.args.team.name == 'Personal';
  }
}
