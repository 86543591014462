import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthenticatedProjectsShowIssuesNewRoute extends BaseRoute {
  @service store;
  @service currentUser;

  model() {
    let project = this.modelFor('authenticated.projects.show');
    let issue = this.store.createRecord('comment', {
      project: project,
      type: 'issue',
    });

    let bounces = this.store.query('bounce', {
      filter: { project_id: project.id },
      include: 'user,project-version',
    });

    let model = { issue, project, bounces };
    console.log('model ==============', model);
    return hash(model);
  }
}
