/* import __COLOCATED_TEMPLATE__ from './project-open-button.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ProjectOpenButtonComponent extends Component {
  @service projectVersionRepository;
  @service projectDownloadManager;

  shell = require('electron').shell;
  os = require('os');

  @tracked project;
  @tracked menuOpen = false;

  @action
  openProject() {
    var path = this.projectVersionRepository.localPathFor(this.args.project);
    if (this.os.platform() === 'win32') {
      path = path.replaceAll('/', '\\');
    }
    console.log('opening path', path);
    this.shell.openPath(path);
  }

  @action
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
    console.log('toggled menu to ', this.menuOpen);
  }

  @action
  forceDownload(e) {
    e.preventDefault();
    console.log(
      'starting a download for ',
      this.args.project,
      this.args.projectVersion
    );
    this.projectDownloadManager.downloadProjectVersion(
      this.args.project,
      this.args.projectVersion
    );
  }

  @action
  addDescription(e) {
    e.preventDefault();
    this.menuOpen = false;
    this.args.addDescription();
  }
}
