import Service from '@ember/service';
import ENV from 'seshy/config/environment';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

export default class VersionCheckerService extends Service {
  @tracked latestVersion = null;
  @tracked ourVersion = null;

  interval = 1000 * 60 * 10; // every 10 minutes
  //interval = 1000 * 2; // every 2 seconds for testing

  async start() {
    this.ourVersion = ENV.APP.version;
    this.periodicHandler();
  }

  periodicHandler() {
    this.getLatestVersion();
    later(this, this.periodicHandler, this.interval);
  }

  async getLatestVersion() {
    try {
      let url = ENV.api.webhost + '/app/version_check';
      //console.log('version check url = ', url);
      let response = await fetch(url);
      //console.log('version check response = ', response);
      let parsed = await response.json();
      //console.log('parsed = ', parsed);
      this.latestVersion = parsed.latestVersion;
    } catch (error) {
      if (error instanceof TypeError && error.message == 'Failed to fetch') {
        // Do nothing. This happens sometimes for random unexplained reasons and it's not a big deal.
      } else {
        console.error('verison checker caught an unexpected error', error);
        throw error;
      }
    }
  }

  get isUpToDate() {
    // this check prevents a flash of the VersionUpdateAlert if we're actually up to date.
    if (this.latestVersion) {
      return this.ourVersion == this.latestVersion;
    } else {
      return true;
    }
  }
}
