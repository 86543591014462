/* import __COLOCATED_TEMPLATE__ from './issue-list.hbs'; */
import Component from '@glimmer/component';
import { sort, filter, uniqBy } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class IssueListComponent extends Component {
  @tracked searchValue = '';
  @tracked state = 'open';

  @uniqBy('args.issues', 'id') uniqueIssues;

  createdAtSorting = ['createdAt:desc'];
  @sort('filteredIssues', 'createdAtSorting') sortedIssues;

  @action
  setSearch(searchValue) {
    this.searchValue = searchValue;
    //console.log('setting search value', searchValue);
    /*
    this.router.transitionTo({
      queryParams: { searchValue: this.searchValue },
    });
    */
    //this.send('propagateSearch', searchValue);
  }

  @action
  setIssueState(state) {
    this.state = state;
    /*
    this.router.transitionTo({
      queryParams: { archived: this.archived, searchValue: this.searchValue },
    });
    */
    //this.send('propagateArchived', archived);
  }

  get filteredIssues() {
    console.log('filtering issues...');
    //let archived = this.archived;
    let issues = this.uniqueIssues;
    let filteredIssues = issues;

    if (this.state) {
      if (this.state == 'comments') {
        filteredIssues = filteredIssues.filterBy('type', 'comment');
      } else {
        filteredIssues = filteredIssues.filterBy('state', this.state);
      }
    }
    /*
    if (archived == 'true') {
      filteredProjects = filteredProjects.filterBy('archived', true);
    } else if (archived == 'false') {
      filteredProjects = filteredProjects.filterBy('archived', false);
    }
    */

    if (this.searchValue != '') {
      var pattern = new RegExp(this.searchValue, 'i');
      filteredIssues = filteredIssues.filter(function (
        item,
        index,
        enumerable
      ) {
        var data = item.get('title') + ' ' + item.get('body');
        return pattern.test(data);
      });
    }

    return filteredIssues;
  }
}
