/* import __COLOCATED_TEMPLATE__ from './minimal.hbs'; */
import BouncePlayerAbstract from '../bounce-player-abstract';
import { action } from '@ember/object';

export default class BouncePlayerMinimalComponent extends BouncePlayerAbstract {
  @action
  playSoundFromArgs() {
    //console.log('playSoundFromArgs');
    if (this.sound) {
      if (!this.sound.isPlaying) {
        this.sound.play();
      }
    } else {
      this.togglePlaySoundTask.perform();
    }
  }

  async afterTogglePlaySound() {
    super.afterTogglePlaySound();
    this.updateSoundPosition(this.args.startTime);
  }
}
