import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class BaseRoute extends Route {
  @service cable;
  @tracked subscriptions = A([]);

  @action
  routeRefresh() {
    //console.log('routeRefresh');
    this.refresh();
  }

  @action
  async loading(transition, originRoute) {
    //console.log('starting the loading event');
    let start = new Date();
    let controller = this.controllerFor('authenticated');
    controller.startLoading();
    transition.promise.finally(function () {
      let end = new Date();
      //console.log('ending the loading event')
      controller.stopLoading();
      //controller.set('currentlyLoading', false);
      console.debug('loading event took', end - start);
    });
    return true;
  }

  subscribeForRefresh(channel, query, callback) {
    //console.log('calling subscribeForRefresh', channel, query);
    let identifier = JSON.stringify({ channel, ...query });
    const existingSubscriptions = this.subscriptions.filter(
      (sub) => !!sub && sub.identifier == identifier
    );
    if (existingSubscriptions.length) {
      //console.log('do not need to resubscribe');
      return;
    }
    //console.log('the identifier = ', identifier);
    let subscription = null;
    //if (callback) {
    //subscription = this.cable.subscribe(channel, query, callback.bind(this));
    //}else{
    subscription = this.cable.subscribe(
      channel,
      query,
      this.refresh.bind(this)
    );
    //}
    //console.log('the subscription = ', subscription)
    this.subscriptions.pushObject(subscription);
  }

  deactivate() {
    //console.log('subscriptions = ', this.subscriptions.length, this.subscriptions)
    for (let i = 0, len = this.subscriptions.length; i < len; i++) {
      let sub = this.subscriptions[i];
      //console.log('unsubscribing ', sub);
      this.cable.unsubscribe(sub);
    }
    this.subscriptions.clear();
  }

  setRefreshAvailable() {
    //console.log('setting refresh available = ', this.routeName)
    let controller = this.controllerFor(this.routeName);
    controller.set('refreshAvailable', true);
  }

  afterModel() {
    let controller = this.controllerFor(this.routeName);
    controller.set('refreshAvailable', false);
  }
}
