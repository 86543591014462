/* import __COLOCATED_TEMPLATE__ from './local-project-missing-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LocalProjectMissingBannerComponent extends Component {
  @service projectVersionRepository;

  get localProjectMissing() {
    return this.projectVersionRepository.localProjectMissingFor(
      this.args.project.id
    );
  }

  get localProjectPath() {
    var data = this.projectVersionRepository.dataFor(this.args.project.id);
    return data.localPath;
  }

  @action
  disconnectLocalCopy() {
    console.log('we should disconnect');
    var data = this.projectVersionRepository.dataFor(this.args.project.id);
    this.projectVersionRepository.removeProjectDataByLocalPath(data.localPath, {
      skipSave: true,
    });
    console.log('we disconnected');
  }
}
