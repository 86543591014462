/* import __COLOCATED_TEMPLATE__ from './debug-report-capturer.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class DebugReportCapturerComponent extends Component {
  @service store;
  @service seshyDir;
  @service settings;
  @service projectVersionRepository;

  @tracked state = 'waiting';

  get isWaiting() {
    return this.state == 'waiting';
  }

  get isSubmitting() {
    return this.state == 'submitting';
  }

  @action
  async submitDebugReport() {
    this.state = 'submitting';

    var seshyDirData = this.buildSeshyDirData();
    console.log('seshyDirData', seshyDirData);

    var projectVersionRepositoryData = this.buildProjectVersionRepositoryData();
    console.log('projectVersionRepositoryData', projectVersionRepositoryData);

    var settingsData = this.settings.data;
    console.log('settingsData', settingsData);

    var watchedFolderData = await this.buildWatchedFolderData(settingsData);
    console.log('watchedFolderData', watchedFolderData);
    // Do stuff

    let debugReport = this.store.createRecord('debug-report', {
      seshyDirData,
      projectVersionRepositoryData,
      settingsData,
      watchedFolderData,
    });

    await debugReport.save();

    this.state = 'submitted';
  }

  buildSeshyDirData() {
    return {
      path: this.seshyDir.path,
      defaultPath: this.seshyDir.defaultPath,
      syncbotPath: this.seshyDir.syncbotPath,
      oldSeshyDataDir: this.seshyDir.oldSeshyDataDir,
      homeDir: this.seshyDir.homeDir(),
      projectDataFilePath: this.seshyDir.projectDataFilePath(),
      projectCacheDirPath: this.seshyDir.projectCacheDirPath(),
      settingsDataFilePath: this.seshyDir.settingsDataFilePath(),
    };
  }

  buildProjectVersionRepositoryData() {
    return {
      projectData: this.projectVersionRepository.projectData,
      recentlyDeletedProjectData:
        this.projectVersionRepository.recentlyDeletedProjectData,
    };
  }

  async buildWatchedFolderData(settingsData) {
    const glob = window.requireNode('glob-promise');
    let data = {};

    let allFolders = [settingsData.primaryFolder].concat(
      settingsData.additionalFolders
    );

    console.log('allFolders = ', allFolders);

    for (let i = 0, len = allFolders.length; i < len; i++) {
      let folder = allFolders[i];
      console.log('trying for folder ', folder);
      data[folder] = await glob(folder + '/**/*');
    }
    return data;
  }
}
