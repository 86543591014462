/* import __COLOCATED_TEMPLATE__ from './additional-folder-manager.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class AdditionalFolderManagerComponent extends Component {
  @service settings;
  @service projectUploadManager;
  @service projectVersionRepository;

  @tracked additionalFolders = A([...this.settings.get('additionalFolders')]);
  @tracked foldersToBeRemoved = A([]);
  @tracked hasChanged = false;

  @action
  async addFolder() {
    console.log('addFolder!');
    const { ipcRenderer } = require('electron');
    let newFolder = await ipcRenderer.invoke('choose-folder');
    console.log('newFolder = ', newFolder);

    if (newFolder && this.additionalFolders.indexOf(newFolder) < 0) {
      console.log('we should push the new folder');
      this.additionalFolders.push(newFolder);
      this.additionalFolders = this.additionalFolders;
      this.hasChanged = true;
    }
  }

  @action
  async removeFolder(folder) {
    const index = this.additionalFolders.indexOf(folder);
    let folderToRemove = this.additionalFolders.splice(index, 1);
    this.additionalFolders = this.additionalFolders;
    this.foldersToBeRemoved.pushObject(folderToRemove);
    this.foldersToBeRemoved = this.foldersToBeRemoved;
    this.hasChanged = true;
  }

  @action
  async save() {
    this.projectUploadManager.stop();
    this.projectVersionRepository.resetDataFile();
    this.settings.set('additionalFolders', this.additionalFolders);
    this.foldersToBeRemoved = A([]);
    this.hasChanged = false;
    this.projectUploadManager.start();
  }

  @action
  async cancel() {
    console.log('settings', this.settings.get('additionalFolders'));
    this.additionalFolders = A([...this.settings.get('additionalFolders')]);
    this.foldersToBeRemoved = A([]);
    this.hasChanged = false;
  }
}
