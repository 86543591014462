import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class AuthenticatedController extends Controller {
  @service platform;

  startLoading() {
    this.set('currentlyLoading', true);
  }

  stopLoading() {
    this.set('currentlyLoading', false);
  }
}
