import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class LoggerService extends Service {
  @tracked logs = A([]);

  info(msg) {
    this.logs.pushObject({ level: 'info', msg: msg });
  }

  error(msg) {
    this.logs.pushObject({ level: 'error', msg: msg });
  }
}
