// app/controllers/login.js
import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'seshy/config/environment';

export default class LoginController extends Controller {
  @tracked errorMessage;
  @service session;
  @service platform;
  @service versionChecker;

  @action
  async authenticate(e) {
    //console.log('calling authenticate');
    e.preventDefault();
    try {
      //console.log('about to authenticate');
      if (this.platform.isNative) {
        await this.session.authenticate('authenticator:seshy');
      } else {
        this.session
          .authenticate('authenticator:newtorii', 'seshy-oauth2-provider')
          .then(function (user) {
            console.log('we got a user ==', user);
            if (user == null) {
              console.log(
                'something went wrong, we should try all of this again'
              );
              //location.reload();
            }
          });
      }

      //console.log('back from authenticate');
    } catch (error) {
      //console.log('error --- ', error);
      this.errorMessage = 'We had trouble signing you in. ' + error;
    }
  }

  get isProduction() {
    return ENV.environment == 'production';
  }

  get environment() {
    return ENV.environment;
  }
}
