import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedTeamsShowProjectsListController extends Controller {
  @service router;
  queryParams = ['searchValue', 'refreshTimestamp', 'archived'];

  @tracked searchValue = '';
  @tracked archived = 'false';

  @action
  controllerRefresh() {
    console.log('controllerRefresh');
    this.send('routeRefresh');
  }

  @action
  setArchived(archived) {
    this.archived = archived;
    this.router.transitionTo({
      queryParams: { archived: this.archived, searchValue: this.searchValue },
    });
    this.send('propagateArchived', archived);
  }

  @action
  setSearch(searchValue) {
    this.searchValue = searchValue;
    this.router.transitionTo({
      queryParams: { archived: this.archived, searchValue: this.searchValue },
    });
    this.send('propagateSearch', searchValue);
  }
}
