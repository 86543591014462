/* import __COLOCATED_TEMPLATE__ from './bounce-player-focused.hbs'; */
import Component from '@glimmer/component';
import BouncePlayerWavesurferMarked from './bounce-player-wavesurfer-marked';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { later } from '@ember/runloop';

export default class BouncePlayerFocusedComponent extends BouncePlayerWavesurferMarked {
  @tracked hideScrollbar = true;
  @tracked comment = null;

  focusWindowPadding = 5;

  // We override this to include the comment id to make each focused player unique
  get playerId() {
    return `bounce-player-${this.bounce.id}-${this.args.comment?.id}-${this.randomId}`;
  }

  focusWavesurfer = task(async () => {
    // TODO: Ideally we don't want to load the sound here...
    //await this.loadSound();
    await this.updateWavesurferZoom();
    await this.initWavesurferPosition();
  });

  updateWavesurferZoom() {
    this.setZoom(this.minPxPerSec);
  }

  get minPxPerSec() {
    //console.log('old calculate minPxPerSec')
    let pixelWidth = this.componentElement.offsetWidth;
    let pixelsPerSecond = pixelWidth / this.focusWindow / 1;
    //console.log('old minPxPerSec = ', pixelsPerSecond)
    return pixelsPerSecond;
  }

  get focusCenterTime() {
    let time = this.focusStartTime + this.focusWindow / 2;
    return time;
  }

  get focusWindow() {
    return this.focusEndTime - this.focusStartTime;
  }

  get focusStartTime() {
    let startTime =
      (this.args.comment?.earliestMarkerStart || 0) - this.focusWindowPadding;
    if (startTime < 0) {
      startTime = 0;
    }
    return startTime;
  }

  get focusEndTime() {
    let duration = this.bounce.duration;
    // First get the start time of the latest marker
    let endTime =
      (this.args.comment?.latestMarkerStart || duration) +
      this.focusWindowPadding;
    // Then if it has an end, use that instead of the start time
    if (this.args.comment?.latestMarkerEnd) {
      endTime = this.args.comment?.latestMarkerEnd + this.focusWindowPadding;
    }
    if (endTime > duration) {
      endTime = duration;
    }
    return endTime;
  }

  async initWavesurferPosition() {
    // Put the playhead at the beginning of the window
    let timeInSeconds = this.focusStartTime;
    this.wavesurfer.options.autoScroll = false;
    this.wavesurfer.setScrollTime(timeInSeconds);
  }

  buildCommentMarkers() {
    if (!this.args.comment) {
      return;
    }
    for (const marker of this.args.comment.markers) {
      //console.log('building marker for ', marker);
      this.buildSingleCommentMarker(this.args.comment, marker);
    }
    return;
  }

  // We override this method so we can always start at the beginning of the window
  afterTogglePlaySound() {
    super.afterTogglePlaySound();
    //console.log('Focused: afterTogglePlaySound!!!')
    this.updateSoundPositionToStartOfFocusWindow();
  }

  async updateSoundPositionToStartOfFocusWindow() {
    this.updateSoundPosition(this.focusStartTime);
  }

  @action
  async commentMarkerClick(comment, marker) {
    await this.playSoundTask.perform();
    this.updateSoundPosition(marker.start);
  }
}
