import Service from '@ember/service';

export default class PlatformService extends Service {
  isNative = false;

  constructor(...args) {
    super(...args);

    try {
      requireNode('electron');
      this.set('isNative', true);
    } catch (e) {
      // fall through
    }
  }
}
