/* import __COLOCATED_TEMPLATE__ from './activity-list.hbs'; */
import Component from '@glimmer/component';
import { union, sort } from '@ember/object/computed';
import moment from 'moment';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

import ActivityDay from 'seshy/models/activity/day';
import ActivityStateChange from 'seshy/models/activity/state-change';

export default class ProjectsActivityListComponent extends Component {
  @service store;

  @tracked activityData = A([]);

  compactCalendarFormatHash = {
    sameDay: '[Today]',
    nextDay: '[Tomorrow]',
    nextWeek: 'dddd',
    lastDay: '[Yesterday]',
    lastWeek: '[Last] dddd MMM Do Y',
    sameElse: 'ddd MMM Do Y',
  };

  @action
  captureElement(elem) {
    //console.log('capturing element', elem);
    this.element = elem;
  }

  @action
  testOffset() {
    //console.log('testing offset');
    let element = this.element;
    let child = element.children[1];
    //console.log('element = ', element);
    //console.log('element.offsetTop = ', element.offsetTop);
    //console.log('element.scrollTop = ', element.scrollTop);

    //console.log('child = ', child);
    //console.log('child.offsetTop = ', child.offsetTop);
    //console.log('child.scrollTop = ', child.scrollTop);

    let parent = element.parentNode;
    //console.log('parent = ', parent);
    //console.log('parent.offsetTop = ', parent.offsetTop);
    //console.log('parent.scrollTop = ', parent.scrollTop);

    parent.scrollTop = child.offsetTop;
  }

  @union('args.comments', 'args.projectVersions', 'issueStateChanges') allItems;

  createdAtSorting = ['createdAtISO:desc'];
  @sort('allItems', 'createdAtSorting') sortedItems;

  get now() {
    return new Date();
  }

  get issueStateChanges() {
    let changes = [];
    for (const comment of this.args.comments.slice()) {
      if (comment.isIssue) {
        //console.log('we got an issue!!!!!!!!!!');
        for (const change of comment.stateChanges) {
          //console.log('got a change', change);
          let stateChange = ActivityStateChange.create({
            ...change,
            type: 'state-change',
            issue: comment,
            user: this.store.peekRecord('user', change.userId),
            projectId: comment.project.id,
            project: comment.project,
          });
          changes.push(stateChange);
        }
      }
    }
    //console.log('changes = ', changes)
    return changes;
  }

  buildActivityData = task(async () => {
    //console.log('calling buildActivityData');
    for (const item of this.sortedItems) {
      let dayString = moment(item.createdAtISO).format('YYYY-MM-DD');
      let friendlyDayString = moment(item.createdAtISO).calendar(
        this.compactCalendarFormatHash
      );
      //console.log('dayString = ', dayString)
      let dayData = this.activityData.findBy('id', dayString);

      if (!dayData) {
        dayData = ActivityDay.create({ day: dayString });
        this.activityData.pushObject(dayData);
      }
      dayData.friendlyDayString = friendlyDayString;
      dayData.addItem(item);
      //await timeout(50);
    }
    console.log('activity list data =', this.activityData);
  });
}
