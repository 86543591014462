import Service, { inject as service } from '@ember/service';

export default class StickyCalculatorService extends Service {
  @service platform;

  top() {
    let top = 0;

    if (this.platform.isNative) {
      top += document.getElementById('stickyMenuBar').offsetHeight;
      //console.log('after adding stickyMenuBar top = ', top);
      //top += document.getElementById('stickyHeaderBar').offsetHeight;
      //console.log('added stickyMenuBar and now top = ', top)
    }

    top += document.getElementById('stickyHeaderBar').offsetHeight;
    //console.log('after adding stickyHeaderBar top = ', top);
    //top += document.getElementById('stickyBreadCrumbs').offsetHeight;
    //console.log('after adding stickyBreadCrumbs top = ', top);
    //console.log('added stickyBreadCrumbs and now top = ', top)

    let playerElem = document.getElementById('stickyPlayerCommentable');
    if (playerElem) {
      top += playerElem.offsetHeight;
      //console.log('added stickyPlayerCommentable and now top = ', top)
    }

    return top;
  }
}
