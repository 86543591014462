/* import __COLOCATED_TEMPLATE__ from './project-team-changer.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import canUserManageProject from 'seshy/utils/can-user-manage-project';

export default class ProjectTeamChangerComponent extends Component {
  @service currentUser;

  @tracked isEditing = false;

  @tracked currentOwningTeam = null;

  @tracked errorMessage = null;

  get hasError() {
    return !!this.errorMessage;
  }

  get canManageProject() {
    return canUserManageProject(this.currentUser, this.args.project);
  }

  get teams() {
    var allTeams = this.currentUser.teams;
    var adminTeams = [];
    for (let i = 0, len = allTeams.length; i < len; i++) {
      let team = allTeams.at(i);
      console.log('checking team', team, team.currentUserRoles);
      if (team.currentUserRoles.indexOf('admin') >= 0) {
        console.log('we have the role');
        adminTeams.push(team);
      }
    }

    return adminTeams;
  }

  @action
  startEditing() {
    this.currentOwningTeam = this.args.project.owningTeam;
    console.log('startEditing');
    this.isEditing = true;
  }

  @action
  cancel() {
    this.errorMessage = null;
    this.isEditing = false;
  }

  @action
  async save() {
    console.log('we should save');
    let originalOwningTeam = await this.args.project.owningTeam;
    this.args.project.set('owningTeam', this.currentOwningTeam);
    try {
      await this.args.project.save();
      console.log('just saved the new project team');
      this.isEditing = false;
      if (this.args.refreshAction) {
        this.args.refreshAction();
      } else {
        console.log('we did not get a controller refresh!');
      }
    } catch (error) {
      console.log('caught an error', error);
      console.log('this.currentOwningTeam', this.currentOwningTeam);
      console.log('originalOwningTeam', originalOwningTeam.labelString);
      this.args.project.set('owningTeam', originalOwningTeam);
      let errorCause = error.errors[0];
      console.log('errorCause = ', errorCause);
      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else if (errorCause.status == '402') {
        this.setPaymentRequiredMessage();
      } else if (errorCause.status == '420') {
        this.setOverQuotaMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  @action
  changeOwningTeam(newTeam) {
    console.log('changeOwningTeam', newTeam);
    this.currentOwningTeam = newTeam;
  }

  setForbiddenMessage() {
    this.errorMessage =
      "You don't seem to be a Team Admin for both teams. You must be an admin on the team that currently owns this project and on the team that you want to transfer it to.";
  }

  setPaymentRequiredMessage() {
    this.errorMessage =
      "The team you selected is not currently covered by a subscription. Projects can't be transfered to teams on the free plan.";
  }

  setOverQuotaMessage() {
    this.errorMessage =
      'The team you selected does not have room in the storage pool for this project.';
  }

  setUnknownErrorMessage() {
    this.errorMessage =
      'We ran into an unexpected error while trying to change the owning team. Please try again.';
  }
}
