import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';

export default class AuthenticatedTeamsShowProjectsShowProjectVersionsShowBouncesShowRoute extends BaseRoute {
  @service store;

  async model(params) {
    console.log('params = ', params);
    let bounce = await this.store.findRecord('bounce', params.bounce_id, {
      include:
        'project-version,project-version.project,comments,comments.user,comments.comment,comments.comments,comments.comments.user',
    });
    let comments = await bounce.comments;
    console.log('bounce.comments = ', comments, comments.length);
    return bounce;
  }
}
