/* import __COLOCATED_TEMPLATE__ from './bounce-player-focused-small.hbs'; */
import Component from '@glimmer/component';
import BouncePlayerFocused from './bounce-player-focused';
import { tracked } from '@glimmer/tracking';

export default class BouncePlayerFocusedSmallComponent extends BouncePlayerFocused {
  @tracked wavesurferHeight = 26;

  @tracked waveColor = '#ffffff';
  @tracked progressColor = '#cbd5e1';
  @tracked showTimeline = false;
}
