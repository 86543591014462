import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedTeamsShowProjectsListIndexController extends Controller {
  @tracked searchValue = '';
  @tracked archived = 'false';

  get filteredProjects() {
    let archived = this.archived;
    let team = this.model.team;
    let projects = this.model.team.get('projects');
    let filteredProjects = projects;

    if (archived == 'true') {
      //filteredProjects = filteredProjects.filterBy('archived', true);
      filteredProjects = filteredProjects.filter((project) => project.archived);
    } else if (archived == 'false') {
      //filteredProjects = filteredProjects.filterBy('archived', false);
      filteredProjects = filteredProjects.filter(
        (project) => !project.archived
      );
    }

    if (this.searchValue != '') {
      var pattern = new RegExp(this.searchValue, 'i');
      filteredProjects = filteredProjects.filter(function (
        item,
        index,
        enumerable
      ) {
        var data = item.get('displayName');
        return pattern.test(data);
      });
    }
    return filteredProjects;
  }
}
