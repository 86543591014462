import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedTeamsShowProjectsShowProjectFilesIndexRoute extends BaseRoute {
  @service store;

  @action
  routeRefresh() {
    console.log('routeRefresh');
    this.refresh();
  }

  async model() {
    var project = this.modelFor('authenticated.projects.show');
    console.log('project = ', project);
    var projectFiles = await this.store.query('project-file', {
      filter: { projectId: project.id },
    });
    return { project, projectFiles };
  }
}
