import Service, { inject as service } from '@ember/service';
import ENV from 'seshy/config/environment';

export default class UserDataUpgradeService extends Service {
  @service seshyDir;

  glob = window.requireNode('glob-promise');
  fs = window.require('fs');
  path = window.require('path');

  async perform() {
    var oldSeshyDataDir = this.seshyDir.oldSeshyDataDir; // ~/Seshy/.seshy or ~/Seshy-development/.seshy
    var newSeshyDataDir = await this.seshyDir.getUserDataDir(); // ~/Library/Application Support/Seshy

    var oldSeshyProjectDataFile = oldSeshyDataDir + '/project_data.json';
    var newSeshyProjectDataFile =
      newSeshyDataDir +
      (ENV.environment == 'production'
        ? '/project_data.json'
        : '/project_data_' + ENV.environment + '.json');

    var oldSeshyProjectCacheDir = oldSeshyDataDir;
    var newSeshyProjectCacheDir =
      newSeshyDataDir +
      (ENV.environment == 'production'
        ? '/project_cache'
        : '/project_cache_' + ENV.environment);

    /*
    console.log({
      oldSeshyDataDir,
      newSeshyDataDir,
      oldSeshyProjectDataFile,
      newSeshyProjectDataFile,
    });
    */

    if (this.fs.existsSync(oldSeshyDataDir)) {
      //console.log('---------------- we have the old seshy data dir');
      await this.checkProjectDataFile(
        oldSeshyProjectDataFile,
        newSeshyProjectDataFile
      );

      await this.checkProjectCache(
        oldSeshyProjectCacheDir,
        newSeshyProjectCacheDir
      );
    }
  }

  async checkProjectDataFile(oldSeshyProjectDataFile, newSeshyProjectDataFile) {
    if (this.fs.existsSync(oldSeshyProjectDataFile)) {
      if (this.fs.existsSync(newSeshyProjectDataFile)) {
        //console.log('-- we already have a new data file, bailing out');
      } else {
        /*
        console.log(
          '-- we do not have a new data file yet, we can move the old one'
        );
        */
        this.fs.renameSync(oldSeshyProjectDataFile, newSeshyProjectDataFile);
      }
    }
  }

  async checkProjectCache(oldSeshyProjectCacheDir, newSeshyProjectCacheDir) {
    var localProjectFiles = await this.glob(
      oldSeshyProjectCacheDir + '/project-*/**/*'
    );
    for (const file of localProjectFiles) {
      if (this.fs.lstatSync(file).isDirectory()) {
        //console.log("it's a dir. NEXT!");
        continue;
      }
      //console.log('need to move local file ', file);
      var destination = file.replace(
        oldSeshyProjectCacheDir,
        newSeshyProjectCacheDir
      );
      //console.log('will move to ' + destination);
      var dir = this.path.dirname(destination);
      if (!this.fs.existsSync(dir)) {
        this.fs.mkdirSync(dir, { recursive: true, mode: 0o744 });
      }
      try {
        this.fs.renameSync(file, destination);
      } catch (err) {
        //console.error('we could not move file ', file);
      }
    }
  }
}
