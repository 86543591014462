/* import __COLOCATED_TEMPLATE__ from './project.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { sort, filter, uniqBy, union } from '@ember/object/computed';

import ActivityItem from 'seshy/models/activity/item';

export default class ActivityListDayProjectComponent extends Component {
  @tracked _topLevelItems = A([]);

  latestActivitySorting = ['latestActivityTimestamp:desc'];
  @sort('_topLevelItems', 'latestActivitySorting') topLevelItems;

  buildTopLevelItems = task(async () => {
    //console.log('##################################################')
    //console.log('## starting buildTopLevelItems')
    for (const item of this.args.items) {
      //console.log('handling item with timestamp ', item.createdAtISO)
      let topLevelItemForItem = this.getTopLevelItemForItem(item);
      if (!topLevelItemForItem) {
        //console.log('we could not find a topLevelItemForItem', item);
        continue;
      }
      let topLevelItemKey =
        topLevelItemForItem.constructor.modelName +
        '-' +
        topLevelItemForItem.id;
      //let topLevelItemData = itemMap[topLevelItemKey];
      let topLevelItemData = this._topLevelItems.findBy('id', topLevelItemKey);
      if (!topLevelItemData) {
        topLevelItemData = ActivityItem.create({
          id: topLevelItemKey,
          item: topLevelItemForItem,
        });
        //itemMap[topLevelItemKey] = topLevelItemData;
        //items.push(topLevelItemData);
        this._topLevelItems.pushObject(topLevelItemData);
      }
      if (item == topLevelItemForItem) {
        topLevelItemData.showFullItem = true;
      } else {
        topLevelItemData.addComment(item);
      }
      //console.log('handling item', item.constructor.modelName, item.id, item.type, topLevelItemForItem?.constructor?.modelName, topLevelItemForItem?.id);
    }
    //console.log('items = ', items);
  });

  getTopLevelItemForItem(item) {
    let topLevelItemForItem = item;
    //console.log('looking for ', item.constructor.modelName, item.id)
    if (item.type == 'state-change') {
      topLevelItemForItem = item.issue;
    } else if (item.constructor.modelName == 'comment') {
      if (item.type == 'issue') {
        topLevelItemForItem = item;
      } else if (item.bounce) {
        //console.log('yoooooooooooooo');
        //topLevelItemForItem = item.bounce;
        topLevelItemForItem = item;
      //} else if (item.type == 'comment' && !item.comment) {
        //console.log('this is the one!!!!!!!!!')
        //topLevelItemForItem = item.projectVersion;
        //topLevelItemForItem = item;
      } else if (item.comment) {
        let upperComment = item.comment;
        if(upperComment.bounce){
          topLevelItemForItem = item.comment;
        } else if (upperComment.type == 'issue') {
          topLevelItemForItem = upperComment;
        }else{
          topLevelItemForItem = upperComment.projectVersion;
        }
      } else {
        topLevelItemForItem = item.projectVersion;
      }
    }
    return topLevelItemForItem;
  }
}
