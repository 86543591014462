import EmberObject from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class StateChange extends EmberObject {
  get id() {
    return `state-change-${this.issueId}-${this.createdAtISO}`;
  }

  get modelId() {
    return this.id;
  }
}
