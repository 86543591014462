import LocalFile from 'seshy/models/local-file';

export default class LocalAvidFileModel extends LocalFile {
  static glob = window.requireNode('glob-promise');
  static crypto = window.require('crypto');

  static dawName = 'avid-pro-tools';

  // we don't want the backup dir
  static backupFragment = 'Session File Backups';

  static excludedFilesRegex = new RegExp(this.backupFragment);

  // TODO
  static projectDataRegex = /(.*\/([^\/]*))\/[^\/]*.ptx$/;

  static projectDataFileGlob = '/*.ptx';

  static async buildFileList(projectPath) {
    // We include directories here because Ableton (does Pro Tools care?) is picky about some of them
    // being there and will prevent a dowloaded session from being saved in the
    // same place after opening it.
    //
    // TODO: We're looking only for files in the projectDir and one subdirectory down. Is that good
    // enough or does Pro Tools use a deeper directory structure for anything? We're trying to limit
    // it because Pro Tools WILL let you save an entire project within a project and you can potentially
    // end up with a very deep directory that Seshy would see as a single project.
    var files = await this.glob('{*,*/*}', {
      nodir: false,
      cwd: projectPath,
      absolute: true,
    });

    var filteredFiles = [];
    for (const file of files) {
      if (!file.match(this.excludedFilesRegex)) {
        filteredFiles.push(file);
      }
    }
    return filteredFiles;
  }

  static async calculateProjectFingerprint(projectPath) {
    const hash = this.crypto.createHash('sha256');
    var projectDataFiles = await this.glob(
      projectPath + this.projectDataFileGlob
    );
    for (const file of projectDataFiles) {
      var localDataFile = new LocalFile(file);
      var fingerprint = await localDataFile.fingerprint();
      hash.update(fingerprint);
    }
    return new Promise((resolve, reject) => {
      resolve(hash.digest('hex'));
    });
  }

  projectName() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectName = match[2];
    return projectName;
  }

  projectPath() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectPath = match[1];
    return projectPath;
  }

  isProjectDataFiile() {
    return (
      this.path.match(this.constructor.projectDataRegex) &&
      !this.path.match(this.constructor.excludedFilesRegex)
    );
  }
}
