/* import __COLOCATED_TEMPLATE__ from './menu-bar.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ENV from 'seshy/config/environment';

export default class MenuBarComponent extends Component {
  @service router;
  @service currentUser;
  @service session;
  @service versionChecker;
  @service platform;

  @tracked
  userMenuOpen = false;

  @tracked
  mainMenuOpen = false;

  @tracked skipCloseMainMenu = false;

  menuItems = [
    { name: 'Dashboard', link: 'authenticated.index' },
    { name: 'Projects', link: 'authenticated.projects.index' },
    { name: 'Issues', link: 'authenticated.issues.index' },
    { name: 'Teams', link: 'authenticated.teams.list.index' },
    { name: 'About', link: 'authenticated.about' },
  ];

  @action
  toggleUserMenu(value) {
    //console.log('toggling user menu');
    this.userMenuOpen = !this.userMenuOpen;
  }

  @action
  closeUserMenuIfOpen() {
    console.log('calling closeUserMenuIfOpen');
    if (this.userMenuOpen) {
      if (this.skipCloseUserMenu) {
        this.skipCloseUserMenu = false;
      } else {
        this.userMenuOpen = false;
      }
    }
  }

  @action
  closeMenuIfOpen() {
    //console.log('calling closeMenuIfOpen')
    if (this.mainMenuOpen) {
      if (this.skipCloseMainMenu) {
        this.skipCloseMainMenu = false;
      } else {
        this.mainMenuOpen = false;
      }
    }
  }

  @action
  toggleMainMenu(value) {
    //console.log('toggling main menu');
    this.mainMenuOpen = !this.mainMenuOpen;
    if (this.mainMenuOpen) {
      this.skipCloseMainMenu = true;
    }
  }

  @action
  userMenuNav(destination) {
    this.mainMenuOpen = false;
    this.userMenuOpen = false;
    console.log('nav destination = ', destination);
    this.router.transitionTo(destination);
    return true;
  }

  @action
  openBugReporter() {
    this.toggleMainMenu();
    let url = ENV.api.webhost + '/account/teams/bug_tracker';
    if (this.platform.isNative) {
      let shell = require('electron').shell;
      shell.openExternal(url);
    } else {
      window.open(url);
    }
  }

  @action
  invalidateSession() {
    this.session.invalidate();
  }

  get appNeedsToUpdate() {
    if (!this.platform.isNative) {
      return false;
    }
    return !this.versionChecker.get('isUpToDate');
  }

  get isProduction() {
    return ENV.environment == 'production';
  }

  get environment() {
    return ENV.environment;
  }
}
