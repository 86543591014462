import Component from '@glimmer/component';
import BouncePlayerAbstract from './bounce-player-abstract';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import WaveSurfer from 'wavesurfer.js';
import { later } from '@ember/runloop';
import { task, timeout } from 'ember-concurrency';
import { A } from '@ember/array';

export default class BouncePlayerWavesurferComponent extends BouncePlayerAbstract {
  @tracked wavesurfer = null;
  @tracked skipClickHandling = false;
  @tracked hideScrollbar = true;
  @tracked autoCenter = true;
  @tracked plugins = A([]);
  @tracked peaksLength = null;
  @tracked wavesurferHeight = 40;
  @tracked waveColor = '#059669';
  @tracked progressColor = '#047857';
  @tracked cursorColor = '#34d399';
  @tracked zoom = 10;

  wireUpCurrentBounce() {
    super.wireUpCurrentBounce();
    //console.log('Wavesurfer: wireUpCurrentBounce', this.bounce.id);
    this.initWavesurfer.perform();
  }

  cleanUpCurrentBounce() {
    //console.log('Wavesurfer: cleanUpCurrentBounce', this.bounce.id);
    this.destroyWavesurfer();
    super.cleanUpCurrentBounce();
  }

  initWavesurfer = task(async () => {
    //console.log('initWavesurfer', this.soundId);
    //console.log('about to preBuildPlugins')
    await this.preBuildPlugins();
    //console.log('about to buildWavesurfer')
    await this.buildWavesurfer.perform();
    //console.log('about to buildPlugins')
    await this.buildPlugins();
    await this.focusWavesurfer.perform();
    await this.registerUiHandlers();
    return;
  });

  get minPxPerSec() {
    return null;
  }

  @action getCursorTime() {
    console.log('calling getCursorTime');
    return this.wavesurfer.getCurrentTime();
  }

  buildWavesurfer = task(async () => {
    //console.log('starting buildWavesurfer ---------------', this.bounce.id, this.wavesurfer);
    if (this.wavesurfer) {
      //console.log('we are returning ', this.wavesurfer);
      return;
    }
    this.wavesurfer = 'creating';

    const idString = `#${this.playerId}`;

    let peaks = this.bounce.peaks;
    let duration = this.bounce.duration;

    this.peaksLength = peaks.length;

    if (peaks.length == 0) {
      peaks = null;
      duration = null;
    }

    //console.log('about to make the wavesurfer', this.wavesurfer)

    this.wavesurfer = await WaveSurfer.create({
      container: idString,
      waveColor: this.waveColor,
      progressColor: this.progressColor,
      cursorColor: this.cursorColor,
      cursorWidth: 1,
      // We don't pass a url so that wavesurfer doesn't try to load the mp3 as soon as it's rendered
      //url: this.identifier,
      height: this.wavesurferHeight,
      mediaControls: false,
      interact: true,
      dragToSeek: true,
      normalize: false,
      peaks: peaks,
      duration: duration,
      autoScroll: true,
      hideScrollbar: this.hideScrollbar,
      minPxPerSec: this.minPxPerSec,
      autoCenter: this.autoCenter,
      style: this.wavesurferStyle,
      plugins: this.plugins,
    });
    //console.log('makde the wavesurfer', this.wavesurfer)
  });

  wavesuferStyle() {
    return {};
  }

  async preBuildPlugins() {}
  async buildPlugins() {}
  async registerUiHandlers() {
    this.wavesurfer.on('interaction', this.wavesurferInteraction);
    this.wavesurfer.on('click', this.wavesurferClick);
  }

  // Mainly for subclasses
  focusWavesurfer = task(async () => {});

  @action
  wavesurferMaskClick() {
    //console.log('wavesurfer mask click');
    this.togglePlaySoundTask.perform();
  }

  @action
  destroyWavesurfer() {
    //console.log('destroyWavesurfer!!!!!!!!')

    if (!this.bounce) {
      console.error(
        'somehow we are trying to destroy a wavesurfer that does not have a bounce...'
      );
      return;
    }

    if (this.wavesurfer) {
      //console.log('now we will actually destroy the wavesurfer')
      let ws = this.wavesurfer;
      ws.empty();
      ws.unAll();
      ws.destroy();
      this.wavesurfer = null;
      this.bounce.set('wavesurfer', null);
    }
  }

  @action
  async wavesurferClick() {
    //console.log('wavesurfer click');
    if (this.skipClickHandling) {
      //console.log('skipping click handling')
      this.skipClickHandling = false;
    } else {
      //console.log('not skipping click handling')
      this.sound.togglePause();
      this.currentlyPlaying.set('bounce', this.bounce);
    }
  }

  @action
  async wavesurferInteraction(newTime) {
    //console.log('Wavesurfer: wavesurfer interaction', newTime);
    this.updateSoundPosition(newTime);

    this.skipClickHandling = true;

    let currentBounce = this.currentlyPlaying.get('bounce');
    if (currentBounce && currentBounce.id != this.bounce.id) {
      this.currentlyPlaying.set('bounce', this.bounce);
    }
  }

  updateSoundPosition(timeInSeconds) {
    //console.log('setting time ', timeInSeconds);
    if (!timeInSeconds) {
      console.error(
        'We tried to updateSoundPosition with an empty value. Bailing out.',
        timeInSeconds
      );
      return;
    }
    let sound = this.currentlyPlaying.getSound(this.soundId);
    if (sound) {
      let newTimeInMs = timeInSeconds * 1000;
      sound.position = newTimeInMs;
    }
  }

  updateWavesurferPosition(timeInSeconds) {
    if (!timeInSeconds) {
      console.error(
        'We tried to updateWavesurferPosition with an empty value. Bailing out.',
        timeInSeconds
      );
      return;
    }
    this.wavesurfer.setTime(timeInSeconds);
  }

  // Now some methods that we override form BouncePlayerAbstract

  @action
  async audioPositionChanged({ sound }) {
    //console.log('audioPositionChanged - Wavesurfer', sound.position, this.bounce)
    super.audioPositionChanged(...arguments);

    let timeInSeconds = sound.position / 1000;
    //console.log('position changed', this.bounce.id, timeInSeconds);
    if (this.wavesurfer != null && this.wavesurfer != 'creating') {
      //console.log('we can update the weavesurfer', this.bounce.id, this.wavesurfer, this.bounce.id);
      this.updateWavesurferPosition(timeInSeconds);
      //this.wavesurfer.setTime(timeInSeconds);
    } else if (this.bounce) {
      console.warn('we do NOT have a wavesurfer to update....');
      //console.log('we need to build a wavesurfer', this.bounce.id, this.wavesurfer, this.bounce.id)
      //await this.buildWavesurfer.perform();
    }
  }

  setZoom(newZoom) {
    //console.log('setZoom', newZoom);
    this.zoom = newZoom;
    if (this.zoom < 1) {
      this.zoom = 1;
    }
    if (this.zoom > 50) {
      this.zoom = 50;
    }
    this.wavesurfer.zoom(this.zoom);
  }

  @action zoomIn() {
    this.setZoom(this.zoom + this.zoom * 0.2);
  }

  @action zoomOut() {
    this.setZoom(this.zoom - this.zoom * 0.2);
  }

  nudgePosition(seconds) {
    let currentTime = this.wavesurfer.getCurrentTime();
    let newTime = currentTime + seconds;

    this.updateSoundPosition(newTime);
    this.updateWavesurferPosition(newTime);
  }

  @action backBig() {
    this.nudgePosition(-5);
  }

  @action backSmol() {
    this.nudgePosition(-1);
  }

  @action forwardBig() {
    this.nudgePosition(5);
  }

  @action forwardSmol() {
    this.nudgePosition(1);
  }
}
