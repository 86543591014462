import EmptyFileError from '../errors/empty-file-error';

export default class LocalBrowserFile {
  // TODO: Is it valid to store this, or does it need to be computed at boot time?
  emptyFileFingerprint =
    'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855';

  constructor(file) {
    this.file = file;
  }

  readUploadedFileAsArrayBuffer(inputFile) {
    const temporaryFileReader = new FileReader();

    return new Promise((resolve, reject) => {
      temporaryFileReader.onerror = () => {
        temporaryFileReader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      temporaryFileReader.onload = () => {
        resolve(temporaryFileReader.result);
      };
      temporaryFileReader.readAsArrayBuffer(inputFile);
    });
  }

  async fingerprint() {
    let data = await this.readUploadedFileAsArrayBuffer(this.file.file);
    console.log('data = ', data);
    let digest = await crypto.subtle.digest('SHA-256', data);
    console.log('digest = ', digest);
    let resultBytes = [...new Uint8Array(digest)];
    console.log('result bytes = ', resultBytes);
    let fp = resultBytes.map((x) => x.toString(16).padStart(2, '0')).join('');
    console.log('fp = ', fp);
    return fp;
  }
}
