import Service, { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import FingerprintMismatchError from '../errors/fingerprint-mismatch-error';
import FilesLockedError from '../errors/files-locked-error';
import { task, timeout } from 'ember-concurrency';
import { getOwner } from '@ember/application';

export default class ProjectDownloadManagerService extends Service {
  @service store;
  @service currentUser;
  @service projectVersionRepository;
  @service projectUploadManager;
  @service seshyDir;

  @tracked operations = A([]);

  async downloadProjectVersion(project, projectVersion) {
    if (!projectVersion) {
      var projectVersions = await this.store.query('project-version', {
        filter: { projectId: project.id },
        sort: '-updatedAt',
      });
      projectVersion = projectVersions.at(0);
    }

    if (!projectVersion) {
      alert(
        "We couln't find that version of this project, so we were unable to download it."
      );
      return;
    }

    // This team is used to figure out where to put the file
    // if we don't already have it in the local repo.
    // First we'll use whatever the currentTeam is, if there is one.
    var targetTeam = this.currentUser.currentTeam;

    // If not, we'll use the first team connected to the project.
    if (!targetTeam) {
      var teams = await project.get('teams');
      targetTeam = teams.at(0);
    }

    var projectPath = this.projectVersionRepository.localPathFor(
      project,
      targetTeam
    );
    this.projectUploadManager.pausePath(projectPath);

    var fs = require('fs');

    // Now a quick sanity check to make sure that the main directory either exists, or can be created
    // in the spot where we think it should go.
    if (!fs.existsSync(projectPath)) {
      try {
        fs.mkdirSync(projectPath, { recursive: true, mode: 0o744 });
      } catch (err) {
        console.error('caught an error', err, err.code);
        if (err.code == 'EACCES') {
          alert(
            "Seshy can't seem to create a directory in the following location. Please check your disk permissions. " +
              projectPath
          );
          return;
        } else {
          throw err;
        }
      }
    } else {
      console.log('maybe it does exist', projectPath);
      if (!fs.lstatSync(projectPath).isDirectory()) {
        alert(
          'The following path seems to be a file, but we expect it to be a directory: ' +
            projectPath
        );
        return;
      }
    }

    // Here we lookup a model instance from the container instead of doing `new ProjectDownloadOperation`.
    // This allows us to use services inside the model, which allows us to use the settings service to
    // allow the user to set concurrency levels for file downloads.
    let applicationInstance = getOwner(this);
    var operation = applicationInstance.lookup('operation:project-download');
    // Now we call a customInit method to set stuff that we used to pass to the constructor
    operation.customInit(
      project,
      projectVersion,
      this.store,
      projectPath,
      this.seshyDir
    );
    this.operations.pushObject(operation);
    try {
      await operation.download();
    } catch (err) {
      if (err instanceof FingerprintMismatchError) {
        console.error('we had trouble downloading a file:');
        console.error(err);
        // We set this null so that when we push data in to the repo we end up clearing the local version
        projectVersion = null;
      }else if (err instanceof FilesLockedError) {
        alert('The following file seems to be in use. Please close the project before downloading. ' + err.filePath);

        this.operations.removeObject(operation);

        return;
      } else if (err.code == 'ENOSPC') {
        console.error('we ran out of space on the device');
        alert('Your hard drive seems to be full. Please free up some space and try downloading again.')

        this.operations.removeObject(operation);

        // TODO: Eventually remove this throw. Keeping it in just to be able to see in Sentry that this branch is being triggered.
        throw err;
        return;
      } else {
        console.error('we caught an unexpected error', err);
        alert('Seshy ran into an unexpected error while downloading. -- ' + err.message);
        this.operations.removeObject(operation);
        throw err;
      }
    }
    this.operations.removeObject(operation);

    this.projectVersionRepository.pushProjectData(
      project,
      projectVersion,
      projectPath
    );
    // Timeout is here to let things settle before resuming watching for uploads
    console.log('about to timeout before we unpause');
    await timeout(2000);
    this.projectUploadManager.unpausePath(projectPath);
  }

  operationForProjectIdAndChecksum(projectId, checksum) {
    for (const operation of this.operations) {
      if (
        operation.project &&
        operation.project.id == projectId &&
        operation.projectVersion &&
        operation.projectVersion.projectDataChecksum == checksum
      ) {
        return operation;
      }
    }
  }
}
