import BaseRoute from 'seshy/routes/base';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { hash } from 'rsvp';

export default class AuthenticatedTeamsShowProjectsShowIndexRoute extends BaseRoute {
  @service store;

  async model(params) {
    console.log('params = ', params);
    let project = await this.modelFor('authenticated.projects.show');
    let projectId = project.id;
    project = this.store.findRecord('project', projectId, {
      include: 'user,updator,owning-team,teams',
    });

    let comments = this.store.query('comment', {
      filter: { projectId: projectId, activeWithinDays: 31 },
      page: { size: 25 },
      sort: '-createdAt',
      include: 'comment,comments,project,project-version,bounce,user',
    });

    let projectVersions = this.store.query('project-version', {
      filter: { projectId: projectId },
      page: { size: 25 },
      sort: '-createdAt',
      include: 'bounces,project,user',
    });

    return hash({ project, comments, projectVersions });
  }

  @action
  routeRefresh() {
    console.log('routeRefresh');
    this.refresh();
  }

  afterModel(model, transition) {
    super.afterModel(model, transition);
    let project = model.project;
    this.subscribeForRefresh(
      'ProjectVersionsChannel',
      { project_id: project.id },
      this.setRefreshAvailable
    );
    this.subscribeForRefresh(
      'BouncesChannel',
      { project_id: project.id },
      this.setRefreshAvailable
    );
    this.subscribeForRefresh(
      'CommentsChannel',
      { project_id: project.id },
      this.setRefreshAvailable
    );
  }
}
