/* import __COLOCATED_TEMPLATE__ from './global-player.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import WaveSurfer from 'wavesurfer.js';
import { task, timeout } from 'ember-concurrency';
import { registerDestructor } from '@ember/destroyable';

export default class GlobalPlayerComponent extends Component {
  @service currentlyPlaying;
  @service betaFeatures;
  @service session;

  @tracked wavesurfer = null;
  @tracked randomNumber = null;
  @tracked sound = null;

  @tracked peaksLength = null;

  constructor() {
    super(...arguments);
    this.randomNumber = Math.floor(Math.random() * 1000000);
    this.boundAudioPositionChanged = this.audioPositionChanged.bind(this);
  }

  get soundId() {
    return `bounce_${this.bounce.id}`;
  }

  get bounce() {
    return this.currentlyPlaying.get('bounce');
  }

  get playerId() {
    return `global-player-${this.randomNumber}`;
  }

  get authString() {
    return `Authorization=Bearer ${this.session.data.authenticated.access_token}&for_download=true`;
  }

  get hasBounce() {
    return !!this.bounce;
  }

  initWavesurfer = task(async (element) => {
    //console.log('global player calling init', this.wavesurfer);
    await this.buildWavesurferTask.perform();
    registerDestructor(this, () => {
      //console.log('calling the registerDestructor method')
      this.destroyWavesurfer();
    });
    return;
  });

  buildWavesurferTask = task(async () => {
    //buildWavesurfer(){
    if (this.wavesurfer) {
      //console.log('we are returning ', this.wavesurfer);
      return;
    }

    //console.log('we are creating ', this.wavesurfer);
    // Creating a wavesurfer takes a little while, and so we have
    this.wavesurfer = 'creating';
    const idString = `#${this.playerId}`;
    //console.log('idString = ', idString)
    let peaks = null;
    let duration = null;
    if (this.bounce) {
      //console.log('peaks = ', this.bounce.peaks);

      let peaks = this.bounce.peaks;
      let duration = this.bounce.duration;

      this.peaksLength = peaks.length;

      if (peaks.length == 0) {
        peaks = null;
        duration = null;
      }
    } else {
      peaks = [0, 0, 0];
      duration = 1;
    }

    //console.log('duration = ', duration);
    this.wavesurfer = WaveSurfer.create({
      container: idString,
      waveColor: '#059669',
      progressColor: '#047857',
      cursorColor: '#34d399',
      //url: this.bounce.identifier,
      //"barWidth": 5,
      //"barGap": 1,
      //"barRadius": 30,
      height: 40,
      mediaControls: false,
      interact: true,
      dragToSeek: true,
      normalize: false,
      peaks: peaks,
      duration: duration,
    });
    this.wavesurfer.on('interaction', this.wavesurferInteraction);
    this.wavesurfer.on('click', this.wavesurferClick);
    //console.log('built a wavesurfer', this.wavesurfer);

    this.connectWavesurfer();
  });

  connectWavesurfer() {
    if (!this.bounce) {
      return;
    }
    let peaks = this.bounce.peaks;
    let duration = this.bounce.duration;
    let sound = this.currentlyPlaying.getSound(this.soundId);
    //console.log('the sound = ', sound);
    if (sound) {
      let identifier = this.currentlyPlaying.getIdentifier(this.soundId);
      this.wavesurfer.load(identifier, peaks, duration);
      this.boundAudioPositionChanged({ sound: sound });
      sound.on('audio-position-changed', this.boundAudioPositionChanged);

      this.sound = sound;
    } else {
      //console.log('we do not have a sound---------------------------------------------------');
    }
  }

  @action
  audioPositionChanged({ sound }) {
    //console.log('got an update in the global player', sound);
    let timeInSeconds = sound.position / 1000;
    this.wavesurfer.setTime(timeInSeconds);
  }

  @action
  wavesurferInteraction(newTime) {
    if (!this.bounce) {
      return;
    }
    //console.log('wavesurfer interaction');
    let newTimeInMs = newTime * 1000;
    this.sound.position = newTimeInMs;
    this.skipClickHandling = true;
    if (!this.sound.isPlaying) {
      this.sound.play();
    }
  }

  @action
  wavesurferClick() {
    if (!this.bounce) {
      return;
    }
    //console.log('wavesurfer click');
    if (this.skipClickHandling) {
      this.skipClickHandling = false;
    } else {
      this.sound.togglePause();
    }
  }
}
