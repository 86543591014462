/* import __COLOCATED_TEMPLATE__ from './active-operations-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ActiveOperationsListComponent extends Component {
  @service projectUploadManager;
  @service platform;

  get operations() {
    if (!this.platform.isNative) {
      return [];
    }
    return this.projectUploadManager.operations;
  }
}
