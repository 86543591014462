export default function canCurrentUserManageProject(currentUser, project) {
  var projectTeamId = project.get('teamId');
  var user = currentUser.get('user');
  //console.log('user', user);
  var teams = currentUser.get('teams');
  if (!teams) {
    return false;
  }
  //console.log('teams = ', teams);
  for (let i = 0, len = teams.length; i < len; i++) {
    var team = teams.at(i);
    if (team.get('id') == projectTeamId) {
      var roles = team.get('currentUserRoles'); // || [];
      //console.log('roles = ', roles);
      return roles.indexOf('admin') >= 0;
    }
  }
  return false;
}
