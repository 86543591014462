/* import __COLOCATED_TEMPLATE__ from './state-change.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class CommentsListStateChangeComponent extends Component {
  @service store;

  colors = {
    open: 'border-indigo-300 bg-indigo-50 dark:border-indigo-950 dark:bg-indigo-900',
    complete:
      'border-emerald-300 bg-emerald-50 dark:border-emerald-950 dark:bg-emerald-900',
    closed: 'border-red-300 bg-red-50 dark:border-red-950 dark:bg-red-900',
  };
  get borderColor() {
    if (typeof this.args.borderColor !== 'undefined') {
      return this.args.borderColor;
    }
    return this.colors[this.args.change.newState];
  }

  get user() {
    return this.store.peekRecord('user', this.args.change.userId);
  }
}
