/* import __COLOCATED_TEMPLATE__ from './comment-bubble.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class CommentBubbleComponent extends Component {
  @tracked showingDescription = false;

  @action
  showDescription() {
    if (this.args.description) {
      this.showingDescription = true;
    }
  }

  @action
  hideDescription() {
    this.showingDescription = false;
  }
}
