import { helper } from '@ember/component/helper';

export default helper(function formatTime(timeInSeconds /*, named*/) {
  let theDateString = new Date(timeInSeconds * 1000).toISOString(); // '1970-01-01T00:01:33.000Z'
  if (timeInSeconds < 60 * 10) {
    return theDateString.substring(15, 19);
  } else if (timeInSeconds < 60 * 60) {
    return theDateString.substring(14, 19);
  } else {
    return theDateSttring.substring(11, 19);
  }
});
