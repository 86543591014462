import RSVP from 'rsvp';
import FingerprintMismatchError from '../errors/fingerprint-mismatch-error';
import FileUploadError from '../errors/file-upload-error';
//import * as fs from 'node:fs/promises';

export default class FileUploadOperationModel {
  progress = 0;

  constructor(filePath, projectFileVersion, checksum, store) {
    this.filePath = filePath;
    this.projectFileVersion = projectFileVersion;
    this.checksum = checksum;
    this.store = store;

    // This is handy for testing how we handle random upload failures
    /*
    var rand = Math.random();
    console.log('rand = ', rand);
    if(rand > 0.5){
      this.checksum = "a-fake-checksum";
    }
    */
  }

  async upload(progressCallback) {
    console.log('FileUploadOperation.upload', this.filePath);
    let uploadPresign = this.store.createRecord('upload-presign', {
      projectFileVersionId: this.projectFileVersion.id,
      mimetype: 'application/octet-stream',
      sha256Checksum: this.checksum,
      // This next line is handy to have around if you want to test what happens if a file upload goes bad.
      // sha256Checksum: 'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855'
    });
    await uploadPresign.save();
    var url = uploadPresign.get('url');
    var headers = uploadPresign.get('headers');
    console.log('url = ', url);
    console.log('headers = ', headers);
    // We unload the presign from the local store so that it doesn't sit around
    // in memory after we're done with it.
    uploadPresign.unloadRecord();

    var fs = window.require('fs');
    // TODO: There are probably better ways than reading the entire file into memory first.
    var fileData = fs.readFileSync(this.filePath);

    //var response = await fetch(url, {
    //method: 'PUT',
    //body: fileData,
    //headers: headers,
    //});
    try {

      var startTime = Date.now();
      var status = await this.makeRequest(
        'PUT',
        url,
        fileData,
        headers,
        progressCallback
      );

      var elapsedMillis = Date.now() - startTime;
      console.log('it took this long to uplaod: ', elapsedMillis, this.filePath);

      return status;
    } catch (err) {
      if (err.status == 400) {
        console.log(
          'caught a 400 on the upload to s3 - this should trigger a retry',
          this.filePath
        );
        throw new FingerprintMismatchError(this.filePath);
      } else if (err instanceof FileUploadError) {
        console.log(
          'caught FileUploadError on upload to s3 - this should trigger a retry'
        );
        throw err;
      } else {
        console.log(
          'caught some other error on upload to s3 - this should NOT trigger a retry'
        );
        console.log(err);
        throw err;
      }
    }

    //const xhr = new XMLHttpRequest();
    //xhr.open('PUT', url);

    //xhr.onreadystatechange = () => {
    //if (xhr.readyState === 4) {
    //if (xhr.status === 200) {
    //console.log('uploaded a file', this.filePath);
    //} else {
    //console.error('Could not upload file.', this.filePath, xhr);
    //}
    //}
    //};
    //xhr.send(fileData);
  }

  handleEvent(e) {
    //console.log(e);
    //console.log(`handleEvent: ${e.type}: ${e.loaded} bytes transferred\n`);
  }

  makeRequest(method, url, data, headers, progressCallback) {
    let fuo = this;
    return new Promise(
      function (resolve, reject) {
        let xhr = new XMLHttpRequest();
        xhr.open(method, url);
        // TODO: Set headers

        var headerKeys = Object.keys(headers);
        for (const header of headerKeys) {
          var headerValue = headers[header];
          xhr.setRequestHeader(header, headerValue);
        }
        xhr.addEventListener('loadstart', this.handleEvent);
        //xhr.addEventListener('load', this.handleEvent);
        xhr.addEventListener('loadend', this.handleEvent);
        xhr.addEventListener('progress', this.handleEvent);
        //xhr.addEventListener('error', this.handleEvent);
        xhr.addEventListener('abort', this.handleEvent);
        xhr.onprogress = function (e) {
          //console.log('got some progress');
          //console.log(`onprogress: ${e.type}: ${e.loaded} bytes transferred\n`);
        };
        xhr.upload.addEventListener(
          'progress',
          function (event) {
            //console.log('upload.addEventListener progress');
            //console.log(event, event.lengthComputable, event.total);
            //console.log(
            //`onprogress: ${event.type}: ${event.loaded} bytes transferred\n`
            //);
            if (event.lengthComputable) {
              this.progress = event.loaded / event.total;
              console.log('progress = ', this.progress);
            } else {
              this.progress = 0;
            }
            progressCallback(this.progress);
          }.bind(this),
          false
        );
        xhr.onload = function (e) {
          //console.log(`FileUploadOperation onload: ${e.type}: ${e.loaded} bytes transferred\n`);
          //console.log(`FileUploadOperation onload: ${this.status}: ${xhr.statusText}\n`);
          //console.log(`FileUploadOperation onload:`, this, xhr);
          //console.log(e);
          if (this.status >= 200 && this.status < 300) {
            resolve(xhr.status);
          } else {
            console.log(
              `FileUploadOperation onload: e.type = ${e.type}: ${e.loaded} bytes transferred\n`
            );
            console.log(
              `FileUploadOperation onload: ${this.status}: ${xhr.statusText}\n`
            );
            console.log(e);
            reject(
              new FileUploadError(fuo.filePath, this.status, xhr.statusText)
            );
          }
        };
        xhr.onerror = function (e) {
          console.log(`onerror: ${e.type}: ${e.loaded} bytes transferred\n`);
          console.log(e);
          reject(
            new FileUploadError(fuo.filePath, this.status, xhr.statusText)
          );
        };
        xhr.send(data);
      }.bind(this)
    );
  }
}
