/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';

export default class ActivityListItemComponent extends Component {
  get modelName() {
    return this.args.item.constructor.modelName;
  }

  get isProjectVersion() {
    return this.modelName == 'project-version';
  }

  get isComment() {
    return this.modelName == 'comment';
  }

  get isTopLevelComment() {
    return this.modelName == 'comment' && !this.args.item.comment;
  }

  get isBounce() {
    return this.modelName == 'bounce';
  }

  get isIssue() {
    return this.isComment && this.args.item.type == 'issue';
  }
}
