/* import __COLOCATED_TEMPLATE__ from './side-list.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class SideListComponent extends Component {
  @service stickyCalculator;

  get stickyTop() {
    return this.stickyCalculator.top();
  }
}
