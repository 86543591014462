/* import __COLOCATED_TEMPLATE__ from './status-badge.hbs'; */
import Component from '@glimmer/component';

export default class IssuesStatusBadgeComponent extends Component {
  colors = {
    open: 'bg-indigo-700',
    complete: 'bg-emerald-700',
    closed: 'bg-red-700',
  };
  get bgColor() {
    if (typeof this.args.bgColor !== 'undefined') {
      return this.args.bgColor;
    }
    return this.colors[this.args.status];
  }
}
