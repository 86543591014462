import Model, { attr, hasMany, belongsTo } from '@ember-data/model';

export default class UserModel extends Model {
  @attr('string') email;
  @attr('string') firstName;
  @attr('string') lastName;

  // Read only attribute
  @attr primaryTeamId; // Eventually we may want this to be settable from this app
  @attr betaFeatures;

  @hasMany('team', { inverse: 'users', async: false }) teams;
  @belongsTo('team', { inverse: null, async: false }) primaryTeam;

  get fullName() {
    return this.firstName + ' ' + this.lastName;
  }

  get name() {
    return this.fullName;
  }
}
