import Service, { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SettingsService extends Service {
  @service seshyDir;

  @tracked data = {
    additionalFolders: [],
    primaryFolder: this.seshyDir.defaultPath,
  };

  writeDataFile() {
    var fs = window.require('fs');
    var path = this.seshyDir.settingsDataFilePath();
    fs.writeFileSync(path, JSON.stringify(this.data, null, 2));
  }

  readDataFile() {
    var fs = window.require('fs');
    var path = this.seshyDir.settingsDataFilePath();
    //console.log('trying to read settings from path ', path);
    if (fs.existsSync(path)) {
      //console.log('settings: reading data file');
      this.data = require(path);
    }
    this.runSettingsMigrations();
    //console.log('settings:data = ', this.data);
  }

  get(key) {
    return this.data[key];
  }

  set(key, val) {
    this.data[key] = val;
    this.writeDataFile();
  }

  runSettingsMigrations() {
    // As we add new settings we'll want to merge the new default values into
    // whatever settings we restore from disk.
    // TODO: Can we just directly merge two hashes? Does it need to be more complex?
    this.addUploadAndDownloadLimits();
    //console.log('after running migrations this.data = ', this.data);
  }

  // A helper function to make it easy to write migrations
  checkExistenceOfDefault(key, defaultValue) {
    let existingValue = this.get(key);
    if (existingValue === undefined) {
      console.log(
        'had an undefined value for ',
        key,
        existingValue,
        defaultValue,
        this.data
      );
      this.set(key, defaultValue);
    }
  }

  addUploadAndDownloadLimits() {
    this.checkExistenceOfDefault('projectDownloadMax', 1);
    this.checkExistenceOfDefault('fileDownloadMax', 3);
    this.checkExistenceOfDefault('projectUploadMax', 1);
    this.checkExistenceOfDefault('fileUploadMax', 3);
  }

  // Some convenience methods

  pathsToWatch() {
    return [this.data.primaryFolder, ...this.data.additionalFolders];
  }
}
