import EmberObject from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { map, max } from '@ember/object/computed';

export default class Project extends EmberObject {
  @tracked project = null;
  @tracked items = A([]);
  //items = [];

  get id() {
    return this.project.get('modelId');
  }

  @map('items', (item) => Date.parse(item.createdAtISO)) activityTimestamps;
  @max('activityTimestamps') latestActivityTimestamp;

  addItem(newItem) {
    //console.log('adding newItem', newItem.modelId);
    let oldItem = this.items.findBy('modelId', newItem.modelId);
    if (oldItem) {
      // TODO: How do we update old items?
      //console.log('  -- we already have this item Activity/Project')
    } else {
      //console.log('  ++ we are adding new item in Activity/Project', newItem.modelId);
      this.items.unshiftObject(newItem);
    }
  }
}
