// app/models/base.js

import Model from '@ember-data/model';
import RSVP from 'rsvp';

// Wrap async model methods in RSVP.resolve(...) to workaround a bug in Ember Data 4.4 causing errors when
// they're called in the context of ember-concurrency tasks: https://github.com/emberjs/data/issues/8312
//
// (It's fixed in Ember Data  4.8, so it can be removed once we upgrade to it.)
class FixedModel extends Model {
  save() {
    return RSVP.resolve(super.save(...arguments));
  }

  reload() {
    return RSVP.resolve(super.reload(...arguments));
  }

  destroyRecord() {
    return RSVP.resolve(super.destroyRecord(...arguments));
  }
}

export default FixedModel;
