import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedTeamsListIndexRoute extends BaseRoute {
  @service store;
  @service currentUser;

  async model(params) {
    return this.store.query('team', {
      include: 'users',
    });
  }

  @action
  routeRefresh() {
    //console.log('routeRefresh');
    this.refresh();
  }
}
