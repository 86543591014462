/* import __COLOCATED_TEMPLATE__ from './display.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked, cached } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { A } from '@ember/array';
import { inject as service } from '@ember/service';

import WaveSurfer from 'wavesurfer.js';

import { Root } from 'ember-composability-tools';

export default class BouncePlayerWavesurferDisplayComponent extends Root {
  @service currentlyPlaying;
  @service session;

  @tracked wavesurfer = null;
  @tracked bounceId = null;

  _height = 40;
  _waveColor = '#059669';
  _progressColor = '#047857';
  _cursorColor = '#34d399';
  _cursorWidth = 1;
  _zoom = 10;
  _wavesurferStyle = null;
  _scrollTime = 0;
  _autoScroll = true;
  _hideScrollbar = true;

  _element = null;

  @cached
  get sound() {
    //console.log('get sound', this.args.bounce?.soundId)
    return this.currentlyPlaying.getSound(this.args.bounce?.soundId);
  }

  async didInsertParent(element) {
    // The topmost parent hook call.
    // Here we have a `element` available and
    // we are certain that none of the children's
    // `didInsertParent` hooks were called

    //console.log('BP:W:Display : didInsertParent', this.args.bounce.id)
    this._element = element;

    if (!this.args.bounce) {
      console.error(
        'we tried to render a wavesurfer display without a bounce, skipping it'
      );
      return;
    }
    await this._buildWavesurferTask.perform();
    // TODO: Can we set this as part of creating the wavesufer instead of setting if after?
    this.setWavesurferScrollTime();
    this.setWavesurferTime();
  }

  @action
  willDestroyParent(element) {
    // the reverse is applied here.
    // We are certain that this call will take place
    // when all of the children's `willDestroyParent`
    // were called.

    //console.log('BP:W:Display : willDestroyParent', this.args.id, element)
    this._destroyWavesurfer.perform();
  }

  @action
  setWavesurferScrollTime() {
    //console.log('BP:W:Display setWavesurferScrollTime', this.scrollTime);
    if (this.wavesurfer) {
      this.wavesurfer.setScrollTime(this.scrollTime);
    }
  }

  @action
  setMinPxPerSec() {
    //console.log('BP:W:Display setMinPxPerSec', this.scrollTime, this.args.bounce.id);
    if (this.wavesurfer) {
      try {
        this.wavesurfer.zoom(this.minPxPerSec);
      } catch (err) {
        if (err.message == 'No audio loaded') {
          console.error(
            'Somehow we tried to update the zoom on a wavesurfer with no audio.',
            { bounceId: this.args.bounce.id }
          );
        } else {
          console.error(err);
        }
      }
    }
  }

  @action
  setWavesurferTime() {
    //console.log('BP:W:Display setWavesurferTime', this.currentTime);

    if (this.wavesurfer) {
      this.wavesurfer.setTime(this.currentTime);
    }
  }

  @action
  async bounceDidUpdate() {
    //console.log('BP:W:Display bounceDidUpdate', this.args.bounce.id);
    if (this.args.bounce?.id == this.bounceId) {
      console.log('we got an update even though the bounce id is the same...');
      return;
    }
    if (this.wavesurfer) {
      //console.log('going to destroy wavesurfer');
      await this._destroyWavesurfer.perform();
    }
    if (this.args.bounce) {
      //console.log('going to rebuild wavesurfer');
      await this._buildWavesurferTask.perform();
      this.setWavesurferTime();
    }
  }

  @action
  async wavesurferClick() {
    //console.log('BP:W:Display wavesurfer click');
    /*
    if(this.skipClickHandling){
      //console.log('skipping click handling')
      this.skipClickHandling = false;
    }else{
      //console.log('not skipping click handling')
      this.sound.togglePause();
      this.currentlyPlaying.set('bounce', this.bounce);
    }
    */
  }

  @action
  async wavesurferInteraction(newTime) {
    //console.log('BP:W:Display wavesurfer interaction', newTime);
    if (this.args.onInteraction) {
      this.args.onInteraction(newTime);
    } else {
      //console.log('BP:W:Display we do not have an arg for onInteraction')
    }
    /*
    this.updateSoundPosition(newTime);

    this.skipClickHandling = true;

    let currentBounce = this.currentlyPlaying.get('bounce');
    if(currentBounce && currentBounce.id != this.bounce.id){
      this.currentlyPlaying.set('bounce', this.bounce);
    }
    */
  }

  get identifier() {
    return `${this.args.bounce.downloadUrl}?Authorization=Bearer ${this.session.data.authenticated.access_token}`;
  }

  _buildWavesurferTask = task(async () => {
    if (this.wavesurfer) {
      console.error(
        'we tried to build a wavesurfer when we already had one',
        this.wavesurfer
      );
      return;
    }
    this.bounceId = this.args.bounce.id;

    /*
    let peaks = this.args.bounce.peaks;
    let duration = this.args.bounce.duration;

    this.peaksLength = peaks.length;
    if(peaks.length == 0){
      peaks = null;
      duration = null;
    }
    */

    //console.log('about to make the wavesurfer', this.wavesurfer)

    this.wavesurfer = await WaveSurfer.create({
      container: this._element,
      waveColor: this.waveColor,
      progressColor: this.progressColor,
      cursorColor: this.cursorColor,
      cursorWidth: this.cursorWidth,
      // We don't pass a url so that wavesurfer doesn't try to load the mp3 as soon as it's rendered
      //url: this.identifier,
      height: this.height,
      mediaControls: false,
      interact: true,
      dragToSeek: true,
      normalize: false,
      peaks: this.peaks,
      duration: this.duration,
      autoScroll: this.autoScroll,
      hideScrollbar: this.hideScrollbar,
      minPxPerSec: this.minPxPerSec,
      autoCenter: this.autoCenter,
      style: this.wavesurferStyle,
    });

    this.wavesurfer.on('interaction', this.wavesurferInteraction);
    this.wavesurfer.on('click', this.wavesurferClick);
  });

  _destroyWavesurfer = task(async () => {
    if (!this.wavesurfer) {
      console.error(
        'somehow we are trying to destroy a wavesurfer that does not exist'
      );
      return;
    }

    if (this.wavesurfer) {
      //console.log('now we will actually destroy the wavesurfer')
      let ws = this.wavesurfer;
      ws.empty();
      ws.unAll();
      ws.destroy();
      this.wavesurfer = null;
      //this.bounce.set('wavesurfer', null);
    }
  });

  get currentTime() {
    if (this.sound) {
      return this.sound.position / 1000;
    }
    return this.args.currentTime || 0;
  }

  get height() {
    return this.args.height || this._height;
  }

  get waveColor() {
    return this.args.waveColor || this._waveColor;
  }

  get progressColor() {
    return this.args.progressColor || this._progressColor;
  }

  get cursorColor() {
    return this.args.cursorColor || this._cursorColor;
  }

  get zoom() {
    return this.args.zoom || this._zoom;
  }

  get cursorWidth() {
    return this.args.cursorWidth || this._cursorWidth;
  }

  get wavesurferStyle() {
    return this.args.wavesurferStyle || this._wavesurferStyle;
  }

  get minPxPerSec() {
    return this.args.minPxPerSec || this._minPxPerSec;
  }

  get scrollTime() {
    return this.args.scrollTime || this._scrollTime;
  }

  get autoScroll() {
    if (typeof this.args.autoScroll !== 'undefined') {
      return this.args.autoScroll;
    }
    return this._autoScroll;
  }

  get hideScrollbar() {
    if (typeof this.args.hideScrollbar !== 'undefined') {
      return this.args.hideScrollbar;
    }
    return this._hideScrollbar;
  }

  get peaks() {
    return this.args.bounce.peaks;
  }

  get duration() {
    return this.args.bounce.duration;
  }
}
