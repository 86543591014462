/* import __COLOCATED_TEMPLATE__ from './test.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BouncePlayerTestComponent extends Component {
  @tracked time = 10;
  @tracked start = 2;
  @tracked end = 42;
  @tracked bounceToRender = null;

  get firstMarker() {
    return this.args.comment.markers[0];
  }

  @action
  toggleBounce() {
    if (this.bounceToRender) {
      this.bounceToRender = null;
    } else {
      this.bounceToRender = this.args.bounce;
    }
  }
}
