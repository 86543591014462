/* import __COLOCATED_TEMPLATE__ from './files-locked-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class FilesLockedBannerComponent extends Component {
  @service projectUploadManager;

  @action
  retryUpload() {
    var projectData = this.args.project.get('retryProjectData');
    console.log('projectData for retry = ', projectData);
    this.projectUploadManager.projectUpdated(projectData);
    this.args.project.set('filesLockedError', false);
  }
}
