// This file was mostly lifted from the GoogleOauth2BearerV2 from the torii project.
// https://github.com/adopted-ember-addons/torii/blob/main/addon/providers/google-oauth2-bearer-v2.js
//
// It handles the authorization_code flow where we get an authorization code first which
// we then exchange for an access token.

import Base from 'torii/providers/oauth2-code';
import { configurable } from 'torii/configuration';
import ENV from 'seshy/config/environment';
import { run } from '@ember/runloop';

export default Base.extend({
  init() {
    this._super(...arguments);
    //this.responseParams = ['access_token'];
  },

  name: 'seshy-oauth2-provider',
  baseUrl: ENV.api.webhost + '/oauth/authorize',

  tokenValidationUrl: ENV.api.apihost + '/oauth/token',

  scope: configurable('scope', 'read write delete'),

  apiKey: ENV.api.clientId,

  redirectUri: ENV.APP.myUrl + '/torii/redirect.html',

  responseType: 'code',

  responseParams: ['code', 'state'],

  /**
   * @method open
   * @return {Promise<object>} If the authorization attempt is a success,
   * the promise will resolve an object containing the following keys:
   *   - authorizationToken: The `token` from the 3rd-party provider
   *   - provider: The name of the provider (i.e., google-oauth2)
   *   - redirectUri: The redirect uri (some server-side exchange flows require this)
   * If there was an error or the user either canceled the authorization or
   * closed the popup window, the promise rejects.
   */
  open(options) {
    var name = this.name,
      url = this.buildUrl(),
      redirectUri = this.redirectUri,
      responseParams = this.responseParams,
      tokenValidationUrl = this.tokenValidationUrl,
      clientId = this.apiKey;

    return this.popup
      .open(url, responseParams, options)
      .then(function (authData) {
        console.log('authData = ', authData);
        var missingResponseParams = [];

        responseParams.forEach(function (param) {
          if (authData[param] === undefined) {
            missingResponseParams.push(param);
          }
        });

        if (missingResponseParams.length) {
          console.error('missing response params', missingResponseParams);
          throw new Error(
            'The response from the provider is missing ' +
              'these required response params: ' +
              missingResponseParams.join(', ')
          );
        }

        console.log('authData2 = ', authData);
        /* at this point 'authData' should look like:
        {
          code: '<some long acces token string>',
          state: '<time in s, was '3600' in jan 2017>'
        }
        */

        // Token validation. We exchange the authorization code for an access token
        return new Promise(function (resolve, reject) {
          // Token validation request
          let xhr = new XMLHttpRequest();
          xhr.overrideMimeType('application/json');
          xhr.onload = function () {
            var jsonResponse = JSON.parse(xhr.responseText);
            console.log('jsonResponse = ', jsonResponse);
            /* the response is a JSON that looks like:
            {
              "access_token": "43ndMYXZdKk-JioCm_mJJ_o8ViEMTYWQOhbi9bHLHfA",
              "token_type": "Bearer",
              "expires_in": 7200,
              "refresh_token": "YwTEaQUxpHcNnlWNHSLHE8t29Q9Oey9cPnBu1RG5wJQ",
              "scope": "read write delete",
              "created_at": 1695829942
            }
            */

            // authentication succeeded. Add name and redirectUri to the
            // authentication data and resolve
            run(() => {
              var returnVal = Object.assign(jsonResponse, {
                provider: name,
                redirectUri: redirectUri,
              });
              console.log('returnVal = ', returnVal);
              resolve(returnVal);
            });
          };
          xhr.onerror = function () {
            // authentication failed because the validation request failed
            run(() =>
              reject(
                new Error(
                  `Token validation request failed with status '${xhr.statusText}' (server '${tokenValidationUrl}' '${xhr.responseText}').`
                )
              )
            );
          };
          xhr.open(
            'POST',
            `${tokenValidationUrl}?code=${encodeURIComponent(
              authData['code']
            )}&grant_type=authorization_code&redirect_uri=${redirectUri}&client_id=${clientId}`
          );
          xhr.send();
        });
      });
  },

  /*
   * TODO: I think that here we should check if the token is expired and if so attempt to refresh it.
   */
  fetch(data) {
    console.log('calling fetch in the torii provider and data = ', data);
    return data;
  },
});
