/* import __COLOCATED_TEMPLATE__ from './oprhaned-project-list-item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

export default class OprhanedProjectListItemComponent extends Component {
  @service projectVersionRepository;
  @service projectUploadManager;

  @action
  async openProject() {
    const shell = require('electron').shell;
    shell.openPath(this.args.pData.localPath);
  }

  @action
  async createProject() {
    this.projectVersionRepository.removeProjectDataByLocalPath(
      this.args.pData.localPath,
      { skipSave: true }
    );
    this.args.deleteCallback();
    // This is a little hacky, but it gets the job done
    this.projectUploadManager.stop();
    this.projectUploadManager.start();
    console.log('create project');
  }

  @action
  async deleteLocalCopy() {
    console.log('delete local copy');
    const fs = require('fs');
    const shell = require('electron').shell;
    try {
      await shell.trashItem(this.args.pData.localPath);
    } catch (err) {
      console.log('error = ', err);
    }

    if (!fs.existsSync(this.args.pData.localPath)) {
      this.projectVersionRepository.removeProjectDataByLocalPath(
        this.args.pData.localPath,
        { skipSave: true }
      );
    }
    // We delay checking again because it takes a few seconds for the
    // upload manager to notice the change and remove it from the repo.
    later(() => {
      if (this.args.deleteCallback) {
        this.args.deleteCallback();
      }
    }, 3000);
  }
}
