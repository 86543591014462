/* import __COLOCATED_TEMPLATE__ from './issue-header.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class IssueHeaderComponent extends Component {
  @tracked showNewBounce = false;
  @tracked isEditing = false;

  @action
  toggleShowNewBounce() {
    this.showNewBounce = !this.showNewBounce;
  }

  @action
  startEditing(e) {
    e.preventDefault();
    console.log('startEditing');
    this.isEditing = true;
    return false;
  }

  @action
  cancelEditing() {
    this.args.issue.rollbackAttributes();
    this.isEditing = false;
  }

  @action
  async saveIssue() {
    await this.args.issue.save();
    this.isEditing = false;
  }

  @action
  async startDeleting(e) {
    e.preventDefault();
    console.log('startDeleting', this.args.commentDeleteAction);
    if (
      confirm(
        'Are you sure you want to delete this issue and all the comments that are on it?'
      )
    ) {
      let comment = this.args.issue;
      if (this.args.commentDeleteAction) {
        await this.args.commentDeleteAction(comment);
      }
      comment.deleteRecord();
      await comment.save();
    }
  }

  get showBouncePlayer() {
    console.log(
      'showBouncePlayer',
      this.args.issue.bounce,
      this.args.issue.markers.length
    );
    return this.args.issue.bounce; // && this.args.issue.markers.length;
  }

  get latestBounce() {
    return this.args.issue.project.get('currentBounce');
  }

  get newBounceAvailable() {
    let issueBounce = this.args.issue.bounce;
    if (!issueBounce) {
      return false;
    }
    let latestBounce = this.args.issue.get('project.currentBounce');
    if (!latestBounce) {
      return false;
    }
    return issueBounce.id != latestBounce.get('id');
  }

  @action updateIssueMarker(issue, oldMarker, newMarker) {
    console.log(
      'calling updateIssueMarker',
      JSON.stringify(oldMarker),
      JSON.stringify(newMarker)
    );
  }
}
