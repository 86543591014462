import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';

export default class AuthenticatedTeamsShowIssuesIndexRoute extends BaseRoute {
  @service store;
  async model() {
    let team = this.modelFor('authenticated.teams.show');

    let issues = await this.store.query('comment', {
      filter: { team_id: team.id, top_level: true },
      include: 'user,project,project-version',
    });

    return { team, issues };
  }
}
