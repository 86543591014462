/* import __COLOCATED_TEMPLATE__ from './item.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class CommentsListItemComponent extends Component {
  @tracked isEditing = false;

  @action
  commentClick(comment) {}

  @action
  startEditing(e) {
    e.preventDefault();
    console.log('startEditing');
    this.isEditing = true;
    return false;
  }

  @action
  cancelEditing() {
    this.args.comment.rollbackAttributes();
    this.isEditing = false;
  }

  @action
  async saveComment() {
    //this.args.comment.set('markers', this.args.comment.get('trackedMarkers'));
    await this.args.comment.save();
    this.args.comment.set('trackedMarkers', this.args.comment.get('markers'));
    this.isEditing = false;
  }

  @action
  async startDeleting(e) {
    e.preventDefault();
    console.log('startDeleting', this.args.commentDeleteAction);
    if (confirm('Are you sure you want to delete this comment?')) {
      let comment = this.args.comment;
      if (this.args.commentDeleteAction) {
        await this.args.commentDeleteAction(comment);
      }
      comment.deleteRecord();
      await comment.save();
    }
  }

  get showMarkerDiv() {
    return (
      !!this.args.bounceTimeClick && !!this.args.comment.sortedMarkers.length
    );
  }
}
