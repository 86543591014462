import EmberObject from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { sort, filter, uniqBy, union } from '@ember/object/computed';

export default class Day extends EmberObject {
  @tracked day = null;
  @tracked _items = A([]);

  createdAtSorting = ['createdAtISO:asc'];
  @sort('_items', 'createdAtSorting') items;

  get id() {
    return this.day;
  }

  addItem(newItem) {
    let modelId = newItem.get('modelId');
    //console.log('adding newItem', modelId);
    let oldItem = this._items.findBy('modelId', modelId);
    if (oldItem) {
      // TODO: How do we update old items?
      //console.log('  -- we already have this item')
    } else {
      //console.log('  ++ we are adding new item', modelId);
      this._items.unshiftObject(newItem);
    }
  }
}
