import Model, { attr, belongsTo } from '@ember-data/model';

export default class ProjectFileVersionModel extends Model {
  @belongsTo('project-file', { inverse: null, async: false }) projectFile;
  @belongsTo('project-version', {
    inverse: 'projectFileVersions',
    async: false,
  })
  projectVersion;
  @attr('string') checksum;
  @attr('string') path;
  @attr('boolean') uploadComplete;

  // Read only attributes
  @attr uploadCompletedAt;
  @attr sizeInBytes;
}
