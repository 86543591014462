/* import __COLOCATED_TEMPLATE__ from './project-not-created-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { later } from '@ember/runloop';

export default class ProjectNotCreatedBannerComponent extends Component {
  @service projectUploadManager;

  @action
  retryUpload() {
    this.args.project.set('projectNotCreatedError', false);
    later( () => {
      var projectData = this.args.project.get('retryProjectData');
      this.args.project.unloadRecord();
      console.log('projectData for retry = ', projectData);
      this.projectUploadManager.projectUpdated(projectData);
    }, 100 );
  }
}
