import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class AuthenticatedAboutController extends Controller {
  @service versionChecker;
  @service platform;
  @service currentUser;

  @action
  openOctoLabs(e) {
    console.log('openOctoLabs');
    e.preventDefault();
    this.openUrl('http://www.octolabs.com/');
  }

  @action
  openLayerCake(e) {
    console.log('openLayerCake');
    e.preventDefault();
    this.openUrl('http://www.layercake.band/');
  }

  openUrl(url) {
    if (this.platform.isNative) {
      let shell = require('electron').shell;
      shell.openExternal(url);
    } else {
      window.open(url);
    }
  }

  @action
  throwError(){
    this.throwErrorTwo();
  }

  throwErrorTwo(){
    this.throwErrorThree();
  }

  throwErrorThree(){
    throw new Error('Yet Another Test Error');
  }
}
