import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import config from '../config/environment';

export default class ApplicationRoute extends Route {
  @service session;
  @service currentUser;
  @service versionChecker;

  async beforeModel() {
    //console.log('app version = ', config.APP.version);
    await this.session.setup();
    this.versionChecker.start();
    return this._loadCurrentUser();
  }

  async _loadCurrentUser() {
    try {
      await this.currentUser.load();
    } catch (err) {
      await this.session.invalidate();
    }
  }
}
