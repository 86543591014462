/* import __COLOCATED_TEMPLATE__ from './project-list-tabs.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ProjectListTabsComponent extends Component {
  menuItems = [
    {
      name: 'Active Projects',
      archived: 'false',
    },
    {
      name: 'Archived Projects',
      archived: 'true',
    },
    {
      name: 'All Projects',
      archived: 'null',
    },
  ];

  @tracked currentItem = this.menuItems[0];

  @action
  setArchivedState(newState) {
    console.log('setting archived', newState);
    return true;
  }

  @action
  changeCurrentItem(newItem) {
    this.currentItem = newItem;
    this.args.setArchived(newItem.archived);
  }
}
