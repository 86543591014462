import LocalFile from 'seshy/models/local-file';

export default class LocalLogicFileModel extends LocalFile {
  static glob = window.requireNode('glob-promise');
  static crypto = window.require('crypto');

  static dawName = 'logic-pro-x';

  // We listen for things that aren't in a Logic tmp dir
  static tmpDirFragment = '^.*.logicx/tmp/';
  // And that aren't in a 'Project File Backups' dir
  static projectFileBackupsFragment =
    '^.*.logicx/Alternatives/\\d*/Project File Backups';
  // And that aren't in a 'Undo Data.nosync' dir
  static undoDataFragment = '^.*.logicx/Alternatives/\\d*/Undo Data.nosync';
  // And that aren't in an Autosave directory
  static autosaveFragment = '^.*.logicx/Alternatives/\\d*/Autosave';
  // And that aren't in a 'Freeze Files.nosync' directory
  static freezeFilesFragment = '^.*.logicx/Media/Freeze Files.nosync';

  static excludedFilesRegex = new RegExp(
    this.tmpDirFragment +
      '|' +
      this.projectFileBackupsFragment +
      '|' +
      this.undoDataFragment +
      '|' +
      this.autosaveFragment +
      '|' +
      this.freezeFilesFragment +
      '|' +
      this.seshyJsonFragment
  );

  static projectDataRegex =
    /(.*\/([^\/]*\.logicx))\/Alternatives\/\d*\/ProjectData$/;

  static projectDataFileGlob = '/Alternatives/*/ProjectData';

  static async buildFileList(projectPath) {
    var files = await this.glob(projectPath + '/**', { nodir: true });
    var filteredFiles = [];
    for (const file of files) {
      if (!file.match(this.excludedFilesRegex)) {
        filteredFiles.push(file);
      }
    }
    return filteredFiles;
  }

  static async calculateProjectFingerprint(projectPath) {
    const hash = this.crypto.createHash('sha256');
    var projectDataFiles = await this.glob(
      projectPath + this.projectDataFileGlob
    );
    for (const file of projectDataFiles) {
      var localDataFile = new LocalFile(file);
      var fingerprint = await localDataFile.fingerprint();
      hash.update(fingerprint);
    }
    return new Promise((resolve, reject) => {
      resolve(hash.digest('hex'));
    });
  }

  projectName() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectName = match[2];
    return projectName;
  }

  projectPath() {
    var match = this.path.match(this.constructor.projectDataRegex);
    var projectPath = match[1];
    return projectPath;
  }

  isProjectDataFiile() {
    return (
      this.path.match(this.constructor.projectDataRegex) &&
      !this.path.match(this.constructor.excludedFilesRegex)
    );
  }
}
