/* import __COLOCATED_TEMPLATE__ from './primary-folder-manager.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class PrimaryFolderManagerComponent extends Component {
  @service settings;
  @service projectUploadManager;
  @service projectVersionRepository;

  @tracked primaryFolder = this.settings.get('primaryFolder');
  @tracked folderHasChanged = false;

  @action
  async changeFolder() {
    console.log('changeFolder!');
    const { ipcRenderer } = require('electron');
    let newFolder = await ipcRenderer.invoke('choose-folder');
    console.log('newFolder = ', newFolder);
    if (newFolder && newFolder != this.primaryFolder) {
      this.primaryFolder = newFolder;
      this.folderHasChanged = true;
    }
  }

  @action
  async save() {
    this.projectUploadManager.stop();
    this.projectVersionRepository.resetDataFile();
    this.settings.set('primaryFolder', this.primaryFolder);
    this.folderHasChanged = false;
    this.projectUploadManager.start();
  }

  @action
  async cancel() {
    this.primaryFolder = this.settings.get('primaryFolder');
    this.folderHasChanged = false;
  }
}
