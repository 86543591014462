/* import __COLOCATED_TEMPLATE__ from './version-update-alert.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import ENV from 'seshy/config/environment';
import { action } from '@ember/object';
import { timeout } from 'ember-concurrency';

export default class VersionUpdateAlertComponent extends Component {
  @service versionChecker;
  @service platform;

  updateUrl = ENV.api.webhost + '/app/download';

  @action
  async openUpdatePage(e) {
    console.log('openUpdatePage');
    e.preventDefault();

    var { shell, ipcRenderer } = require('electron');

    console.log('the shell = ', shell);
    shell.openExternal(this.updateUrl);

    // If we try to `await shell.openExternal` it just hangs forever and Seshy never quits.
    // If we just proceed immediately we sometimes quit before `openExternal` command gets
    // dispatched to the OS. So... we have to set a short timeout before quitting. Ugh.
    await timeout(1000);

    console.log('going to send an event to the renderer', ipcRenderer);
    //const { ipcRenderer } = require('electron');
    ipcRenderer.send('close-me');
    console.log('tried to send the event')
  }

  get shouldDisplayAlert() {
    return this.versionChecker.isUpToDate;
  }
}
