import BaseRoute from 'seshy/routes/base';
import { action } from '@ember/object';

export default class AuthenticatedTeamsShowProjectsListRoute extends BaseRoute {
  model(params) {
    let team = this.modelFor('authenticated.teams.show');
    //console.log('List team = ', team);
    return { team, archived: params.archived, searchValue: params.searchValue };
  }

  @action
  propagateArchived(archived) {
    //console.log('propagateArchived', archived);
    this.controllerFor('authenticated.teams.show.projects.list.index').set(
      'archived',
      archived
    );
  }

  @action
  propagateSearch(searchValue) {
    //console.log('propagateSearch', searchValue);
    this.controllerFor('authenticated.teams.show.projects.list.index').set(
      'searchValue',
      searchValue
    );
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    let listIndexController = this.controllerFor(
      'authenticated.teams.show.projects.list.index'
    );
    listIndexController.set('archived', model.archived);
    listIndexController.set('searchValue', model.searchValue);
  }
}
