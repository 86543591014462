import Service, { inject as service } from '@ember/service';
import Timer from 'ember-stopwatch/utils/timer';
import LocalFile from 'seshy/models/local-file';
import LocalLogicFile from 'seshy/models/local-logic-file';
import LocalAbletonFile from 'seshy/models/local-ableton-file';
import LocalAvidFile from 'seshy/models/local-avid-file';
import LocalBitwigFile from 'seshy/models/local-bitwig-file';
import LocalReaperFile from 'seshy/models/local-reaper-file';
import LocalFLStudioFile from 'seshy/models/local-fl-studio-file';
import { task, timeout } from 'ember-concurrency';
import slash from 'slash';

import { A } from '@ember/array';

export default class ProjectDirectoryWatcherService extends Service {
  @service projectVersionRepository;

  initialScanComplete = false;

  pausedPaths = A([]);

  timers = {};
  watchers = [];
  timeout = 0;

  fileClasses = [
    LocalLogicFile,
    LocalAbletonFile,
    LocalAvidFile,
    LocalBitwigFile,
    //LocalReaperFile,
    //LocalFLStudioFile
  ];

  callback = null;

  log(...args) {
    if (false) {
      console.log(args);
    }
  }

  pausePath(projectPath) {
    this.log('about to pause', projectPath);
    this.pausedPaths.pushObject(projectPath);
    this.log('paused = ', this.pausedPaths);
  }

  unpausePath(projectPath) {
    this.log('about to unpause', projectPath);
    this.pausedPaths.removeObject(projectPath);
    this.log('paused = ', this.pausedPaths);
  }

  isPaused(localFile) {
    //this.log('checking paused status', localFile, this.pausedPaths);
    return this.pausedPaths.includes(localFile.projectPath());
  }

  async stop() {
    for (let i = 0, len = this.watchers.length; i < len; i++) {
      let watcher = this.watchers[i];
      watcher.stop();
    }
    this.watchers = [];
    var timerKeys = Object.keys(this.timers);
    this.log('------ stop', timerKeys);
    timerKeys.forEach(
      function (key) {
        this.timers[key].stop();
        delete this.timers[key];
      }.bind(this)
    );
  }

  async createWatchers(paths) {
    for (let i = 0, len = paths.length; i < len; i++) {
      let path = paths[i];

      if (this.fs.existsSync(path)) {
        this.log('creating watcher for path ', path);
        this.nsfw(path, this.handleNsfwEvents.bind(this), {
          debounceMS: 250,
          errorCallback: this.handleNSFWErrors.bind(this),
        }).then(
          function (watcher) {
            //this.log('created watcher for path ', path);
            this.watchers.push(watcher);
            watcher.start();
          }.bind(this)
        );
      } else {
        console.warn('we cannot create a watcher for missing path: ', path);
      }
    }
  }

  async handleNSFWErrors(errors) {
    console.error('we caught an error from the NSFW watcher');
    console.error(errors);
    // TODO: Should we throw here? Or something to get errors to Sentry?
  }

  async handleNsfwEvents(events) {
    //this.log('handling events', events);
    for (let i = 0, len = events.length; i < len; i++) {
      var event = events[i];
      var filePath = slash(event.directory) + '/' + event.file;
      //this.log('filePath = ', filePath);
      //this.log('this = ', this);
      if (
        event.action == this.nsfw.actions.CREATED ||
        event.action == this.nsfw.actions.MODIFIED
      ) {
        //this.log('created or modified');
        this.handleFile(filePath);
      } else if (event.action == this.nsfw.actions.DELETED) {
        //this.log('deleted')
        this.fileRemoved(filePath);
      } else if (event.action == this.nsfw.actions.RENAMED) {
        //this.log('renamed')
        let oldPath = slash(event.directory) + '/' + event.oldFile;
        this.fileRemoved(oldPath);
        let newPath = slash(event.newDirectory) + '/' + event.newFile;
        this.handleFile(newPath);
      }
    }
  }

  async doInitialScan(paths) {
    var globs = [];
    for (const path of paths) {
      for (const fileClass of this.fileClasses) {
        //this.log('building glob for path ', path, fileClass);
        // We slash the path here because we need to make sure that on Windows we are dealing with
        // / and not \ because globs exclusively use /. The glob from the fileClass is already using /.
        let glob = slash(path) + '/**' + fileClass.projectDataFileGlob;
        //this.log('glob = ', glob);
        globs.push(glob);
      }
    }
    this.log('initial scan globs = ', globs);

    var startTime = new Date();
    var localProjectFiles = await this.glob(globs);
    var endTime = new Date();
    this.log('the project files = ', localProjectFiles);
    this.log('time to get files = ', endTime - startTime);
    for (let i = 0, len = localProjectFiles.length; i < len; i++) {
      var localFile = localProjectFiles[i];
      this.handleFile(localFile);
    }
    this.initialScanComplete = true;
  }

  async start(paths, timeout, callback) {
    this.os = require('os');
    this.fs = require('fs');
    this.glob = window.requireNode('fast-glob');
    this.nsfw = window.requireNode('nsfw');

    try {
      this.log('Starting ProjectDirectoryWatcher for paths', paths);

      this.callback = callback;
      this.timeout = timeout;

      this.createWatchers(paths);

      this.doInitialScan(paths);
    } catch (err) {
      console.error(
        'We caught an error while trying to start the watcher',
        err
      );
    }
  }

  fileAdded(path) {
    //this.log('File', path, 'has been added', this.store);
    //var newProject = this.store.createRecord('project', { name: path });
    //this.log('created a record!');
    this.log('fileAdded', path);
    this.handleFile(path);
  }

  fileChanged(path) {
    this.log('fileChanged', path);
    //this.log('File', path, 'has been changed');
    this.handleFile(path);
  }

  fileRemoved(path) {
    //this.log('file removed', path);
    var localFile = this.createLocalProjectDataFile(path);
    if (!localFile) {
      //this.log('file is not a project data file', path);
      return;
    }
    if (this.isPaused(localFile)) {
      this.log(
        'this path is paused, skipping fileRemoved handling',
        localFile.projectPath()
      );
      return;
    }
    //if (localFile.isProjectDataFiile()) {
    this.log('a projectDataFile was removed!', path);
    var projectPath = localFile.projectPath();
    this.log('projectPath = ', projectPath);
    //this.projectVersionRepository.removeProjectDataByLocalPath(projectPath);
    this.projectVersionRepository.markMissingLocalProjectByLocalPath(
      projectPath
    );
    //}
  }

  // TODO : this is the most minimal change I could make to add some concurrency control to this.
  // There are probably better ways to handle this.
  // TODO : What's the right concurrency limit here?
  handleFileTask = task({ maxConcurrency: 10, enqueue: true }, async (path) => {
    this.log('handleFile', path);

    // A timeout that's helpful for debugging in development
    //await timeout(300);

    // 0. Does this file even exist anymore? If not, bail out.
    if (!this.fs.existsSync(path)) {
      this.log('handleFile path missing');
      return;
    }
    // 1. Check if any of our timers are pointed at a parent directory of this path
    var timer = this.findExistingProjectTimer(path);
    // - If yes: reset the timer
    if (timer) {
      timer.restart();
      return;
    }
    // 2. Check if the file represents a project data file
    var projectDataFile = this.createLocalProjectDataFile(path);

    // - If yes: start a timer with the key being the path to this project
    if (projectDataFile) {
      // Make sure that the project repo doesn't think the local project is missing
      this.projectVersionRepository.unmarkMissingLocalProjectByLocalPath(
        projectDataFile.projectPath()
      );
      // If this path is paused, just bail out
      if (this.isPaused(projectDataFile)) {
        this.log(
          'this path is paused, skipping file handling',
          projectDataFile.projectPath()
        );
        return;
      }
      this.createTimer(projectDataFile);
    }
    // - If no: do nothing. We don't want to trigger uploads until the main
    // project data file for a project has been touched.
  });

  async handleFile(path) {
    //this.log('starting handleFile', path)
    //this.log('getWatched = ', this.watcher.getWatched())
    await this.handleFileTask.perform(path);
    //this.log('ending handleFile'[>, path<])
  }

  findExistingProjectTimer(path) {
    //this.log('findExistingProjectTimer', path);
    var timerKeys = Object.keys(this.timers);
    for (let i = 0, len = timerKeys.length; i < len; i++) {
      let timerProjectPath = timerKeys[i];
      //this.log('checking againt timerProjectPath', timerProjectPath);
      if (path.startsWith(timerProjectPath)) {
        //this.log('we found a timer!!!');
        let timer = this.timers[timerProjectPath];
        return timer;
      }
    }
    return null;
  }

  createLocalProjectDataFile(path) {
    //this.log('trying to create localfile');
    let localFile = null;
    for (const fileClass of this.fileClasses) {
      localFile = new fileClass(path);
      //this.log('made a localFile', localFile);
      if (localFile.isProjectDataFiile()) {
        //this.log("it's a project data file", fileClass, localFile);
        return localFile;
      } else {
        //this.log("NOT project data file", fileClass, localFile);
      }
    }
    localFile = null;
    return null;
  }

  createTimer(projectDataFile) {
    let timer = new Timer(this.timeout);

    var projectPath = projectDataFile.projectPath();
    var projectName = projectDataFile.projectName();

    timer.on(
      'expired',
      this,
      this.expirationHandler.bind(this, {
        projectPath,
        projectName,
        daw: projectDataFile.constructor.dawName,
        projetLocalFileClass: projectDataFile.constructor,
        isFirstScan: !this.initialScanComplete,
      })
    );
    timer.start();
    this.timers[projectPath] = timer;
  }

  async expirationHandler(projectData, timerData) {
    //this.log('expirationHandler', projectData, timerData);
    projectData.files = await projectData.projetLocalFileClass.buildFileList(
      projectData.projectPath
    );
    //this.log('this = ', this);
    this.callback(projectData);
    var timer = this.timers[projectData.projectPath];
    delete this.timers[projectData.projectPath];
    //this.log('expirationHandler', this.timers);
  }
}
