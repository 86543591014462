/* import __COLOCATED_TEMPLATE__ from './marker.hbs'; */
import Component from '@glimmer/component';

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import { later } from '@ember/runloop';

import { Node } from 'ember-composability-tools';

export default class BouncePlayerWavesurferMarkerComponent extends Node {
  @tracked _color = 'rgba(0,0,255,0.5)';

  async _buildMarker() {
    if (this.displayMarker) {
      console.error('we already built this marker...');
      return;
    }
    //console.log('BP:W:Marker : starting buildMarker and this.args = ', this.args, this.args.parent, this.args.parent.regions);
    let regions = this.args.parent.regions;

    this.displayMarker = await regions.addRegion({
      content: this.content,
      color: this.color,
      start: this.start,
      end: this.end,
      drag: false,
      resize: false,
      id: this.args.id,
    });
    //console.log('BP:W:Marker this.displayMarker = ', this.displayMarker)
  }

  _destroyMarker() {
    if (this.displayMarker) {
      //console.log('BP:W:Marker : removing display marker');
      try {
        this.displayMarker.remove();
      } catch (err) {
        console.error(err);
      }
      this.displayMarker = null;
    } else {
      //console.log('somehow the displayMarker is missing...')
    }
  }

  @action
  captureMarkerContent(element) {
    //console.log('BP:W:Marker : captureMarkerContent', this.args.start, this.args.end, element)
    this.markerContent = element;
  }

  @action
  async didInsertParent(element) {
    //console.log('BP:W:Marker : didInsertParent', this.args.start, this.args.end, element)
    this._element = element;
    if (this._buildMarker) {
      later(() => {
        // TODO: Can we get away with doing this immediately instead of `later`?
        // It seems that we don't capture the content that was yielded to the marker until
        // after this didInsertParent method fires. It's kinda weird...
        this._buildMarker();
      });
    } else {
      console.error(
        'we tried to buid a marker but the task is not there',
        this
      );
    }
    // The topmost parent hook call.
    // Here we have a `element` available and
    // we are certain that none of the children's
    // `didInsertParent` hooks were called
  }

  @action
  willDestroyParent(element) {
    //console.log('BP:W:Marker : willDestroyParent', this.args.start, this.args.end)
    this._destroyMarker();
    // the reverse is applied here.
    // We are certain that this call will take place
    // when all of the children's `willDestroyParent`
    // were called.
  }

  @action
  updateMarker() {
    //console.log('BP:W:Marker : calling updateMarker', this.args.start, this.args.end)
    let realEnd = this.args.end || this.args.start;
    this.displayMarker.setOptions({
      start: this.args.start,
      end: realEnd,
    });
  }

  get color() {
    return this.args.color || this._color;
  }

  get start() {
    return this.args.start;
  }

  get end() {
    // If no end is supplied we have to make a short region for event handling to work
    // https://github.com/katspaugh/wavesurfer.js/issues/3328
    return this.args.end || this.args.start + 0.2;
  }

  // TODO
  get content() {
    //console.log('BP:W:Marker going to set content as ', this.markerContent)
    return this.markerContent;
    //let elem = document.getElementById(`comment_${comment.id}_icon`);
    let regionContent = null;
    //if(elem){
    //regionContent = elem.cloneNode(true);
    //regionContent.classList.add('commentIcon')
    //}
  }
}
