/* import __COLOCATED_TEMPLATE__ from './project-directory-cache-clearer.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ProjectDirectoryCacheClearerComponent extends Component {
  @service seshyDir;

  @tracked hasChanged = false;

  @action
  async clearCache() {
    this.hasChanged = true;
  }

  @action
  async save() {
    this.removeCacheDirectory();
    this.hasChanged = false;
  }

  @action
  async cancel() {
    this.folderHasChanged = false;
  }

  async removeCacheDirectory() {
    var cacheDirPath = await this.seshyDir.projectCacheDirPath();
    const fs = require('fs');
    if (fs.existsSync(cacheDirPath)) {
      fs.rmdirSync(cacheDirPath, { recursive: true });
    }
    alert('The cache is cleared!');
  }
}
