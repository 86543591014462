/* import __COLOCATED_TEMPLATE__ from './bounce-player-commentable.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action, set, setProperties } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';
import WaveSurfer from 'wavesurfer.js';
import TimelinePlugin from 'wavesurfer.js/plugins/timeline';
import MinimapPlugin from 'wavesurfer.js/plugins/minimap';
import { later } from '@ember/runloop';
import { sort, filter, uniqBy, union } from '@ember/object/computed';
import { A } from '@ember/array';
import CommentMarker from 'seshy/models/comment-marker';

import BouncePlayerWavesurferMarked from './bounce-player-wavesurfer-marked';

export default class BouncePlayerComponent extends BouncePlayerWavesurferMarked {
  @service currentlyPlaying;
  @service stereo;
  @service store;
  @service stickyCalculator;
  @service currentUser;

  @tracked timeline = null;
  @tracked minimapTimeline = null;
  @tracked minimap = null;

  @tracked skipClickHandling = false;
  @tracked newIssue = null;
  @tracked newComment = null;

  @tracked issueFormIsOpen = false;

  commentStartSorting = ['earliestMarkerStart:asc'];
  @sort('args.comments', 'commentStartSorting') sortedComments;

  constructor() {
    super(...arguments);
    this.createNewIssue();
    this.createNewComment();
    //this.boundAudioPositionChanged = this.audioPositionChanged.bind(this);
  }

  wireUpCurrentBounce() {
    super.wireUpCurrentBounce();
    //console.log('Commentable: wireUpCurrentBounce', this.bounce.id);
    // TODO: Ideally we wouldn't load the sound first
    this.loadSound();
  }

  // This is for the comment/issue form attached directly in the player
  createNewIssue() {
    this.newIssue = this.store.createRecord('comment', {
      type: 'issue',
      project: this.bounce.get('projectVersion.project'),
      projectVersion: this.bounce.get('projectVersion'),
      bounce: this.bounce,
      user: this.currentUser.user,
      markers: A([]),
    });
  }

  // This is for the simple comment form at the end of the list
  createNewComment() {
    this.newComment = this.store.createRecord('comment', {
      type: 'comment',
      project: this.bounce.get('projectVersion.project'),
      projectVersion: this.bounce.get('projectVersion'),
      bounce: this.bounce,
      user: this.currentUser.user,
      markers: A([]),
    });
  }

  // We override this method so we can move the cursor back to the active marker when we start/stop playing
  afterTogglePlaySound() {
    super.afterTogglePlaySound();
    //console.log('Commentable: afterTogglePlaySound!!!')
    // TODO: Instead of using the first marker, use the focused marker
    if (this.newIssue.markers.length) {
      let marker = this.newIssue.markers.objectAt(0);
      let newTimeInMs = marker.start * 1000;
      this.sound.position = newTimeInMs;
    }
  }

  // This sets the initial zoom when the wavesurfer is created
  get minPxPerSec() {
    return this.zoom;
  }

  async preBuildPlugins() {
    await super.preBuildPlugins();
    await this.buildMinimap();
  }

  async registerUiHandlers() {
    await super.registerUiHandlers();

    this.minimap.on('interaction', this.wavesurferInteraction);

    //console.log('about to set region handleras and this.regions = ', this.regions);
    this.regions.on('region-in', this.regionIn.bind(this));
    this.regions.on('region-clicked', this.regionClicked.bind(this));
  }

  async buildMinimap() {
    this.minimapTimeline = await TimelinePlugin.create({
      height: 12,
      timeInterval: 15,
      primaryLabelInterval: 60,
      style: {
        fontSize: '0.75rem',
        color: '#999',
        lineHeight: '1em',
        //marginTop: '0.5em',
        marginBottom: '0em',
      },
    });
    this.minimap = await MinimapPlugin.create({
      insertPosition: 'beforebegin',
      height: 30,

      waveColor: '#059669',
      progressColor: '#047857',
      //waveColor: '#ccc',
      //progressColor: '#999',
      overlayColor: 'rgba(125,125,125,0.25)',
      //overlayColor: '#eee',
      barHeight: 0.4,
      interact: true,
      dragToSeek: false,
      style: {
        marginBottom: '0em',
        paddingTop: '1em',
      },
      plugins: [this.minimapTimeline],
    });
    this.plugins.push(this.minimap);
  }

  wavesurferStyle() {
    return {
      marginTop: '1em',
    };
  }

  focusRegion(region) {
    var commentId = region.id;
    this.focusComment(commentId);
  }

  focusComment(commentId) {
    //console.log('commentId = ', commentId)
    var element = document.getElementById(commentId);
    var list = document.getElementById('commentList');
    var overflowMode = list.style.overflowY;
    var isOverflowing = list.scrollHeight > list.clientHeight;
    if (isOverflowing) {
      //console.log('is overflowing ', element.offsetTop)
      var elementTop = element.offsetTop;
      list.scrollTop = elementTop;
    } else {
      //console.log('is not overflowing')
      var headerOffset = this.stickyCalculator.top();
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  regionIn(region) {
    if (region.id == 'comment-marker') {
      return;
    }
    this.focusRegion(region);
  }

  regionClicked(region) {
    this.wavesurfer.toggleInteraction(false);
    //console.log('regionClicked', region);
    this.skipClickHandling = true;
    this.focusRegion(region);
    //console.log('updating positions------------------------')
    this.updateSoundPosition(region.start);
    //this.updateWavesurferPosition(region.start);
    this.updateActiveMarkerPosition(region.start);
    //console.log('done updating positions------------------------')
    later(() => {
      this.wavesurfer.toggleInteraction(true);
      this.skipClickHandling = false;
    });
  }

  // TODO: Maybe hoist the buildWavesurferMarkersTask logic into this method?
  // Or rely on a version from the superclass?
  async buildCommentMarkers() {
    this.buildWavesurferMarkersTask.perform();
  }

  buildWavesurferMarkersTask = task(async () => {
    //console.log('about to buildWavesurferMarkersTask and this.regions = ', this.regions);
    if (!this.regions) {
      console.error(
        'we cannot build comment markers because this.regions is missing'
      );
      return;
    }
    //this.realBuildWavesurferMarkers();
    this.cachedComments = this.args.comments;
    //console.log('set cachedComments', this.cachedComments);
    //console.log('going to build markers for ', this.args.comments.length)
    this.args.comments.forEach((comment) => {
      if (!comment.isNew) {
        //comment.populateTrackedMarkers();
        //comment.trackedMarkers = A(comment.markers);
        for (let i = 0, len = comment.markers.length; i < len; i++) {
          let marker = comment.markers[i];
          this.buildSingleCommentMarker(comment, marker);
        }
      }
    });
  });

  buildCommentMarker() {
    //console.log('calling buildCommentMarker')
    // TODO: Handle multiple markers on the new comment
    let currentTime = this.wavesurfer.getCurrentTime();
    let newMarkerData = this.newIssue.createMarker({
      start: currentTime,
      end: null,
      isEditing: true,
      isActive: true,
    });

    let elem = document.getElementById(`new_comment_icon`);

    let newDisplayMarker = this.regions.addRegion({
      id: 'comment-marker',
      start: newMarkerData.start,
      end: newMarkerData.end,
      content: elem.cloneNode(true),
      color: '#ff0000',
      drag: false,
      resize: false,
    });
    let displayMarker = {
      comment: this.newIssue,
      marker: newMarkerData,
      displayMarker: newDisplayMarker,
    };
    this.newDisplayMarkers.pushObject(displayMarker);

    this.setActiveMarker(this.newIssue, newMarkerData);
  }

  moveCommentMarker() {
    let currentTime = this.wavesurfer.getCurrentTime();
    this.updateActiveMarkerPosition(currentTime);
  }

  @action
  toggleComment() {
    if (this.issueFormIsOpen && this.newIssue.type == 'comment') {
      this.cancelComment();
    } else {
      this.newIssue.type = 'comment';
      this.startComment();
    }
  }

  @action
  toggleIssue() {
    if (this.issueFormIsOpen && this.newIssue.type == 'issue') {
      this.cancelComment();
    } else {
      this.newIssue.type = 'issue';
      this.startComment();
    }
  }

  @action
  startComment() {
    this.issueFormIsOpen = true;
    return;
    if (this.newIssue.markers.length) {
      //console.log('trying to move themarker')
      this.moveCommentMarker();
    } else {
      //console.log('trying to build the marker')
      this.buildCommentMarker();
    }
  }

  // This is the action for saving the comment/issue that's directly connected to the player
  @action
  async saveComment() {
    let comment = this.newIssue;

    comment.set('markers', comment.get('markers'));
    await comment.save();

    this.createNewIssue();
    later(() => {
      // TODO: Fix this
      if (comment.marker) {
        comment.marker.remove();
        comment.marker = null;
      }
      //comment.populateTrackedMarkers();
      for (let i = 0, len = comment.markers.length; i < len; i++) {
        let marker = comment.markers[i];
        this.buildSingleCommentMarker(comment, marker);
      }
      this.focusComment(`comment_${comment.id}`);
    });
    this.cancelComment();
  }

  // This is the action for saving the comment at the bottom of the list
  @action
  async saveSingleComment() {
    let comment = this.newComment;
    await comment.save();
    this.createNewComment();
  }

  // For closing the comment/issue form connected to the player
  @action
  cancelComment() {
    this.issueFormIsOpen = false;

    this.newIssue.markers = A([]);

    for (let i = 0, len = this.newDisplayMarkers.length; i < len; i++) {
      let displayMarker = this.newDisplayMarkers[i];
      this.removeDisplayMarker(displayMarker);
    }

    this.setActiveMarker(null);
  }

  @action
  commentDeleteAction(comment) {
    for (let i = 0, len = comment.markers.length; i < len; i++) {
      let marker = comment.markers[i];
      let displayMarker = this.findDisplayMarker(comment, marker);
      if (displayMarker) {
        this.removeDisplayMarker(displayMarker);
      }
    }
  }

  @action
  commentMarkerClick(comment, marker) {
    //console.log('commentClick', comment);
    var commentId = `comment_${comment.id}`;
    this.focusComment(commentId);

    super.commentMarkerClick(comment, marker);
  }

  @action
  async wavesurferInteraction(newTime) {
    console.log('Commentable: wavesurferInteraction', newTime);
    super.wavesurferInteraction(newTime);
    this.updateWavesurferPosition(newTime);
    this.updateActiveMarkerPosition(newTime);
  }

  @action
  destroyWavesurfer() {
    console.log(
      'Commentable: calling destroyWavesurfer',
      this.cachedComments?.length
    );

    if (this.minimapTimeline) {
      console.log('destroying minimapTimeline');
      this.minimapTimeline.destroy();
      this.minimapTimeline = null;
    }
    if (this.minimap) {
      console.log('destroying minimap');
      this.minimap.destroy();
      this.minimap = null;
    }
    super.destroyWavesurfer();
  }
}
