import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';

export default class ProjectsRoute extends BaseRoute {
  @service router;

  //redirect(){
  //this.router.transitionTo('authenticated.teams.show.projects.list');
  //}
}
