/* import __COLOCATED_TEMPLATE__ from './project-list-item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import ProjectsAbstractStatusComponent from './projects/abstract/status';

export default class ProjectListItemComponent extends ProjectsAbstractStatusComponent {
  @service projectVersionRepository;
  @service projectUploadManager;
  @service projectDownloadManager;
  @service currentUser;
  @service platform;

  @tracked linkTeam = 'wat';

  @action
  async setLinkTeam() {
    if (this.args.team) {
      this.linkTeam = this.args.team;
      return;
    }
    let teams = await this.args.project.get('teams');
    this.linkTeam = teams.at(0);
  }

  @action
  async removeBrandNewProjectVersion() {
    var brandNewVersion = this.args.project.get('brandNewProjectVersion');
    if (brandNewVersion) {
      brandNewVersion.set('brandNewProjectVersion', null);
    }

    this.args.project.set('brandNewProjectVersion', null);
    await this.args.project.reload();
  }
}
