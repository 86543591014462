/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
import Component from '@glimmer/component';
import { sort, filter, uniqBy, union } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';

export default class CommentsListComponent extends Component {
  @union('args.comments', 'args.stateChanges') allItems;

  commentStartSorting = ['createdAtISO:asc'];

  @sort('args.comments', 'commentSortingGetter') sortedComments;
  @sort('allItems', 'commentSortingGetter') sortedItems;

  get commentSortingGetter() {
    if (this.args.sortDefinition) {
      return this.args.sortDefinition;
    }
    return this.commentStartSorting;
  }

  get sortComments() {
    if (typeof this.args.sortComments !== 'undefined') {
      return this.args.sortComments;
    } else {
      return true;
    }
  }

  get commentsToDisplay() {
    if (this.sortComments) {
      return this.sortedItems;
    } else {
      return this.allItems;
    }
  }
}
