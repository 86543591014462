/* import __COLOCATED_TEMPLATE__ from './empty-project-list.hbs'; */
import Component from '@glimmer/component';

import { inject as service } from '@ember/service';

export default class EmptyProjectListComponent extends Component {
  @service settings;
  @service platform;

  get seshyFolders() {
    return this.settings.pathsToWatch();
  }
}
