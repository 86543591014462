import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedIssuesIndexRoute extends Route {
  @service store;

  async model() {
    let issues = await this.store.query('comment', {
      filter: { top_level: true },
      include: 'user,project,project-version',
    });

    return { issues };
  }
}
