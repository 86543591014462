import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import ENV from 'seshy/config/environment';
import { A } from '@ember/array';

export default class AuthenticatedRoute extends Route {
  @service projectDownloadManager;
  @service projectUploadManager;
  @service projectVersionRepository;
  @service settings;
  @service session;
  @service router;
  @service store;
  @service cable;
  @service platform;
  @service currentUser;

  // TODO: This service is temporary, and should be removed after a few weeks? Months?
  // It's just to get previous installs over the hump of the project_data.json file
  // living in ~/Seshy/.seshy/project_data.json to being in ~/Library/Application Support/Seshy
  @service userDataUpgrade;

  activeOperations = [];

  beforeModel(transition) {
    //console.log('AuthenticatedRoute beforeModel');
    this.session.requireAuthentication(transition, 'login');
  }

  async afterModel(model, transition) {
    //console.log('AuthenticatedRoute afterModel');
    // TODO : this conditional might be redundant here?
    if (this.session.isAuthenticated) {
      // TODO: Is this the best place for this?
      this.cable.startSubscriptions();

      if (this.platform.isNative) {
        await this.userDataUpgrade.perform();
        await this.settings.readDataFile();
        // TODO : Will blocking here cause problems with large data files?
        await this.projectVersionRepository.readDataFile();
        await this.projectVersionRepository.scanForDeletedProjects();
        //console.log('AuthenticatedIndexRoute starting watcher');
        this.projectUploadManager.start();
      }
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);

    controller.set('activeOperations', this.activeOperations);
    controller.set('projectDownloadManager', this.projectDownloadManager);
  }
}
