/* import __COLOCATED_TEMPLATE__ from './form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default class IssuesFormComponent extends Component {
  @service currentlyPlaying;
  @service router;

  @tracked _isEditing = false;
  @tracked currentAudioTime = null;

  get isEditing() {
    return this.args.isEditing || this._isEditing;
  }

  get isCancelable() {
    //console.log('isCancelable = ', this.args.issue.isNew, !!this.args.cancelAction);
    return !this.args.issue.isNew || !!this.args.cancelAction;
  }

  get showBounceControls() {
    if (typeof this.args.showBounceControls !== 'undefined') {
      return this.args.showBounceControls;
    }

    return true;
  }

  @action
  markerUpdated() {
    console.log('markerUpdated');
    let newArray = [...this.args.issue.markers];
    this.args.issue.markers = [];
    this.args.issue.markers = newArray;
  }

  @action
  getCursorTime() {
    console.log('calling getCursorTime', this.displayTime);
    return this.displayTime;
  }

  get sound() {
    return this.currentlyPlaying.getSound(this.args.issue.bounce?.soundId);
  }

  get soundTime() {
    if (this.sound) {
      return this.sound?.position / 1000;
    }
    return null;
  }

  get displayTime() {
    return this.soundTime || this.currentAudioTime;
  }

  @action
  addMarker() {
    let newStart = 0;
    if (this.currentAudioTime) {
      newStart = this.currentAudioTime;
    }
    let newMarker = { start: newStart, end: null, isEditing: true };
    newMarker = this.args.issue.createMarker(newMarker);
    this.markerUpdated();
  }

  @action
  setCurrentAudioTime(newTime) {
    //console.log('setCurrentAudioTime = ', newTime);
    this.currentAudioTime = newTime;
  }

  @action
  setAudioTime(newTime) {
    //console.log('setAudioTime =', newTime);
    this.currentAudioTime = newTime;
    if (this.sound) {
      this.sound.position = newTime * 1000;
    }
  }

  @action
  setBounce(bounce) {
    if (this.args.issue.bounce) {
      this.args.issue.bounce = null;
    }
    later(() => {
      this.args.issue.bounce = bounce;
      this.args.issue.projectVersion = bounce.projectVersion;
    });
  }

  @action
  captureOriginalMarkers() {
    this.originalMarkers = JSON.stringify(this.args.issue.markers); //JSON.stringify(this.args.issue.markers);
    //console.log('we are calling captureOriginalMarkers = ', this.originalMarkers)
  }

  @action
  startEditing(e) {
    e.preventDefault();
    //console.log('startEditing');
    this._isEditing = true;
    return false;
  }

  @action
  cancelEditing() {
    if (!this.args.issue.isNew) {
      this.removeExistingMarkers();
      this.args.issue.rollbackAttributes();
      this.createNewMarkers();
      this._isEditing = false;
    } else {
      if (this.args.cancelAction) {
        this.args.cancelAction();
      }
    }

    //later(this,this.createNewMarkers);
  }

  removeExistingMarkers() {
    let markersToRemove = [...this.args.issue.markers];
    //console.log('canceling with tis many tracked markers ', this.args.issue.markers.length);
    for (let i = 0, len = markersToRemove.length; i < len; i++) {
      let marker = markersToRemove[i];
      this.args.issue.removeMarker(marker);
    }
  }

  createNewMarkers() {
    this.args.issue.clearMarkers();
    let markersToCreate = JSON.parse(this.originalMarkers);
    for (let i = 0, len = markersToCreate.length; i < len; i++) {
      let marker = markersToCreate[i];
      //console.log('need to create a marker for ', marker);

      let newMarker = this.args.issue.createMarker(marker);
      //if(this.args.updateIssueMarker){ this.args.updateIssueMarker( this.args.issue, null, newMarker); }
    }
    //this.args.issue.resetMarkers();
    //console.log('after rebuilding markers ', JSON.stringify(this.args.issue.markers));
  }

  @action
  async saveIssue() {
    if (this.args.saveAction) {
      this.args.saveAction();
    } else {
      await this.args.issue.save();
      this._isEditing = false;
    }
  }

  @action async closeIssue(closeStatus) {}

  @action
  async startDeleting(e) {
    e.preventDefault();
    //console.log('startDeleting', this.args.commentDeleteAction);
    if (
      confirm(
        'Are you sure you want to delete this issue and all the comments that are on it?'
      )
    ) {
      let comment = this.args.issue;
      comment.deleteRecord();
      await comment.save();
      this.router.transitionTo('authenticated.projects.show.issues');
    }
  }
}
