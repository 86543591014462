/* import __COLOCATED_TEMPLATE__ from './breadcrumbs-item.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class BreadcrumbsItemComponent extends Component {
  @service breadcrumbs;

  @action
  pushCrumb() {
    //console.log('pushCrumb', this.breadcrumbs);
    this.breadcrumbs.pushCrumb(this);
  }

  @action
  removeCrumb() {
    //console.log('removeCrumb', this.args.text);
    this.breadcrumbs.removeCrumb(this);
  }
}
