// Ideally we want to do this:
//import Model, { attr, belongsTo } from '@ember-data/model';

// But due to the bug mentioned in ./base we do this:
import Model from './base';
import { attr, belongsTo } from '@ember-data/model';

export default class BounceDownloadRequestModel extends Model {
  @belongsTo('bounce', { inverse: null, async: false }) bounce;

  @attr downloadUrl;
}
