/* import __COLOCATED_TEMPLATE__ from './payment-required-banner.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import ENV from 'seshy/config/environment';
import { inject as service } from '@ember/service';

export default class PaymentRequiredBannerComponent extends Component {
  @service projectVersionRepository;
  @service projectUploadManager;

  @action
  openSubscriptionsPage(e) {
    e.preventDefault();
    var page =
      ENV.api.webhost +
      `/account/teams/${this.args.project.teamId}/billing/subscriptions`;
    console.log('yo1', page);

    let shell = require('electron').shell;
    shell.openExternal(page);
    return true;
  }

  @action
  retryUpload() {
    var projectData = this.args.project.get('retryProjectData');
    console.log('projectData for retry = ', projectData);
    this.projectUploadManager.projectUpdated(projectData);
    this.args.project.set('apiPaymentRequired', false);
  }
}
