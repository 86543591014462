/* import __COLOCATED_TEMPLATE__ from './bubble-list.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class IssueFormBubbleListComponent extends Component {
  @action
  commentMarkerClick(comment, marker) {
    if (this.args.commentMarkerClick) {
      this.args.commentMarkerClick(comment, marker);
    }
  }
}
