import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';

export default class BreadcrumbsService extends Service {
  @tracked crumbs = A([]);

  pushCrumb(crumb) {
    this.crumbs.pushObject(crumb);
  }

  removeCrumb(crumb) {
    this.crumbs.removeObject(crumb);
  }
}
