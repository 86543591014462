export function pushPayload(store, modelName, rawPayload) {
  let ModelClass = store.modelFor(modelName);
  let serializer = store.serializerFor(modelName);

  let jsonApiPayload = serializer.normalizeResponse(
    store,
    ModelClass,
    rawPayload,
    null,
    'query'
  );

  return store.push(jsonApiPayload);
}
