import JSONAPIAdapter from '@ember-data/adapter/json-api';
import ENV from 'seshy/config/environment';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default class ApplicationAdapter extends JSONAPIAdapter {
  @service session;
  @service router;
  @service subscriptionTracker;

  host = ENV.api.apihost;
  namespace = ENV.api.namespace;

  @computed('session.{data.authenticated.access_token,isAuthenticated}')
  get headers() {
    let headers = {};
    if (this.session.isAuthenticated) {
      // OAuth 2
      headers[
        'Authorization'
      ] = `Bearer ${this.session.data.authenticated.access_token}`;
      // Help up shape requests to not break older versions and track
      // what versions are in the wild
      headers['SeshyVersion'] = ENV.APP.version;
    }

    return headers;
  }

  handleResponse(status, headers, _payload, requestData) {
    if (status === 401) {
      //this.router.replaceWith('login');
      this.session.invalidate();
      return;
    }

    // Unfortunately I used 'payment-required' to really mean 'payment-required-soon'
    // and since I can't force everyone to upgrade, that's just how it is. So this top
    // set is legacy, and I'm leaving it to remind myself not to use 'payment-required' in the future.
    if (
      headers['seshy-payment-required'] &&
      headers['seshy-payment-required'] == 'true'
    ) {
      // Do nothing now.
    }

    if (
      headers['seshy-subscription-required'] &&
      headers['seshy-subscription-required'] == 'true'
    ) {
      console.log(
        'headers["seshy-subscription-required"] =',
        headers['seshy-subscription-required']
      );
      let teamId = headers['seshy-subscription-required-team-id'];
      let teamName = headers['seshy-subscription-required-team-name'];
      let projectId = headers['seshy-subscription-required-project-id'];
      let projectName = headers['seshy-subscription-required-project-name'];
      let starting = headers['seshy-subscription-required-starting'];
      this.subscriptionTracker.subscriptionRequired(
        teamId,
        teamName,
        projectId,
        projectName,
        starting
      );
    }

    return super.handleResponse(...arguments);
  }
}
