/* import __COLOCATED_TEMPLATE__ from './commentable.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { A } from '@ember/array';
import { later } from '@ember/runloop';
import { sort, filter, uniqBy, union } from '@ember/object/computed';

export default class BouncePlayerCommentableComponent extends Component {
  @service currentlyPlaying;
  @service store;
  @service currentUser;
  @service stickyCalculator;

  @tracked wavesurferElement = null;
  @tracked soundShouldPlay = false;
  @tracked skipClickHandling = false;

  @tracked _currentAudioTime = null;
  @tracked _zoom = 10;
  @tracked issueFormIsOpen = false;
  @tracked newIssue = null;

  commentStartSorting = ['earliestMarkerStart:asc'];
  @sort('args.bounce.comments', 'commentStartSorting') sortedComments;

  get sound() {
    //console.log('get sound', this.args.bounce?.soundId)
    return this.currentlyPlaying.getSound(this.args.bounce?.soundId);
  }

  @action
  captureWavesurferElement(element) {
    //console.log('captureWavesurferElement', element);
    this.wavesurferElement = element;
    //this.zoom = this.minPxPerSec;
  }

  @action
  onAudioPositionChanged(newTime) {
    //console.log('onAudioPositionChanged', newTime);
    this._currentAudioTime = newTime;
    if (this.args.onAudioPositionChanged) {
      this.args.onAudioPositionChanged(newTime);
    }
  }

  @action
  onInteraction(newTime) {
    console.log('onInteraction', newTime);
    if (this.skipClickHandling) {
      //console.log('skipClickHandling = true, bailing out', this._currentAudioTime)
      return;
    }
    if (this.args.onInteraction) {
      //console.log('we have an onInteraction arg')
      this.args.onInteraction(newTime);
    } else {
      //console.log('we do not have an onInteraction arg')
      if (this.sound) {
        //console.log('we have a sound')
        this.sound.position = newTime * 1000;
      } else {
        this._currentAudioTime = newTime;
        //console.log('we do not have a sound')
      }
    }
  }

  @action
  async commentMarkerClick(comment, marker) {
    //console.log('commentMarkerClick', marker.start)
    this.onInteraction(marker.start);
    this.soundShouldPlay = true;
    //this.updateSoundPosition(marker.start);
    //await this.playSoundTask.perform();
  }

  get currentAudioTime() {
    return this._currentAudioTime || 0;
  }

  get displayTime() {
    return this.args.displayTime || this.currentAudioTime;
  }

  @action
  getCursorTime() {
    //console.log('calling getCursorTime', this.displayTime);
    return this.displayTime;
  }

  get zoom() {
    //console.log('trying to get zoom', this._zoom, this.minPxPerSec);
    return this._zoom || this.minPxPerSec;
  }

  set zoom(newZoom) {
    //console.log('the zoom setter', newZoom);
    this._zoom = newZoom;
  }

  setZoom(newZoom) {
    //console.log('setZoom', newZoom);
    this.zoom = newZoom;
    if (this.zoom < 1) {
      this.zoom = 1;
    }
    if (this.zoom > 50) {
      this.zoom = 50;
    }
    //this.wavesurfer.zoom(this.zoom);
  }

  @action zoomIn() {
    this.setZoom(this.zoom + this.zoom * 0.2);
  }

  @action zoomOut() {
    this.setZoom(this.zoom - this.zoom * 0.2);
  }

  nudgePosition(seconds) {
    let currentTime = this.currentAudioTime;
    let newTime = currentTime + seconds;

    this._currentAudioTime = newTime;
    //this.updateSoundPosition(newTime);
    //this.updateWavesurferPosition(newTime);
  }

  @action backBig() {
    this.nudgePosition(-5);
  }

  @action backSmol() {
    this.nudgePosition(-1);
  }

  @action forwardBig() {
    this.nudgePosition(5);
  }

  @action forwardSmol() {
    this.nudgePosition(1);
  }

  get bounce() {
    return this.args.bounce;
  }

  // This is for the comment/issue form attached directly in the player
  @action
  createNewIssue() {
    this.newIssue = this.store.createRecord('comment', {
      type: 'issue',
      project: this.bounce.get('projectVersion.project'),
      projectVersion: this.bounce.get('projectVersion'),
      bounce: this.bounce,
      user: this.currentUser.user,
      markers: A([]),
    });
  }

  @action
  toggleComment() {
    if (this.issueFormIsOpen && this.newIssue.type == 'comment') {
      this.cancelComment();
    } else {
      this.newIssue.type = 'comment';
      this.startComment();
    }
  }

  @action
  toggleIssue() {
    if (this.issueFormIsOpen && this.newIssue.type == 'issue') {
      this.cancelComment();
    } else {
      this.newIssue.type = 'issue';
      this.startComment();
    }
  }

  @action
  startComment() {
    this.issueFormIsOpen = true;
    return;
    if (this.newIssue.markers.length) {
      //console.log('trying to move themarker')
      this.moveCommentMarker();
    } else {
      //console.log('trying to build the marker')
      this.buildCommentMarker();
    }
  }

  // This is the action for saving the comment/issue that's directly connected to the player
  @action
  async saveComment() {
    let comment = this.newIssue;

    comment.set('markers', comment.get('markers'));
    await comment.save();

    this.createNewIssue();
    this.cancelComment();
  }

  // For closing the comment/issue form connected to the player
  @action
  cancelComment() {
    this.issueFormIsOpen = false;

    this.newIssue.markers = A([]);
  }

  focusRegion(region) {
    var commentId = region.id;
    this.focusComment(commentId);
  }

  focusComment(commentId) {
    //console.log('commentId = ', commentId)
    var element = document.getElementById(commentId);
    if(!element){
      return;
    }
    var list = document.getElementById('commentList');
    var overflowMode = list.style.overflowY;
    var isOverflowing = list.scrollHeight > list.clientHeight;
    if (isOverflowing) {
      //console.log('is overflowing ', element.offsetTop)
      var elementTop = element.offsetTop;
      list.scrollTop = elementTop;
    } else {
      //console.log('is not overflowing')
      var headerOffset = this.stickyCalculator.top();
      var elementPosition = element.getBoundingClientRect().top;
      var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  }

  regionIn(region) {
    if (region.id == 'comment-marker') {
      return;
    }
    this.focusRegion(region);
  }

  @action
  onRegionIn(region) {
    //console.log('regionIn', region);
    this.focusRegion(region);
  }

  @action
  onRegionClick(region) {
    //console.log('-------- regionClick', region);
    this._currentAudioTime = region.start;
    this.onInteraction(region.start);
    this.focusRegion(region);
    this.skipClickHandling = true;
    later(() => {
      this.skipClickHandling = false;
      // TODO: It seems we need to set this again to get it to stick.
      // Is there a way around that?
      this._currentAudioTime = region.start;
    });
  }
}
