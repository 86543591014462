import Controller from '@ember/controller';
import { inject as service } from '@ember/service';

export default class AuthenticatedProjectsShowProjectVersionsShowIndexController extends Controller {
  @service betaFeatures;

  get versionComments(){
    let comments = this.model.get('comments');
    return comments.filter((comment) => !comment.bounce)
  }

  get bounceComments(){
    let comments = this.model.get('comments');
    return comments.filter((comment) => comment.bounce)
  }
}
