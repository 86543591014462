import EmberObject from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { map, max, sort, filter, uniqBy, union } from '@ember/object/computed';

export default class Item extends EmberObject {
  @tracked id = null;
  @tracked showFullItem = false;
  @tracked _comments = A([]);

  createdAtSorting = ['createdAtISO:asc'];
  @sort('_comments', 'createdAtSorting') comments;

  @map('comments', (comment) => Date.parse(comment.createdAtISO)) activityTimestamps;
  @max('activityTimestamps') latestCommentTimestamp;


  get latestActivityTimestamp() {
    return Date.parse(this.item.createdAtISO) > this.latestCommentTimestamp ? Date.parse(this.item.createdAtISO) : this.latestCommentTimestamp
  }

  addComment(newComment) {
    let modelId = newComment.get('modelId');
    //console.log('adding newComment', modelId);
    let oldComment = this._comments.findBy('modelId', modelId);
    if (oldComment) {
      // TODO: How do we update old items?
      //console.log('  -- we already have this newComment')
    } else {
      //console.log('  ++ we are adding new newComment', modelId);
      this._comments.pushObject(newComment);
    }
  }
}
