/* import __COLOCATED_TEMPLATE__ from './project-archive-toggler.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import canUserManageProject from 'seshy/utils/can-user-manage-project';

export default class ProjectArchiveTogglerComponent extends Component {
  @service currentUser;

  @tracked errorMessage = null;

  get hasError() {
    return !!this.errorMessage;
  }

  get canManageProject() {
    return canUserManageProject(this.currentUser, this.args.project);
  }

  @action
  async archive() {
    console.log('we should archive');
    this.errorMessage = null;
    this.args.project.set('archived', true);
    try {
      await this.args.project.save();
    } catch (error) {
      this.args.project.set('archived', false);
      console.log('caught an error', error);

      let errorCause = error.errors[0];

      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  @action
  async activate() {
    console.log('we should activate');
    this.errorMessage = null;
    this.args.project.set('archived', false);
    try {
      await this.args.project.save();
    } catch (error) {
      this.args.project.set('archived', true);
      let errorCause = error.errors[0];
      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  setForbiddenMessage() {
    this.errorMessage =
      "You don't seem to be a Team Admin for the team that owns this project.";
  }

  setUnknownErrorMessage() {
    this.errorMessage =
      'We ran into an unexpected error while trying to change the archive status. Please try again.';
  }
}
