import EmptyFileError from '../errors/empty-file-error';
import slash from 'slash';

export default class LocalFile {
  crypto = window.require('crypto');
  fs = window.require('fs');
  // TODO: Is it valid to store this, or does it need to be computed at boot time?
  emptyFileFingerprint =
    'e3b0c44298fc1c149afbf4c8996fb92427ae41e4649b934ca495991b7852b855';

  constructor(path) {
    this.path = slash(path);
  }

  async fingerprint() {
    return new Promise((resolve, reject) => {
      //console.log('starting fingerprint', this.path);
      if (this.fs.lstatSync(this.path).isDirectory()) {
        //console.log('this is a directory', this.path);
        resolve('000000');
      }
      // if absolutely necessary, use md5
      const hash = this.crypto.createHash('sha256');
      //console.log('made the hash', this.path);
      const input = this.fs.createReadStream(this.path);
      //console.log('made the input', this.path);
      input.on('error', (err) => {
        console.error('caught an error trying to fingerprint', this.path, err);
        reject(err);
      });
      input.on('data', (chunk) => {
        hash.update(chunk);
      });
      input.on('close', () => {
        var fingerprint = hash.digest('hex');
        //if (fingerprint == this.emptyFileFingerprint) {
        //reject(new EmptyFileError(this.path));
        //} else {
        resolve(fingerprint);
        //}
      });
    });
  }

  projectName() {
    throw "you tried to call projectName on a file that's not part of a project";
  }

  projectPath() {
    throw "you tried to call projectName on a file that's not part of a project";
  }

  isProjectDataFiile() {
    throw "you tried to call isProjectDataFiile on a file that's not part of a project";
  }
}
