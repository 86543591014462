/* import __COLOCATED_TEMPLATE__ from './search-input.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SearchInputComponent extends Component {
  @action
  inputReceived(event) {
    this.args.searchAction(this.args.searchValue);
    return true;
  }

  @action
  clear() {
    this.args.searchAction('');
    return true;
  }
}
