/* import __COLOCATED_TEMPLATE__ from './simple-form.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class IssuesSimpleFormComponent extends Component {
  @action
  addMarker() {
    let newStart = 0;
    if (this.args.getCursorTime) {
      newStart = this.args.getCursorTime();
    }
    let newMarker = { start: newStart, end: null, isEditing: true };
    newMarker = this.args.issue.createMarker(newMarker);
    this.markerUpdated();
  }

  @action
  markerUpdated() {
    console.log('markerUpdated');
    let newArray = [...this.args.issue.markers];
    this.args.issue.markers = [];
    this.args.issue.markers = newArray;
  }
}
