import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedProjectsShowProjectVersionsListController extends Controller {
  @service router;
  queryParams = ['searchValue'];
  @tracked searchValue = '';

  get filteredProjectVersions() {
    let versions = this.model.projectVersions;
    let filteredVersions = versions;

    if (this.searchValue != '') {
      var pattern = new RegExp(this.searchValue, 'i');
      filteredVersions = filteredVersions.filter(function (
        item,
        index,
        enumerable
      ) {
        var data = item.get('description');
        return pattern.test(data);
      });
    }
    return filteredVersions;
  }

  @action
  controllerRefresh() {
    console.log('controllerRefresh');
    this.send('routeRefresh');
  }

  @action
  setSearch(searchValue) {
    this.searchValue = searchValue;
    this.router.transitionTo({
      queryParams: { archived: this.archived, searchValue: this.searchValue },
    });
    this.send('propagateSearch', searchValue);
  }
}
