import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { pushPayload } from 'seshy/utils/push-payload';
import { action } from '@ember/object';
import { hash } from 'rsvp';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { later } from '@ember/runloop';
import { task, timeout } from 'ember-concurrency';

export default class IndexRoute extends BaseRoute {
  @service store;
  @service currentUser;
  @service betaFeatures;

  async model(params) {
    let projects = this.store.query('project', {
      filter: { archived: false },
      page: { size: 10 },
      sort: '-latestVersionUpdatedAt',
      include:
        'teams,user,updator,current-project-version,current-project-version.bounces',
    });

    // TODO: For some reaon things don't work right if we don't await the comments
    // model individually. Not sure why. Would be nice to be able to use hash to resolve
    // all of the promises, but that seems to leave things in an incomplete state.
    // Specifically it makes it so that some of the project-versions will be missing
    // their project, even though they're include in the request/response.
    let comments = await this.store.query('comment', {
      filter: { activeWithinDays: 31 },
      //page: {size: 25},
      sort: '-createdAt',
      include:
        'comment,comments,project,project-version,project-version.project,project-version.bounces,project-version.user,bounce,bounce.project-version,bounce.project-version.project,user',
    });

    let projectVersions = this.store.query('project-version', {
      filter: {},
      page: { size: 25 },
      sort: '-createdAt',
      include: 'bounces,project,user',
    });

    return hash({
      projects,
      comments,
      projectVersions,
      teams: this.currentUser.teams,
    });
  }

  @action
  routeRefresh() {
    //console.log('routeRefresh');
    this.refresh();
  }

  async afterModel(model, transition) {
    super.afterModel(model, transition);
    later(this, this.createSubscriptions);
  }

  async createSubscriptions() {
    let teams = await this.store.findAll('team');
    //teams = teams.toArray();
    //console.log('afterModel teams = ', teams, teams.length);
    for (let i = 0, len = teams.length; i < len; i++) {
      let team = teams[i];

      this.subscribeForRefresh(
        'ProjectsChannel',
        { team_id: team.id },
        this.setRefreshAvailable
      );
      this.subscribeForRefresh(
        'BouncesChannel',
        { team_id: team.id },
        this.setRefreshAvailable
      );
      this.subscribeForRefresh(
        'CommentsChannel',
        { team_id: team.id },
        this.setRefreshAvailable
      );
    }
  }
}
