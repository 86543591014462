import { set } from '@ember/object';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import { sort, map } from '@ember/object/computed';
import { A } from '@ember/array';
import CommentMarker from 'seshy/models/comment-marker';
import { later } from '@ember/runloop';

export default class CommentModel extends Model {
  @attr('string') title;
  @attr('string') body;
  @attr('date') createdAt;
  @attr('date') updatedAt;
  @belongsTo('project', { inverse: 'comments', async: false }) project;
  @belongsTo('project-version', { inverse: 'comments', async: false })
  projectVersion;
  @belongsTo('bounce', { inverse: 'comments', async: false }) bounce;
  @belongsTo('comment', { async: false, inverse: 'comments' }) comment;
  @hasMany('comment', { inverse: 'comment', async: false }) comments;
  @attr('string') type;
  @attr('string') state;
  //@attr('number') bounceStart;
  //@attr('number') bounceEnd;
  @attr('number') commentsCount;
  @attr stateChanges;
  @attr('markers', {
    defaultValue: () => {
      return [];
    },
  })
  markers;

  //@tracked trackedMarkers = A([]);

  markerStartSorting = ['start:asc'];
  @sort('markers', 'markerStartSorting') sortedMarkers;

  constructor() {
    super(...arguments);
    //later(this, this.populateTrackedMarkers)
  }

  createMarker(markerData) {
    //console.log('creating a marker for ', markerData);
    let newMarker = CommentMarker.create(markerData);
    this.markers.push(newMarker);
    return newMarker;
  }

  clearMarkers() {
    set(this, 'markers', A([]));
  }

  resetMarkers() {
    //console.log('calling resetMarkers and they = ', JSON.stringify(this.markers))
    set(this, 'markers', A(this.markers.toArray()));
    /*
    later(() => {
      console.log('in the later', JSON.stringify(this.markers));
      this.markers = A(this.markers.toArray());
    })
    */
  }

  removeMarker(marker){
    this.markers.splice(this.markers.indexOf(marker), 1);
  }

  /*
  populateTrackedMarkers(){
    //console.log('calling populateTrackedMarkers with markers = ', this.markers)
    this.trackedMarkers = A([]);
    for (let i = 0, len = this.markers.length; i < len; i++) {
      let marker = this.markers[i];
      this.createMarker(marker);
      //trackedMarkers.pushObject(marker);
    }
    //this.trackedMarkers = trackedMarkers;
    console.log('trackedMarkers = ', this.trackedMarkers);
  }
  */

  /*
  save(){
    console.log('calling save')
    let newMarkers = [];
    for (let i = 0, len = this.trackedMarkers.length; i < len; i++) {
      let marker = this.trackedMarkers[i];
      newMarkers.pushObject(marker.toSimpleMarker());
      //trackedMarkers.pushObject(marker);
    }
    this.markers = newMarkers;
    console.log('set newMarkers = ', this.markers);
    super.save();
  }
  */

  @belongsTo('user', { async: false, inverse: null }) user;

  @attr issueNumber;

  @tracked markerSectionLength = 10;
  @tracked marker = null;

  get hasComments() {
    return this.commentsCount > 0;
  }

  get isIssue() {
    return this.type == 'issue';
  }

  get earliestMarkerStart() {
    //console.log('getting earliestMarkerStart', this.markers);
    if (!this.markers) {
      return 0;
    }
    let start = this.markers[0]?.start || 0;
    for (const marker of this.markers) {
      if (marker.start < start) {
        start = marker.start;
      }
    }
    //console.log('returing ', start);
    return start;
  }

  get latestMarkerStart() {
    //console.log('getting latestMarkerStart', this.markers, this.bounce.duration);
    if (!this.markers) {
      return this.bounce.duration;
    }
    let start = this.markers[0]?.start || this.bounce.duration;
    for (const marker of this.markers) {
      if (marker.start > start) {
        start = marker.start;
      }
    }
    //console.log('latest start', start);
    return start;
  }

  get latestMarkerEnd() {
    //console.log('getting latestMarkerEnd', this.markers);
    if (!this.markers) {
      return null;
    }
    let end = this.markers[0]?.start; // Yes, we do start here to ensure we get a number
    for (const marker of this.markers) {
      if (marker.end > end) {
        end = marker.end;
      }
    }
    //console.log('latest end = ', end);
    return end;
  }

  get displayMarkerTime() {
    let theDateString = new Date(this.bounceStart * 1000).toISOString(); // '1970-01-01T00:01:33.000Z'
    if (this.bounceStart < 3600) {
      return theDateString.substring(14, 19); // 01:33
    } else {
      return theDateSttring.substring(11, 19); // 00:01:33
    }
  }

  // TODO: Maybe we don't need this...
  get displayMarkerEndTime() {
    let theDateString = new Date(this.bounceEnd * 1000).toISOString(); // '1970-01-01T00:01:33.000Z'
    if (this.newIssue.bounceEnd < 3600) {
      return theDateString.substring(14, 19);
    } else {
      return theDateSttring.substring(11, 19);
    }
  }

  get createdAtISO() {
    return this.createdAt?.toISOString();
  }

  get modelId() {
    return `comment-${this.id}`;
  }
}
