import BaseRoute from 'seshy/routes/base';
import { inject as service } from '@ember/service';
import { pushPayload } from 'seshy/utils/push-payload';

export default class AuthenticatedProjectsShowRoute extends BaseRoute {
  @service store;
  @service platform;

  async model(params) {
    return this.store.findRecord('project', params.project_id, {
      include: 'owning-team',
    });
  }
}
