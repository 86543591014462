/* import __COLOCATED_TEMPLATE__ from './description-editor.hbs'; */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class DescriptionEditorComponent extends Component {
  @service currentUser;

  @tracked isEditing = false;
  @tracked errorMessage = null;

  @action
  initEditing() {
    //console.log('calling initEditing', this);
    this.isEditing = this.args.isEditing || false;
  }

  get hasError() {
    return !!this.errorMessage;
  }

  get canEdit() {
    if (!this.args.describable.user) {
      return false;
    }
    return this.currentUser.user.id == this.args.describable.user.get('id');
  }

  @action
  cancel() {
    //console.log('cancel description');
    if (this.args.afterCancel) {
      this.args.afterCancel();
    }
    this.isEditing = false;
  }

  @action
  edit() {
    //console.log('add description');
    this.isEditing = true;
  }

  @action
  async save() {
    //console.log('save description');
    try {
      await this.args.describable.save();
      if (this.args.afterSave) {
        this.args.afterSave();
      }
      this.isEditing = false;
    } catch (error) {
      let errorCause = error.errors[0];
      console.log('caught an error tyring to save a comment', error);
      if (errorCause.status == '403') {
        this.setForbiddenMessage();
      } else {
        this.setUnknownErrorMessage();
        throw error;
      }
    }
  }

  setForbiddenMessage() {
    this.errorMessage = "You don't seem to be allowed to comment on this.";
  }

  setUnknownErrorMessage() {
    this.errorMessage =
      'We ran into an unexpected error while trying to save your comment.';
  }
}
