/* import __COLOCATED_TEMPLATE__ from './day.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { A } from '@ember/array';
import { action } from '@ember/object';
import { task, timeout } from 'ember-concurrency';
import { sort, filter, uniqBy, union } from '@ember/object/computed';

import ActivityProject from 'seshy/models/activity/project';

export default class ActivityListDayComponent extends Component {
  @service stickyCalculator;
  @tracked _projectData = A([]);

  latestActivitySorting = ['latestActivityTimestamp:desc'];
  @sort('_projectData', 'latestActivitySorting') projectData;

  get stickyDayTop() {
    //return this.stickyCalculator.top() + document.getElementsByClassName('stickyMainContentHeader')[0].offsetHeight;
    return this.stickyCalculator.top();
  }

  get stickyProjectTop() {
    return (
      this.stickyDayTop +
      document.getElementsByClassName('stickyDayHeader')[0].offsetHeight
    );
  }

  buildProjectData = task(async () => {
    //console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%')
    //console.log('%% Starting to build projectItems in ActivityList::Day')
    //let projectMap = {};
    //let data = [];

    for (const item of this.args.dayData.items.reverse()) {
      //console.log('handling item with timestamp ', item.createdAtISO)
      let projectId = item.projectId || item.get('project.id');
      //let projectData = projectMap[projectId];
      let projectData = this._projectData.findBy('id', `project-${projectId}`);
      if (!projectData) {
        //console.log('creating projectData for ', item.get('project.displayName'))
        projectData = ActivityProject.create({ project: item.project });
        //projectMap[projectId] = projectData;
        //data.push(projectData);
        this._projectData.pushObject(projectData);
      }
      projectData.addItem(item);
    }

    //console.log("data =", data)
    //console.log("projectMap =", projectMap)
    //return data;
  });
}
