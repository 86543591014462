/* import __COLOCATED_TEMPLATE__ from './pop-down-menu.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class PopDownMenuComponent extends Component {
  @tracked menuOpen = false;

  @action
  closeMenuIfOpen() {
    if (this.menuOpen) {
      this.menuOpen = false;
    }
  }

  @action
  toggleMenu(value) {
    this.menuOpen = !this.menuOpen;
  }
}
