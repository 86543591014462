/* import __COLOCATED_TEMPLATE__ from './main-container-header.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class MainContainerHeaderComponent extends Component {
  @service stickyCalculator;

  get stickyTop() {
    return this.stickyCalculator.top();
  }
}
