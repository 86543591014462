/* import __COLOCATED_TEMPLATE__ from './header-bar.hbs'; */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class HeaderBarComponent extends Component {
  @service router;

  @tracked menuOpen = false;
  @tracked skipCloseMenu = false;

  @action
  closeMenuIfOpen() {
    //console.log('calling closeMenuIfOpen')
    if (this.menuOpen) {
      if (this.skipCloseMenu) {
        this.skipCloseMenu = false;
      } else {
        this.menuOpen = false;
      }
    }
  }

  @action
  toggleMenu(value) {
    //console.log('toggling main menu');
    this.menuOpen = !this.menuOpen;
    if (this.menuOpen) {
      this.skipCloseMenu = true;
    }
  }

  @action
  menuNav(destination) {
    this.menuOpen = false;
    console.log('nav destination = ', destination);
    this.router.transitionTo(destination);
    return true;
  }
}
