/* import __COLOCATED_TEMPLATE__ from './project-list.hbs'; */
import Component from '@glimmer/component';
import { sort, filter, uniqBy } from '@ember/object/computed';

export default class ProjectListComponent extends Component {
  @uniqBy('args.projects', 'id') uniqueProjects;

  updatedAtSorting = ['latestVersionUpdatedAt:desc'];
  @sort('uniqueProjects', 'updatedAtSorting') sortedProjects;
}
