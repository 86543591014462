import OAuth2PasswordGrant from 'ember-simple-auth/authenticators/oauth2-password-grant';
import Base from 'ember-simple-auth/authenticators/base';
import ENV from 'seshy/config/environment';
import { bind, cancel, later, run } from '@ember/runloop';
import { isEmpty } from '@ember/utils';
import assign from 'ember-simple-auth/utils/assign';
import base64url from 'base64url';
import randomstring from 'randomstring';

export default class OAuth2PopupAuthenticator extends OAuth2PasswordGrant {
  authenticatedPromise;
  authenticationSuccess;
  authenticationFailure;

  clientId = ENV.api.clientId;

  // This is where we send people to start the auth dance. We pop this
  // open in a browser, and then people sign in, and then the bullet train
  // app rediredts to a seshy:// url that we intercept.
  authEndpoint = ENV.api.webhost + '/oauth/authorize';

  // When we intercept the auth dance above, and when we need to refresh a
  // token we post to this url.
  //
  // We point this at apihost so that the api has a convenient place to cache
  // user/membership/team data on a regular basis.
  serverTokenEndpoint = ENV.api.apihost + '/oauth/token';

  protocol =
    ENV.environment == 'production' ? 'seshy' : 'seshy' + ENV.environment;

  redirectUri = this.protocol + '://auth-return';
  responseType = 'code';
  responseMode = 'form_post';
  scope = 'read write delete';
  refreshAccessTokens = true;

  shell = require('electron').shell;
  crypto = window.require('crypto');

  constructor() {
    super(...arguments);
  }

  async authenticate() {
    this.authenticatedPromise = new Promise((fResolve, fReject) => {
      this.authenticationSuccess = fResolve;
      this.authenticationFailure = fReject;
    });
    await this._openBrowser();
    return this.authenticatedPromise;
  }

  async _openBrowser() {
    // state is a random string that we use to make sure that we're responding to the right redirect
    this.state = (Math.random() + 1).toString(36).substring(2);

    // Here we set up PKCE security extension values
    this.codeVerifier = randomstring.generate(128);
    const base64Digest = this.crypto
      .createHash('sha256')
      .update(this.codeVerifier)
      .digest('base64');
    this.codeChallenge = base64url.fromBase64(base64Digest);

    console.log('this.codeVerifier = ' + this.codeVerifier);
    console.log('this.codeChallenge = ' + this.codeChallenge);

    // Build a URL and then pass it to the native browser. This lets authentication happen directly on
    // the web site which allows the user to use a password manager.
    let _loginUrl =
      `${this.authEndpoint}` +
      `?client_id=${this.clientId}` +
      `&redirect_uri=${this.redirectUri}` +
      `&response_type=${this.responseType}` +
      `&response_mode=${this.responseMode}` +
      `&scope=${this.scope}` +
      `&state=${this.state}` +
      `&code_challenge_method=S256` +
      `&code_challenge=${this.codeChallenge}`;
    this.shell.openExternal(_loginUrl);

    // New register an event handler for the event that index.js will fire when the browser redirects back.
    const ipc = require('electron').ipcRenderer;
    ipc.on('auth-return', this._handleRedirect.bind(this));
  }

  // The browswer will redirect back to something like "seshy://auth-return?code=xxx"
  // electon-app/src/index.js will intercept that and pass us back everything after the '?'
  // We'll parse that into the format that ember-simple-auth expects to get from a successful
  // password flow, and then usee that data to resolve the Promise that we made earlier.
  async _handleRedirect(event, message) {
    var codeData = this._parseMessage(message);

    if (codeData['state'] != this.state) {
      var errorMessage =
        'The response from the authorization endpoint contains the wrong state parameter.';
      return this.authenticationFailure(errorMessage);
    }

    var tokenRequestData = {
      grant_type: 'authorization_code',
      code: codeData['code'],
      redirect_uri: this.redirectUri,
      client_id: this.clientId,
      code_verifier: this.codeVerifier,
    };

    var tokenResponse = await fetch(this.serverTokenEndpoint, {
      method: 'POST',
      body: JSON.stringify(tokenRequestData),
      headers: {
        'Content-Type': 'application/json',
      },
    });

    var tokenData = await tokenResponse.json();
    console.log('tokenData = ', tokenData);

    if (!tokenResponse.ok) {
      var errorMessage = tokenData['error_description'];
      return this.authenticationFailure(errorMessage);
    }

    // These next few lines are more or less lifted from the `authenticate` method in OAuth2PasswordGrant
    const expiresAt = this._absolutizeExpirationTime(tokenData['expires_in']);
    this._scheduleAccessTokenRefresh(
      tokenData['expires_in'],
      expiresAt,
      tokenData['refresh_token']
    );
    if (!isEmpty(expiresAt)) {
      tokenData = assign(tokenData, { expires_at: expiresAt });
    }

    // Now we let ember-simple-auth know that we've got a token.
    // It will handle stashing it so that the session will persist across app reloads and quits.
    this.authenticationSuccess(tokenData);
  }

  _parseMessage(message) {
    console.log('auth-return', message);
    var parts = message.split('&');
    console.log('parts = ', parts);
    var codeData = {};
    for (const part of parts) {
      var partParts = part.split('=');
      codeData[partParts[0]] = partParts[1];
    }
    console.log('codeData = ', codeData);
    return codeData;
  }
}
