import Model, { attr } from '@ember-data/model';

export default class UploadPresignModel extends Model {
  // We send these so the server can sign the right thing
  @attr('string') projectFileVersionId;
  @attr('string') mimetype;
  @attr('string') sha256Checksum;

  // We get these back from the server
  @attr('string') random;
  @attr('string') url;
  @attr headers;
}
